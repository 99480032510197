import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Payment from "@mui/icons-material/Payment";
import Delete from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";
import Popover from "@mui/material/Popover";

const UserItem = ({ note, onClick, name, email, role, checked }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();

  return (
    <Grid
      className={styles.container}
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      component={motion.div}
      whileHover={{
        backgroundColor: Colors.dark + "10",
        transition: { duration: 0.7 },
        scale: 1.01,
        borderColor: Colors.blue + "88",
        borderWidth: "1px",
        borderRadius: "10px",
      }}
      onClick={onClick}
      style={{ borderColor: checked ? Colors.orange  : Colors.white }}
    >
      <Tooltip title={name}>
        <Avatar style={{ marginLeft: "20px" }}>
          {String(name).substr(0, 2).toLocaleUpperCase()}
        </Avatar>
      </Tooltip>

      <Grid className={styles.container2}>
        <Typography className={styles.title}>{name}</Typography>

        <Typography className={styles.message}>{email} </Typography>

        <Typography className={styles.date}>{role} </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    textTransform: "none",
    backgroundColor: Colors.dark + "10",
    marginTop: "10px",
    marginBottom: "10px",
    border: "1px solid #30374500",
    width: "100%",
    borderRadius: "10px",
  },
  container2: {
    padding: "10px",
    border: "1px solid #30374500",
    maxWidth: window.innerWidth - 350,
    marginLeft: "5px",
  },
  markAsComplete: {
    background: Colors.green,
    color: Colors.white,
    marginTop: "15px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  needsWork: {
    background: Colors.red,
    color: Colors.white,
    marginTop: "15px",
    marginRight: "5px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  inputContent: {
    width: "30%",
    height: "100%",
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "0vh",
    },
  },
  name: {
    fontSize: "11px",
    fontFamily: "Spartan",
    color: Colors.black,
    width: "220px",
    fontWeight: "bold",
  },
  value: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.black,
    marginLeft: "5px",
    width: "600px",
  },
  date: {
    fontSize: "9px",
    fontFamily: "Spartan",
    color: Colors.darkGrey,
    marginLeft: "10px",
    marginTop: "4px",
  },
  title: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  message: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "13px",
    marginLeft: "10px",
    marginRight: "40px",
    width: "80%",

    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  link: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginLeft: "10px",
  },
  Paypal: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  email: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "12px",
    marginTop: "5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    height: "35px",
    borderRadius: "20px",
    color: Colors.blue,
    textTransform: "none",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  price: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "20px",
    bottom: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  orderStatus: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  id: {
    color: Colors.darkGrey,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px",
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan",
  },
}));

export default UserItem;
