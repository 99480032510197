import {
  CREATE_NOTE_ATTEMPING,
  CREATE_NOTE_FAILED,
  CREATE_NOTE_SUCCESS,
  GET_NOTES_ATTEMPING,
  GET_NOTES_FAILED,
  GET_NOTES_SUCCESS,
  
} from "../actions/Types";

const INITIAL_STATE = {
  loadingGetNotes: false,
  errorNotes: null,
  notes: null,
  loadingSaveNotes: false,
  errorSaveNotes: null,
  noteSaved: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTES_ATTEMPING:
      return { ...state, loadingGetNotes: true };

    case GET_NOTES_SUCCESS:
      return { ...state, loadingGetNotes: false, notes: action.payload };

    case GET_NOTES_FAILED:
      return { ...state, loadingGetNotes: false, errorNotes: action.payload };

    case CREATE_NOTE_ATTEMPING:
      return { ...state, loadingSaveNotes: true };

    case CREATE_NOTE_SUCCESS:
      return { ...state, loadingSaveNotes: false, noteSaved: action.payload };

    case CREATE_NOTE_FAILED:
      return {
        ...state,
        loadingSaveNotes: false,
        errorSaveNotes: action.payload,
      };

    default:
      return state;
  }
};
