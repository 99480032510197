import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Payment from "@mui/icons-material/Payment";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";

const NotificationItem = ({
  order,
  onClick,
  status,
  date,
  senderInfo,
  role,
  file,
  message,
  title,
  orderId,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();

  const timeGo = (date) => {
    if (typeof date !== "object") {
      date = new Date(date);
    }

    var seconds = Math.floor((new Date() - date) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = "year";
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = "month";
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = "day";
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }

    if (interval > 1 || interval === 0) {
      intervalType += "s";
    }

    return interval + " " + intervalType;
  };
  return (
    <Grid
      className={styles.container}
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      style={{backgroundColor:status=="unread"?Colors.blue+"44":Colors.grey6}}
      component={motion.div}
      whileHover={{
        backgroundColor: Colors.dark+'10',
        transition: { duration: 0.7 },
        scale: 1.01,
        borderColor: Colors.blue+"88",
        borderWidth: "1px",
      }}
    >
      <Tooltip title={senderInfo?.name + " (" + senderInfo?.role + ")"}>
        <Button onClick={onClick}>
          <Avatar style={{ marginLeft: "5px" }}>
            {String(senderInfo?.name).substr(0, 1).toLocaleUpperCase()}
          </Avatar>
        </Button>
      </Tooltip>
      <Grid className={styles.container2}>
        {orderId && (
          <Typography
            className={styles.title}
            style={{
              color:
                status == "Needs Work" || status == "Canceled"
                  ? Colors.red
                  : Colors.orderId,
            }}
          >
            Order #{orderId}{" "}
          </Typography>
        )}
        {title && (
          <Typography
            className={styles.title}
            style={{
              color:
                status == "Needs Work" || status == "Canceled"
                  ? Colors.red
                  : Colors.green,
            }}
          >
            {title}{" "}
          </Typography>
        )}
        {message && (
          <Typography className={styles.message}>{message} </Typography>
        )}

        {file && (
          <a download className={styles.link} target="_blank" href={file.url}>
            {file.name}
          </a>
        )}

        <Typography className={styles.date}>{timeGo(date)} </Typography>
      </Grid>

      <Button onClick={onClick} style={{position:'absolute',right:'20px',color:Colors.blue}}>
          <ArrowRightAlt/>
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    textTransform: "none",

    marginTop: "4px",
    width: "100%",
    backgroundColor: Colors.grey6,
  },
  container2: {
    padding: "10px",
    marginLeft: "5px",
    width: "100%",
  },
  markAsComplete: {
    background: Colors.green,
    color: Colors.white,
    marginTop: "15px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  needsWork: {
    background: Colors.red,
    color: Colors.white,
    marginTop: "15px",
    marginRight: "5px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  inputContent: {
    width: "30%",
    height: "100%",
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "0vh",
    },
  },
  name: {
    fontSize: "11px",
    fontFamily: "Spartan",
    color: Colors.black,
    width: "220px",
    fontWeight: "bold",
  },
  value: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.black,
    marginLeft: "5px",
    width: "600px",
  },
  date: {
    fontSize: "9px",
    fontFamily: "Spartan",
    color: Colors.darkGrey,
    marginLeft: "10px",
    marginTop: "4px",
  },
  orderId: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  title: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "13px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  message: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "13px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  link: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginLeft: "10px",
  },
  Paypal: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  email: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "12px",
    marginTop: "5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    height: "35px",
    borderRadius: "20px",
    color: Colors.blue,
    textTransform: "none",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  price: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "20px",
    bottom: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  orderStatus: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  id: {
    color: Colors.darkGrey,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px",
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan",
  },
}));

export default NotificationItem;
