import firebase from "../../global/services/Firebase";
import AsyncStorage from "@callstack/async-storage";
import {
  GET_STUDENTS_ATTEMPING,
  GET_STUDENTS_FAILED,
  GET_STUDENTS_SUCCESS
} from "./Types";

export const getStudents = () => {
  return async (dispatch) => {
    dispatch({ type: GET_STUDENTS_ATTEMPING });
    firebase
      .database()
      .ref("users/profiles/")
      .once("value", async (snapshot) => {
       
        dispatch({
          type: GET_STUDENTS_SUCCESS,
          payload: Object.values(snapshot.val())
        });
      })
      .catch((err) => {
        dispatch({ type: GET_STUDENTS_FAILED, payload: err.message });
      });
  };
};
