import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  homeStudentStats,
  getProducts,
  deleteNotification,
  getSubscription
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import ProductItem from "../../../../Components/ProductItem";
import SunscriptionItem from "../../../../Components/SunscriptionItem";
import coming_soon from "../../../../assets/images/coming_soon.avif";
import AsyncStorage from "@callstack/async-storage";

const HomeStudent = ({
  homeStudentStats,
  loadingOrdersStats,
  orderStats,
  getProducts,
  products,
  loading,
  role,
  deleteNotification,
  openOrderManagement,
  loadingSubscription,
  subscriptions,
  getSubscription
}) => {
  const styles = useStyles();

  return (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <img src={coming_soon} style={{ width: "600px", height: "400px" }} />
    </div>
  );
};

const mapStateToProps = ({ products, orders, Subscriptions }) => {
  return {
    loadingOrdersStats: orders.loadingOrdersStats,
    errorOrdersStats: orders.errorOrdersStats,
    orderStats: orders.orderStats,
    products: products.products,
    loading: products.loading,
    loadingSubscription: Subscriptions.loadingSubscription,
    subscriptions: Subscriptions.subscriptions
  };
};

export default connect(mapStateToProps, {
  homeStudentStats,
  getProducts,
  deleteNotification,
  getSubscription
})(HomeStudent);
