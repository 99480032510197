import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import firebase from "../../../../global/services/Firebase";

import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  createChatMessage,
  getChatMessages,
  changeChatMessageStatus,
  setChatMessagesSeen,
  createNotificationFromWriter,
  createNotificationToWriter,
  deleteChatMessage,
  sendSms,
  sendPushNotification,
  sendEmail
} from "../../../../Redux/actions";
import TextField from "@mui/material/TextField";
import ArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeft";
import { motion } from "framer-motion";
import UserComment from "../../../../Components/UserComment";
import AttachFile from "@material-ui/icons/AttachFile";
import Close from "@material-ui/icons/Close";
import AsyncStorage from "@callstack/async-storage";
import { useFilePicker } from "use-file-picker";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import List from "@mui/material/List";
import CircularProgress from "@mui/material/CircularProgress";
const OrderChat = ({
  writers,
  role,
  createChatMessage,
  selectedOrder,
  onClick,
  getChatMessages,
  chatMessages,
  setChatMessagesSeen,
  deleteChatMessage,
  createNotificationFromWriter,
  createNotificationToWriter,
  sendSms,
  sendPushNotification,
  studentProfileByEmail,
  sendEmail
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [userInfo, setUserInfo] = React.useState(null);

  const [file, setFile] = useState(null);
  const imageIcon =
    "https://firebasestorage.googleapis.com/v0/b/paper-writer-f57a6.appspot.com/o/dashboard%2Forders%2F2022806OL%2Fchat%2Fimage.png?alt=media&token=8245f177-1c29-445f-8feb-d2610a0e220f";

  const [openFileSelector, { filesContent, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    accept: [
      ".json",
      ".pdf",
      ".docx",
      ".doc",
      ".txt",
      ".ppt" + ".png",
      ".jpeg",
      ".jpg",
      ".xlsx",
      ".zip",
      ".rar",
      "image/*"
    ],

    multiple: false
    // limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
  });

  useEffect(async () => {
    if (filesContent.length > 0) {
      const url = await uploadImageAsync(
        selectedOrder.id,
        filesContent[0].content,
        filesContent[0].name
      );
      setFile({ name: filesContent[0].name, url });
    }
  }, [filesContent]);

  useEffect(async () => {
    if (chatMessages) {
      setMessages(chatMessages);
    }
  }, [chatMessages]);

  useEffect(async () => {
    const role = await AsyncStorage.getItem("role");

    if (chatMessages) {
      setChatMessagesSeen("/chat/", role, chatMessages);
    }
  }, []);

  const uploadImageAsync = async (orderId, uri, name) => {
    setUploading(true);
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    const ref = firebase
      .storage()
      .ref("dashboard/orders/" + orderId + "/chat/" + makeid(15))
      .child(name);
    const snapshot = await ref.put(blob);
    setUploading(false);

    return await snapshot.ref.getDownloadURL();
  };

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  useEffect(async () => {
    const role = await AsyncStorage.getItem("role");
    const name = await AsyncStorage.getItem("name");
    const email = await AsyncStorage.getItem("email");
    const loggedIn = await AsyncStorage.getItem("loggedIn");

    setUserInfo({
      name,
      role,
      email,
      loggedIn
    });
    getChatMessages("/chat", selectedOrder.id, role, (data) => {});
  }, []);

  const sendMessage = (title, status) => {
    let today = new Date();
    let time = today.getHours() + ":" + today.getMinutes();

    createChatMessage(
      "/chat",
      selectedOrder.id,
      userInfo.name,
      userInfo.email,
      userInfo.role,
      title,
      status,
      message,
      file,
      new Date().toDateString() + " - " + time,
      (data) => {
        if (data) {
          if (userInfo.role == "writer" || userInfo.role == "student") {
            createNotificationFromWriter(
              selectedOrder.id,
              userInfo.name,
              userInfo.email,
              userInfo.role,
              selectedOrder?.privateAdminNotes,
              title,
              message,
              file,
              new Date().toISOString()
            );
            sendEmail({
              subject: `Order #${selectedOrder.id} update from the ${userInfo.role}`,
              to: 'paperwriter20@gmail.com',
              order: selectedOrder,
              title: title ? title : `You got a new message from the ${userInfo.role}`,
              description: `${message}
              ${file ? file?.url : ``}`
            });
          } else {
            var text = selectedOrder?.privateAdminNotes;

            var email = text.match(
              /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
            );

            createNotificationToWriter(
              selectedOrder.id,
              userInfo.name,
              userInfo.email,
              userInfo.role,
              selectedOrder?.privateAdminNotes,
              email[0],
              title,
              message,
              file,
              new Date().toISOString()
            );

            sendEmail({
              subject: `Order #${selectedOrder.id} update from the admin`,
              to: email[0],
              order: selectedOrder,
              title: title ? title : "You got a new message from the admin",
              description: `${message}
              ${file ? file?.url : ``}`
            });
          }

          setMessage("");
        }
      }
    );
  };

  return (
    <Grid
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid
        container
        direction="row"
        wrap="nowrap"
        style={{
          width: window.innerWidth - 280
        }}
      >
        {
          <Typography className={styles.idOrder}>
            {role == "writer" ? "Admin Chat" : "Writer Chat"}
          </Typography>
        }
      </Grid>
      <List>
        {messages?.map((item, index) => {
          return (
            <UserComment
              key={index + " "}
              title={item?.title}
              date={item?.date}
              status={item?.status}
              message={item?.message}
              file={item?.file}
              userInfo={userInfo}
              senderInfo={{
                name: item?.senderName,
                role: item?.senderRole,
                email: item?.senderEmail
              }}
              deleteMessage={() => {
                deleteChatMessage("/chat/", selectedOrder.id, item.id);
              }}
            />
          );
        })}
      </List>

      <div style={{ height: "150px" }}></div>
      {selectedOrder?.fulfillmentStatus != "DELIVERED" ||
      selectedOrder?.fulfillmentStatus != "CANCELED" ? (
        <Grid
          container
          style={{
            position: "fixed",
            bottom: "0px",
            backgroundColor: "white",
            padding: "10px",
            boxShadow: "5px 5px 5px 5px #c7c7c755",
            width: window.innerWidth
          }}
        >
          <TextField
            value={message}
            maxRows={3}
            color="success"
            placeholder="Type your message here"
            className={styles.textArea}
            multiline={true}
            onChange={(event) => {
              setMessage(event.target.value ? event.target.value : "");
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <Grid justifyContent="center" alignItems="center">
                      {uploading ? (
                        <CircularProgress
                          style={{ alignSelf: "center", color: Colors.black }}
                        />
                      ) : file?.url ? (
                        <Grid
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                        >
                          <a className={styles.fileName}> {file?.name}</a>
                          <Button onClick={() => setFile(null)}>
                            <Close />
                          </Button>
                        </Grid>
                      ) : (
                        <Button
                          component={motion.button}
                          whileHover={{
                            backgroundColor: Colors.black,
                            transition: { duration: 0.3 },
                            color: Colors.white
                          }}
                          className={styles.AttachFile}
                          onClick={() => openFileSelector()}
                        >
                          <AttachFile />
                        </Button>
                      )}
                    </Grid>
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="flex-end"
            style={{
              width: window.innerWidth - 280
            }}
          >
            {(userInfo?.role == "admin" || userInfo?.role == "manager") && (
              <Button
                component={motion.button}
                whileHover={{
                  backgroundColor: Colors.black,
                  transition: { duration: 0.3 },
                  color: Colors.white
                }}
                style={{ backgroundColor: Colors.red }}
                className={styles.deliveredButton}
                onClick={() => {
                  sendMessage(
                    userInfo.name + " marked the order as `Needs Work`",
                    "Needs Work"
                  );
                }}
              >
                Needs Work
              </Button>
            )}

            {(userInfo?.role == "admin" || userInfo?.role == "manager") && (
              <Button
                component={motion.button}
                whileHover={{
                  backgroundColor: Colors.black,
                  transition: { duration: 0.3 },
                  color: Colors.white
                }}
                style={{ backgroundColor: Colors.red }}
                className={styles.deliveredButton}
                onClick={() => {
                  sendMessage(
                    userInfo.name + " marked the order as `Canceled`",
                    "Canceled"
                  );
                }}
              >
                Mark As Canceled
              </Button>
            )}

            {(userInfo?.role == "admin" || userInfo?.role == "manager") && (
              <Button
                component={motion.button}
                whileHover={{
                  backgroundColor: Colors.black,
                  transition: { duration: 0.3 },
                  color: Colors.white
                }}
                className={styles.deliveredButton}
                onClick={() => {
                  sendMessage(
                    userInfo.name + " marked the order as `Completed`",
                    "Completed"
                  );
                }}
              >
                Mark As Completed
              </Button>
            )}

            {userInfo?.role == "writer" && (
              <Button
                component={motion.button}
                whileHover={{
                  backgroundColor: Colors.black,
                  transition: { duration: 0.3 },
                  color: Colors.white
                }}
                className={styles.deliveredButton}
                onClick={() => {
                  sendMessage(
                    userInfo.name + " marked the order as `Delivered`",
                    "Delivered"
                  );
                  sendSms(`Hello, ${userInfo.name} has delivered order # ${selectedOrder.id}.
                Please click here to log into the portal to review.
                https://ppwlocker.com/dashboard/orders 
                `);
                }}
              >
                Deliver Work
              </Button>
            )}

            <Button
              component={motion.button}
              whileHover={{
                backgroundColor: Colors.black,
                transition: { duration: 0.3 },
                color: Colors.white
              }}
              className={styles.sendButton}
              onClick={() => {
                if (message || file) {
                  sendMessage("", "");
                }
              }}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps = ({ orders, products, users, writer, chat }) => {
  return {
    loading: orders.loading,
    error: orders.error,
    orders: orders.orders,
    orderStats: orders.orderStats,
    products: products.products,
    writers: writer.writers,

    loadingGetchatMessages: chat.loadingGetchatMessages,
    chatMessages: chat.chatMessages,
    unSeenCount: chat.unSeenCount,
    studentProfileByEmail: users.studentProfileByEmail
  };
};

export default connect(mapStateToProps, {
  changeChatMessageStatus,
  createChatMessage,
  getChatMessages,
  setChatMessagesSeen,
  createNotificationFromWriter,
  createNotificationToWriter,
  deleteChatMessage,
  sendSms,
  sendPushNotification,
  sendEmail
})(OrderChat);
