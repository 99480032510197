import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../Utils/Colors";

const useStyles = makeStyles((theme) => ({
  container: {
    background: Colors.white,
    display: "flex",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    textTransform: "none",
    minHeight: "100vh",
  },
  iframe: {
    display: "flex",
    width: "100%",
    height: "100%",
   
    minHeight: "100vh",
    minWidth:window.innerWidth-240,
 
  },
  classNamePrefix:{
    
  },
  img: {
    width: "100%",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "30%",
      minHeight: "30vh",

    },
  },
  logo: {
    height: "30px",
    height: "30px",
    borderRadius: "10%",
  },
  logoView: {
    position: "absolute",
    top: "25px",
    left: "25px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  imgDev: {
    width: "70%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "30%",
      minHeight: "30vh",

    },
  },
  inputContent: {
    width: "30%",
    height: "100%",
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "0vh",

    },
  },

  appName: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    marginLeft: "5px",
  },
  welcomeText: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "30px",

  
      },
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px",
    
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan",
  },
  inputPassword: {
    width: "80%",
    height: "50px",
    fontFamily: "Spartan",
    marginTop: "20px",

  },

  SigninButton: {
    backgroundColor: Colors.blue,
    marginTop: "20px",
    width: "80%",
    height: "50px",
  },
  signinText: {
    color: Colors.white,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "13px",
    textTransform: 'none',

  },
  rememberMe:{
    fontFamily: "Spartan",
    fontSize: "13px",
  },
  rememberMeContentView:{
   alignSelf:'flex-start',
   marginLeft:"10%"
  },
  forgotPasswordButton:{
    marginTop: "10px",
    alignSelf:'flex-end',
    marginRight:"10%"
  },
  notificationButton:{
    backgroundColor:Colors.white,
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "14px",
    textTransform: 'none',
    width:"150px",
    height:"40px",
    borderRadius:"50px",
    
  },
  forgotPasswordText: {
    color: Colors.blue,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    textTransform: 'none',

    
  },
  NewOnOurPlatform:{
    color: Colors.grey,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
  },
  createAccountButton:{
  },
  createAccountText:{
    color: Colors.blue,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "13px",
    textTransform: 'none',


  },
  createAccountView:{
    marginTop: "20px",

  }
}));

export default useStyles;
