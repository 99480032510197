import {

    GET_CUSTOMER_PROFILE_ATTEMPING,
    GET_CUSTOMER_PROFILE_FAILED,
    GET_CUSTOMER_PROFILE_SUCCESS

} from '../actions/Types';

const INITIAL_STATE = {
    loading: false, error: null, profile: null
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_CUSTOMER_PROFILE_ATTEMPING:
            return {  loading: true }

        case GET_CUSTOMER_PROFILE_SUCCESS:
            return {  loading: false, profile: action.payload }

        case GET_CUSTOMER_PROFILE_FAILED:
            return {  loading: false, error: action.payload, }

        default: return state;

    }

};
