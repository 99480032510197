import firebase from "../../global/services/Firebase";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import { ServiceURL } from "../../global/services/config";

import {
  LOGIN_ATTEMPING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  SIGNUP_ATTEMPING,
  SIGNUP_FAILED,
  SIGNUP_SUCCESS,
  SEND_RESET_PASSWORD_ATTEMPING,
  SEND_RESET_PASSWORD_SUCCESS,
  SEND_RESET_PASSWORD_FAILED,
  RESET_PASSWORD_ATTEMPING,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ATTEMPING,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_SUCCESS,
  GET_USERS_ATTEMPING,
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
  GET_ALL_USERS_ATTEMPING,
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_SUCCESS,
  UPDATE_USER_STATUS_ATTEMPING,
  UPDATE_USER_STATUS_FAILED,
  UPDATE_USER_STATUS_SUCCESS,
  GET_STUDENT_PROFILE_BY_EMAIL_ATTEMPING,
  GET_STUDENT_PROFILE_BY_EMAIL_FAILED,
  GET_STUDENT_PROFILE_BY_EMAIL_SUCCESS
} from "./Types";

export const updateUserStatus = (id, active, noteShare, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_STATUS_ATTEMPING
    });
    try {
      firebase
        .database()
        .ref("dashboard/users/" + id)
        .update({
          active: active,
          noteShare: noteShare
        })
        .then((response) => {
          callBack(true);
          dispatch({
            type: UPDATE_USER_STATUS_SUCCESS
          });
        });
    } catch (error) {
      dispatch({ type: UPDATE_USER_STATUS_FAILED });
    }
  };
};

export const getAllUsers = (callBack) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_USERS_ATTEMPING
    });
    try {
      firebase
        .database()
        .ref("dashboard/users/")
        .once("value", (snapshot) => {
          const users = [];
          snapshot.forEach((value) => {
            let user = value.val();
            user.checked = false;
            users.push(user);
          });
          if (users) {
            callBack(users.reverse());
            dispatch({
              type: GET_ALL_USERS_SUCCESS,
              payload: users
            });
          }
        });
    } catch (error) {
      dispatch({ type: GET_ALL_USERS_FAILED });
    }
  };
};

export const getUsers = (id, role, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: GET_USERS_ATTEMPING
    });
    try {
      firebase
        .database()
        .ref("dashboard/users/")
        .on("value", (snapshot) => {
          const users = [];
          snapshot.forEach((value) => {
            let user = value.val();
            user.checked = false;
            if (user.id != id) {
              if (role != "writer") {
                users.push(user);
              } else if (role == "writer" && user.role != "writer") {
                users.push(user);
              }
            }
          });
          if (users) {
            callBack(users);
            dispatch({
              type: GET_USERS_SUCCESS,
              payload: users
            });
          }
        });
    } catch (error) {
      dispatch({ type: GET_USERS_FAILED });
    }
  };
};

export const resetPassword = (currentPassword, newPass) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_ATTEMPING
    });

    const email = await AsyncStorage.getItem("email");

    firebase
      .auth()
      .signInWithEmailAndPassword(email, currentPassword)
      .then((Response) => {
        firebase
          .auth()
          .currentUser.updatePassword(newPass)
          .then((resp) => {
            dispatch({
              type: RESET_PASSWORD_SUCCESS,
              payload: true
            });
          })
          .catch((error) => {
            dispatch({
              type: RESET_PASSWORD_FAILED,
              payload: error.message
            });
          });
      })
      .catch((err) => {
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: err.message
        });
      });
  };
};

export const signup = (
  name,
  email,
  password,
  role,
  callBack,
  callBackError
) => {
  return async (dispatch) => {
    dispatch({
      type: SIGNUP_ATTEMPING
    });
    let emailExist = false;
    firebase
      .database()
      .ref("dashboard/users/")
      .on("value", (snapshot) => {
        const users = [];
        snapshot.forEach((value) => {
          let user = value.val();
          user.checked = false;
          if (user.email == email) {
            emailExist = true;
          }
        });
      });

    if (emailExist == true) {
      callBackError(email + " is already exist");
    } else {
      firebase
        .database()
        .ref("dashboard/users")
        .push({
          name,
          email,
          password,
          role: role,
          active: true,
          date: new Date().toUTCString(),
          noteShare: true
        })
        .then(async (reponse) => {
          firebase
            .database()
            .ref("dashboard/users/" + reponse.key)
            .update({
              id: reponse.key
            });
          callBack({
            email: email,
            password: password
          });

          dispatch({
            type: SIGNUP_SUCCESS
          });
        })
        .catch((err) => {
          callBackError(err.response);
          dispatch({ type: SIGNUP_FAILED, payload: err.message });
        });
    }
  };
};

export const updatePassword = (id, password, callBack, callBackError) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PASSWORD_ATTEMPING
    });

    firebase
      .database()
      .ref("dashboard/users/" + id)
      .update({
        password
      })
      .then(async (reponse) => {
        callBack({
          password: password
        });
        dispatch({
          type: UPDATE_PASSWORD_SUCCESS
        });
      })
      .catch((err) => {
        callBackError(err.response);
        dispatch({ type: UPDATE_PASSWORD_FAILED, payload: err.message });
      });
  };
};

export const getStudentStudentProfile = (email) => {
  return async (dispatch) => {
    dispatch({ type: GET_STUDENT_PROFILE_BY_EMAIL_ATTEMPING });

    firebase
      .database()
      .ref("users/profiles/")
      .on("value", async (snapshot) => {
        const students = Object.values(snapshot.val());
        let student = null;
        students?.filter((item) => {
          if (email == item?.email) {
            student = item;
          }
        });
        dispatch({
          type: GET_STUDENT_PROFILE_BY_EMAIL_SUCCESS,
          payload: student
        });
      })
  };
};

export const loginStudent = (email, password, callBack, callBackError) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ATTEMPING });

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((Response) => {
        firebase
          .database()
          .ref("users/profiles/" + Response.user.uid)
          .on("value", async (snapshot) => {
            const profile = snapshot.val();
            var loginDate = new Date();
            loginDate.setHours(loginDate.getHours() + 2);
            await AsyncStorage.setItem("loginPath", "/student/login");
            await AsyncStorage.setItem("id", profile.uid);
            await AsyncStorage.setItem("role", "student");
            await AsyncStorage.setItem("loginDate", loginDate.toString());
            await AsyncStorage.setItem(
              "name",
              profile.name ? profile.name : ""
            );
            await AsyncStorage.setItem("email", email);
            await AsyncStorage.setItem("password", password);
            await AsyncStorage.setItem("loggedIn", true);
            await AsyncStorage.setItem(
              "active",
              profile.active ? profile.active : "true"
            );
            await AsyncStorage.setItem(
              "noteShare",
              profile.noteShare ? profile.noteShare : "false"
            );
            firebase
              .database()
              .ref("users/profiles/" + profile.uid)
              .update({
                createdAt: new Date().toUTCString(),
                platform:"Web"
              });
            callBack(profile);
            dispatch({
              type: LOGIN_SUCCESS,
              payload: profile
            });
          });
      })
      .catch((err) => {
        console.log(err);
        callBackError("Email or password is incorrect!");
        dispatch({ type: LOGIN_FAILED, payload: err.message });
      });
  };
};

export const studentSignup = (name, email, password) => {
  return async (dispatch) => {
    dispatch({
      type: SIGNUP_ATTEMPING,
    });

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((resp) => {
        firebase
          .database()
          .ref("users/profiles/" + resp.user.uid)
          .set({
            email,
            uid: resp.user.uid,
            role: "customer",
            createdAt: new Date().toISOString(),
            name:name,
            platform:"Web",
            active: true,
            noteShare: true
          })
          .then(async (reponse) => {
            console.log("reponse", reponse);
            var loginDate = new Date();
            loginDate.setHours(loginDate.getHours() + 2);

            await AsyncStorage.setItem("loginPath", "/student/login");
            await AsyncStorage.setItem("id", resp.user.uid);
            await AsyncStorage.setItem("role", "student");
            await AsyncStorage.setItem("loginDate", loginDate.toString());
            await AsyncStorage.setItem(
              "name",
              name ? name : ""
            );
            await AsyncStorage.setItem("email", email);
            await AsyncStorage.setItem("password", password);
            await AsyncStorage.setItem("loggedIn", "true");
            await AsyncStorage.setItem(
              "active",
              resp.user.active ? resp.user.active : "true"
            );
            await AsyncStorage.setItem(
              "noteShare",
              resp.user.noteShare ? resp.user.noteShare : "false"
            );

            firebase
            .auth()
            .signInWithEmailAndPassword(email, password)

            dispatch({
              type: SIGNUP_SUCCESS,
              payload: {
                email,
                uid: resp.user.uid,
                role: "customer",
                createdAt: new Date().toISOString(),
              },
            });
            const emailArr = String(email).split("@");
            var config = {
              method: "post",
              url: ServiceURL.GetCustomer(email),
              headers: ServiceURL.headers,
              data: {
                email: email,
                name: email,
                password: password,
                customerGroupId: 0,
                billingPerson: {
                  name: email,
                  firstName: emailArr[0],
                  lastName: "@" + emailArr[1],
                },
                id: parseInt(makeNumber(8)),
                lang: "en",
              },
            };
            const { data } = await axios(config);

            console.log("signup response", data);
          }).catch((err) => {
            console.log(err.response);
           // dispatch({ type: SIGNUP_FAILED, payload: err.message });
          });;
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({ type: SIGNUP_FAILED, payload: err.message });
      });
  };
};
function makeNumber(length) {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const login = (email, password, callBack, callBackError) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ATTEMPING });
    try {
      firebase
        .database()
        .ref("dashboard/users")
        .on("value", async (snapshot) => {
          let profile = null;

          snapshot.forEach((value) => {
            let user = value.val();

            if (user.email == email && user.password == password) {
              profile = user;
            }
          });
          var loginDate = new Date();
          loginDate.setHours(loginDate.getHours() + 2);
          if (profile?.active == true) {
            await AsyncStorage.setItem("loginPath", "/login");
            await AsyncStorage.setItem("id", profile.id);
            await AsyncStorage.setItem("role", profile.role);
            await AsyncStorage.setItem("loginDate", loginDate.toString());
            await AsyncStorage.setItem("name", profile.name);
            await AsyncStorage.setItem("email", profile.email);
            await AsyncStorage.setItem("password", profile.password);
            await AsyncStorage.setItem("loggedIn", true);
            await AsyncStorage.setItem("active", profile.active);
            await AsyncStorage.setItem("noteShare", profile.noteShare);
            firebase
              .database()
              .ref("dashboard/users/" + profile.id)
              .update({
                loggedAt: new Date().toUTCString()
              });
            callBack(profile);
            dispatch({
              type: LOGIN_SUCCESS,
              payload: profile
            });
          } else {
            callBackError("Email or password is incorrect!");
            dispatch({ type: LOGIN_FAILED });
          }
        });
    } catch (error) {
      dispatch({ type: LOGIN_FAILED });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ATTEMPING });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: null
    });
  };
};

export const sendResetPassword = (email, callBack) => {
  return async (dispatch) => {
    dispatch({ type: SEND_RESET_PASSWORD_ATTEMPING });
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        callBack();
        dispatch({
          type: SEND_RESET_PASSWORD_SUCCESS
        });
      })
      .catch((err) => {
        dispatch({ type: SEND_RESET_PASSWORD_FAILED, payload: err.message });
      });
  };
};

export const logOut = () => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ATTEMPING });

    firebase
      .auth()
      .signOut()
      .then(function () {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: null
        });
      })
      .catch((err) => {
        dispatch({ type: LOGIN_FAILED, payload: err.message });
      });
  };
};
