import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Payment from "@mui/icons-material/Payment";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import Avatar from "@mui/material/Avatar";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";

const WriterItem = ({ writer, detailsAction }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();

  return (
    <Grid
      component={motion.div}
      whileHover={{
        backgroundColor: Colors.dark+'10',
        transition: { duration: 0.7 },
        scale: 1.05,
        borderColor: Colors.blue+"88",
        borderWidth: "1px",
      }}
      className={styles.container}
      container
      direction="column"
      spacing={2}
      wrap="nowrap"

    >
      <img
        variant="circular"
        className={styles.avatar}
        src={
          "https://firebasestorage.googleapis.com/v0/b/paper-writer-f57a6.appspot.com/o/Screen%20Shot%202022-06-05%20at%206.21.52%20PM.png?alt=media&token=8b8baa02-e770-449d-911c-6d2b3fd62e2a"
        }
      />

      <Typography className={styles.name}>{writer.name}</Typography>
      <Typography className={styles.email}>{writer.email.startsWith("add.task") ?String(writer.email).substr(0,30)+'..':writer.email}</Typography>

      <Button
        component={motion.button}
        whileHover={{
          backgroundColor: Colors.green,
          transition: { duration: 0.3 },
        }}
        className={styles.moreItem}
        onClick={detailsAction}
      >
        <Typography className={styles.more}>View Profile</Typography>
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: Colors.white,
    textTransform: "none",
    padding: "25px",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px 2px #c7c7c755",
    margin: "10px",
    width: "250px",
    height: "300px",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #30374500",
  },
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: "30px",
    border: "5px solid #f5f5f5",

  },

  name: {
    fontSize: "13px",
    fontFamily: "Spartan",
    color: Colors.black,
    fontWeight: "bold",
    marginTop:"15px"
  },

  email: {
    color: Colors.dark,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    width: "200px",
    textAlign:"center",

  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "100px",
    height: "35px",
    background: Colors.blue,
    borderRadius: "20px",
    color: Colors.white,
    textTransform: "none",
    
    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
}));

export default WriterItem;
