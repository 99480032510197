import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../Utils/Colors";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
   
  },

  save: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "100px",
    height: "40px",
    borderRadius: "20px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {},
    color: Colors.blue,
  },

  iframe: {

    height: "100%",
   
    minHeight: "100vh",
    borderColor:'#ffffff00'
 
  }
}));

export default useStyles;
