import { orange, yellow } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import Colors from '../Utils/Colors'

export const theme=createMuiTheme({
    palette: {
        common: {
            main:Colors.blue

        },
        primary: {
            main:Colors.blue
        }
        ,
        secondary:{
            main:Colors.white
        }
    },


})

export const theme2=createMuiTheme({
    palette: {
        common: {
            main:Colors.none

        },
       
        primary: {
            main:Colors.blue
        }
        ,
        secondary:{
            main:Colors.blue
        }
    },
    typography:{
        h4:{
            fontFamily:'Roboto',
            color:Colors.secondaryColor,
            marginLeft:20
            
        }
    }


})


export const TextFieldTheme=createMuiTheme({
    palette: {
        main: {
            main:Colors.grey2

        },
       
        primary: {
            light: '#fff',
            main: Colors.labany,
            dark: '#fff',
            contrastText: '#fff',        }
        ,
        secondary:{
            main:Colors.grey2
        }
    },
    typography:{
        h4:{
            fontFamily:'Roboto',
            color:Colors.secondaryColor,
            marginLeft:20
            
        }
    },



})
