export const LOGIN_ATTEMPING = "LOGIN_ATTEMPING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const REFRESH_PROFILE = "REFRESH_PROFILE";

export const SIGNUP_ATTEMPING = "SIGNUP_ATTEMPING";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";


export const UPDATE_PASSWORD_ATTEMPING = "UPDATE_PASSWORD_ATTEMPING";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const CREATE_WRITER_ATTEMPING = "CREATE_WRITER_ATTEMPING";
export const CREATE_WRITER_FAILED = "CREATE_WRITER_FAILED";
export const CREATE_WRITER_SUCCESS = "CREATE_WRITER_SUCCESS";

export const GET_WRITERS_ATTEMPING = "GET_WRITERS_ATTEMPING";
export const GET_WRITERS_FAILED = "GET_WRITERS_FAILED";
export const GET_WRITERS_SUCCESS = "GET_WRITERS_SUCCESS";

export const GET_WRITER_ATTEMPING = "GET_WRITER_ATTEMPING";
export const GET_WRITER_FAILED = "GET_WRITER_FAILED";
export const GET_WRITER_SUCCESS = "GET_WRITER_SUCCESS";


export const GET_STUDENT_PROFILE_BY_EMAIL_ATTEMPING = "GET_STUDENT_PROFILE_BY_EMAIL_ATTEMPING";
export const GET_STUDENT_PROFILE_BY_EMAIL_FAILED = "GET_STUDENT_PROFILE_BY_EMAIL_FAILED";
export const GET_STUDENT_PROFILE_BY_EMAIL_SUCCESS = "GET_STUDENT_PROFILE_BY_EMAIL_SUCCESS";



export const GET_USERS_ATTEMPING = "GET_USERS_ATTEMPING";
export const GET_USERS_FAILED = "GET_USERS_FAILED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";


export const GET_ALL_USERS_ATTEMPING = "GET_ALL_USERS_ATTEMPING";
export const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";


export const GET_WRITER_STATS_ATTEMPING = "GET_WRITER_STATS_ATTEMPING";
export const GET_WRITER_STATS_FAILED = "GET_WRITER_STATS_FAILED";
export const GET_WRITER_STATS_SUCCESS = "GET_WRITER_STATS_SUCCESS";


export const GET_WRITER_ORDERS_ATTEMPING = "GET_WRITER_ORDERS_ATTEMPING";
export const GET_WRITER_ORDERS_FAILED = "GET_WRITER_ORDERS_FAILED";
export const GET_WRITER_ORDERS_SUCCESS = "GET_WRITER_ORDERS_SUCCESS";


export const GET_CUSTOMER_PROFILE_ATTEMPING = "GET_CUSTOMER_PROFILE_ATTEMPING";
export const GET_CUSTOMER_PROFILE_FAILED = "GET_CUSTOMER_PROFILE_FAILED";
export const GET_CUSTOMER_PROFILE_SUCCESS = "GET_CUSTOMER_PROFILE_SUCCESS";

export const SEND_RESET_PASSWORD_ATTEMPING = "SEND_RESET_PASSWORD_ATTEMPING";
export const SEND_RESET_PASSWORD_SUCCESS = "SEND_RESET_PASSWORD_SUCCESS";
export const SEND_RESET_PASSWORD_FAILED = "SEND_RESET_PASSWORD_FAILED";

export const EDIT_PROFILE_ATTEMPING = "EDIT_PROFILE_ATTEMPING";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILED = "EDIT_PROFILE_FAILED";

export const GET_PRDUCTS_ATTEMPING = "GET_PRDUCTS_ATTEMPING";
export const GET_PRDUCTS_FAILED = "GET_PRDUCTS_FAILED";
export const GET_PRDUCTS_SUCCESS = "GET_PRDUCTS_SUCCESS";

export const GET_HOME_PAGE_ATTEMPING = "GET_HOME_PAGE_ATTEMPING";
export const GET_HOME_PAGE_FAILED = "GET_HOME_PAGE_FAILED";
export const GET_HOME_PAGE_SUCCESS = "GET_HOME_PAGE_SUCCESS";

export const GET_ORDERS_ATTEMPING = "GET_ORDERS_ATTEMPING";
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";

export const GET_ORDERS_STATS_ATTEMPING = "GET_ORDERS_STATS_ATTEMPING";
export const GET_ORDERS_STATS_FAILED = "GET_ORDERS_STATS_FAILED";
export const GET_ORDERS_STATS_SUCCESS = "GET_ORDERS_STATS_SUCCESS";


export const CREATE_ORDER_ATTEMPING = "CREATE_ORDER_ATTEMPING";
export const CREATE_ORDER_FAILED = "CREATE_ORDER_FAILED";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";


export const RESET_PASSWORD_ATTEMPING = 'RESET_PASSWORD_ATTEMPING';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const FORGOT_PASSWORD_ATTEMPING = 'FORGOT_PASSWORD_ATTEMPING';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';


export const UPLOAD_FILE_ATTEMPING = 'UPLOAD_FILE_ATTEMPING';
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';


export const GET__ORDER_COUPON_ATTEMPING = 'GET__ORDER_COUPON_ATTEMPING';
export const GET__ORDER_COUPON_FAILED = 'GET__ORDER_COUPON_FAILED';
export const GET__ORDER_COUPON_SUCCESS = 'GET__ORDER_COUPON_SUCCESS';


export const CREATE_CHAT_MESSAGE_ATTEMPING = "CREATE_CHAT_MESSAGE_ATTEMPING";
export const CREATE_CHAT_MESSAGE_FAILED = "CREATE_CHAT_MESSAGE_FAILED";
export const CREATE_CHAT_MESSAGE_SUCCESS = "CREATE_CHAT_MESSAGE_SUCCESS";

export const GET_CHAT_MESSAGES_STUDENT_ATTEMPING = "GET_CHAT_MESSAGES_STUDENT_ATTEMPING";
export const GET_CHAT_MESSAGES_STUDENT_FAILED = "GET_CHAT_MESSAGES_STUDENT_FAILED";
export const GET_CHAT_MESSAGES_STUDENT_SUCCESS = "GET_CHAT_MESSAGES_STUDENT_SUCCESS";


export const GET_CHAT_MESSAGES_ATTEMPING = "GET_CHAT_MESSAGES_ATTEMPING";
export const GET_CHAT_MESSAGES_FAILED = "GET_CHAT_MESSAGES_FAILED";
export const GET_CHAT_MESSAGES_SUCCESS = "GET_CHAT_MESSAGES_SUCCESS";


export const CHANGE_CHAT_MESSAGE_STATUS_ATTEMPING = "CHANGE_CHAT_MESSAGE_STATUS_ATTEMPING";
export const CHANGE_CHAT_MESSAGE_STATUS_FAILED = "CHANGE_CHAT_MESSAGE_STATUS_FAILED";
export const CHANGE_CHAT_MESSAGE_STATUS_SUCCESS = "CHANGE_CHAT_MESSAGE_STATUS_SUCCESS";



export const CHANGE_CHAT_MESSAGE_SEEN_ATTEMPING = "CHANGE_CHAT_MESSAGE_SEEN_ATTEMPING";
export const CHANGE_CHAT_MESSAGE_SEEN_FAILED = "CHANGE_CHAT_MESSAGE_SEEN_FAILED";
export const CHANGE_CHAT_MESSAGE_SEEN_SUCCESS = "CHANGE_CHAT_MESSAGE_SEEN_SUCCESS";


export const SAVE_WRITER_PAYMENT_ATTEMPING = "SAVE_WRITER_PAYMENT_ATTEMPING";
export const SAVE_WRITER_PAYMENT_FAILED = "SAVE_WRITER_PAYMENT_FAILED";
export const SAVE_WRITER_PAYMENT_SUCCESS = "SAVE_WRITER_PAYMENT_SUCCESS";


export const EDIT_WRITER_PAYMENT_ATTEMPING = "EDIT_WRITER_PAYMENT_ATTEMPING";
export const EDIT_WRITER_PAYMENT_FAILED = "EDIT_WRITER_PAYMENT_FAILED";
export const EDIT_WRITER_PAYMENT_SUCCESS = "EDIT_WRITER_PAYMENT_SUCCESS";


export const DELETE_WRITER_PAYMENT_ATTEMPING = "DELETE_WRITER_PAYMENT_ATTEMPING";
export const DELETE_WRITER_PAYMENT_FAILED = "DELETE_WRITER_PAYMENT_FAILED";
export const DELETE_WRITER_PAYMENT_SUCCESS = "DELETE_WRITER_PAYMENT_SUCCESS";



export const GET_WRITER_PAYMENTS_ATTEMPING = "GET_WRITER_PAYMENTS_ATTEMPING";
export const GET_WRITER_PAYMENTS_FAILED = "GET_WRITER_PAYMENTS_FAILED";
export const GET_WRITER_PAYMENTS_SUCCESS = "GET_WRITER_PAYMENTS_SUCCESS";


export const CREATE_NOTIFICATION_ATTEMPING = "CREATE_NOTIFICATION_ATTEMPING";
export const CREATE_NOTIFICATION_FAILED = "CREATE_NOTIFICATION_FAILED";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";



export const GET_NOTIFICATIONS_ATTEMPING = "GET_NOTIFICATIONS_ATTEMPING";
export const GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_FAILED";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";



export const CHANGE_NOTIFICATIONS_STATUS_ATTEMPING = "CHANGE_NOTIFICATIONS_STATUS_ATTEMPING";
export const CHANGE_NOTIFICATIONS_STATUS_FAILED = "CHANGE_NOTIFICATIONS_STATUS_FAILED";
export const CHANGE_NOTIFICATIONS_STATUS_SUCCESS = "CHANGE_NOTIFICATIONS_STATUS_SUCCESS";




export const DELETE_CHAT_MESSAGE_ATTEMPING = "DELETE_CHAT_MESSAGE_ATTEMPING";
export const DELETE_CHAT_MESSAGE_FAILED = "DELETE_CHAT_MESSAGE_FAILED";
export const DELETE_CHAT_MESSAGE_SUCCESS = "DELETE_CHAT_MESSAGE_SUCCESS";


export const DELETE_NOTIFICATION_ATTEMPING = "DELETE_NOTIFICATION_ATTEMPING";
export const DELETE_NOTIFICATION_FAILED = "DELETE_NOTIFICATION_FAILED";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";



export const GET_ADMIN_LEDGER_LIST_ATTEMPING = "GET_ADMIN_LEDGER_LIST_ATTEMPING";
export const GET_ADMIN_LEDGER_LIST_FAILED = "GET_ADMIN_LEDGER_LIST_FAILED";
export const GET_ADMIN_LEDGER_LIST_SUCCESS = "GET_ADMIN_LEDGER_LIST_SUCCESS";


export const SAVE_ADMIN_LEDGER_ITEM_ATTEMPING = "SAVE_ADMIN_LEDGER_ITEM_ATTEMPING";
export const SAVE_ADMIN_LEDGER_ITEM_FAILED = "SAVE_ADMIN_LEDGER_ITEM_FAILED";
export const SAVE_ADMIN_LEDGER_ITEM_SUCCESS = "SAVE_ADMIN_LEDGER_ITEM_SUCCESS";


export const DELETE_ADMIN_LEDGER_ITEM_ATTEMPING = "DELETE_ADMIN_LEDGER_ITEM_ATTEMPING";
export const DELETE_ADMIN_LEDGER_ITEM_FAILED = "DELETE_ADMIN_LEDGER_ITEM_FAILED";
export const DELETE_ADMIN_LEDGER_ITEM_SUCCESS = "DELETE_ADMIN_LEDGER_ITEM_SUCCESS";



export const CREATE_NOTE_ATTEMPING = "CREATE_NOTE_ATTEMPING";
export const CREATE_NOTE_FAILED = "CREATE_NOTE_FAILED";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";


export const DELETE_NOTE_ATTEMPING = "DELETE_NOTE_ATTEMPING";
export const DELETE_NOTE_FAILED = "DELETE_NOTE_FAILED";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";


export const GET_NOTES_ATTEMPING = "GET_NOTES_ATTEMPING";
export const GET_NOTES_FAILED = "GET_NOTES_FAILED";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";


export const SHARE_NOTE_ATTEMPING = "SHARE_NOTE_ATTEMPING";
export const SHARE_NOTE_FAILED = "SHARE_NOTE_FAILED";
export const SHARE_NOTE_SUCCESS = "SHARE_NOTE_SUCCESS";



export const GET_ORDER_LEDGER_ATTEMPING = "GET_ORDER_LEDGER_ATTEMPING";
export const GET_ORDER_LEDGER_FAILED = "GET_ORDER_LEDGER_FAILED";
export const GET_ORDER_LEDGER_SUCCESS = "GET_ORDER_LEDGER_SUCCESS";

export const UPDATE_USER_STATUS_ATTEMPING = "UPDATE_USER_STATUS_ATTEMPING";
export const UPDATE_USER_STATUS_FAILED = "UPDATE_USER_STATUS_FAILED";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";

export const SEND_SMS_ATTEMPING = "SEND_SMS_ATTEMPING";
export const SEND_SMS_FAILED = "SEND_SMS_FAILED";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";

export const SEND_EMAIL_ATTEMPING = "SEND_EMAIL_ATTEMPING";
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";



export const GET_STUDENT_SUBSCRIPTION_ATTEMPING = "GET_STUDENT_SUBSCRIPTION_ATTEMPING";
export const GET_STUDENT_SUBSCRIPTION_FAILED = "GET_STUDENT_SUBSCRIPTION_FAILED";
export const GET_STUDENT_SUBSCRIPTION_SUCCESS = "GET_STUDENT_SUBSCRIPTION_SUCCESS";


export const GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_ATTEMPING = "GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_ATTEMPING";
export const GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_FAILED = "GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_FAILED";
export const GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_SUCCESS = "GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_SUCCESS";


export const UPDATE_SUBSCRIPTION_ATTEMPING = "UPDATE_SUBSCRIPTION_ATTEMPING";
export const UPDATE_SUBSCRIPTION_FAILED = "UPDATE_SUBSCRIPTION_FAILED";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";


export const GET_STUDENTS_ATTEMPING = "GET_STUDENTS_ATTEMPING";
export const GET_STUDENTS_FAILED = "GET_STUDENTS_FAILED";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
