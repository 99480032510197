import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../Utils/Colors";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#c3c3c322",
    display: "flex",
    width: "100%",
    height: "100%",

    minHeight: "100vh",
    minWidth: window.innerWidth - 210,
    padding: "30px",
    flexDirection: "column",
  },
  BootstrapDialog: {},

  container2: {
    background: Colors.white,
    textTransform: "none",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px 2px #c7c7c755",
    margin: "10px",
    width: "250px",
    height: "150px",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #30374500",
  },
  tableCell: { fontFamily: "Spartan" },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "30px",
    alignSelf: "center",
    border: "5px solid #f5f5f5",
  },
  backButton: {
    justifyContent: "flex-start",
    width: "80px",
  },
  options: {
    justifyContent: "flex-start",
    width: "80px",
    position: "relative",
    right: "20px",
    top: "20px",
  },

  noOrders: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    marginLeft: "5px",
    textAlign: "center",
    alignSelf: "center",
    width: "100%",
  },
  createNewPasswordButton: {
    fontFamily: "Spartan",
    fontSize: "16px",
    fontWeight: "bold",
    position: "absolute",
    borderRadius: "40px",

    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  createNewPassword: {
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    fontWeight: "bold",
    height: "35px",
    backgroundColor: Colors.grey,
    borderRadius: "20px",
    color: Colors.white,
    textTransform: "none",

    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  showPassword: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginLeft: "10px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {},
  },
  search: {
    fontFamily: "Spartan",
    marginLeft: "15px",
  },
  formInput: {
    fontFamily: "Spartan",
    marginLeft: "15px",
  },
  createPaymentButton: {
    width: "180px",
    height: "50px",
    backgroundColor: Colors.black,
  },
  fileName:{

    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "10px",
    height: "50px",
    textDecoration:'underline'
  },
  AttachFile: {
    backgroundColor: Colors.grey5,
    color: Colors.white,
    marginLeft: "40px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px",
    height: "60px",
    width: "500px",
    marginTop:"20px",
    borderRadius: "20px",
    alignSelf:"center",
    border:"1px solid #c3c3c3"
  },
  messageText: {
    color: Colors.white,
    fontFamily: "Spartan",
    fontSize: "13px",
    marginTop: "5px",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  chooseImg: {
    width: "100px",
    height: "100px",
    borderRadius: "50px",
  },
  progress: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  name: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.dark,
  },
  value: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.black,
    marginLeft: "5px",
    width: "600px",
  },

  paymentStatus: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  Paypal: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  email: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "14px",
    marginTop: "5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  save: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "100px",
    height: "40px",
    borderRadius: "20px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {},
    color: Colors.blue,
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  price: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  orderStatus: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "20px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  password: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "20px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  numberText: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "14px",
    textAlign: "center",
    marginTop: "20px",
    fontWeight: "bold",
  },
  number: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "22px",
    textAlign: "center",
    marginTop: "20px",
    fontWeight: "bold",
  },
  idOrder: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "15px",
    marginLeft: "15px",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {},
  },

  date: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  id: {
    color: Colors.darkGrey,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px",
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan",
  },
}));

export default useStyles;
