import {
  GET__ORDER_COUPON_ATTEMPING,
  GET__ORDER_COUPON_FAILED,
  GET__ORDER_COUPON_SUCCESS,
} from "./Types";
import axios from "axios";
import { ServiceURL } from "../../global/services/config";
import AsyncStorage from "@callstack/async-storage";

export const getCoupon = (productId, couponCode) => {
  return async (dispatch) => {
    dispatch({
      type: GET__ORDER_COUPON_ATTEMPING,
    });
    const email = await AsyncStorage.getItem("email");

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetCoupon(couponCode),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      if (data.status == "ACTIVE") {

        var config2 = {
          method: "get",
          url: ServiceURL.GetOrdersCoupon(email,""),
          headers: ServiceURL.headers,
        };
      axios(config2).then(resp=>{

        const count=resp.data.total
        
          if (count==0&&data.applicationLimit=="NEW_CUSTOMER_ONLY") {
            dispatch({ type: GET__ORDER_COUPON_SUCCESS, payload: data });

          }else if (count>0&&data.applicationLimit=="UNLIMITED") {
            dispatch({ type: GET__ORDER_COUPON_SUCCESS, payload: data });

           
          }else {
            dispatch({
              type: GET__ORDER_COUPON_FAILED,
              payload: "Coupon is not found",
            });
          }
      }).catch(error=>{
      });
       

      } else {
        dispatch({
          type: GET__ORDER_COUPON_FAILED,
          payload: "Coupon is not found",
        });
      }
    } catch (error) {
      if (couponCode == "dummy") {
        dispatch({
          type: GET__ORDER_COUPON_FAILED,
          payload: null,
        });
      } else
        dispatch({
          type: GET__ORDER_COUPON_FAILED,
          payload: error.response.data.errorMessage,
        });
    }
  };
};
