export default {
  primaryColor: "#0b0b15",
  secondaryColor: "#40ac4e",
  darkBlue: "#5493f4",
  darkGreen: "#40ac4e",
  blue: "#157cfb",
  lightBlue: "#1eaffb",
  purple: "#f851bf",
  lightPurple: "#F1EEFC",
  green: "#019267",
  lightGreen: "#4CD964",
  extraLightGreen: "#9eeeab",

  green2: "#3ac569",
  greenOpacity: "#3ac56988",
  orange: "#E8630A",
  red: "#db5143",
  white: "#fff",
  black: "#0b0b15",
  lightBlack: "#303745",
  lightBlack2: "#353945",
  grey: "#a4a4a4",
  offWhite: "#fafafa",
  grey2: "#B8BECE",
  grey3: "#ececec",
  grey4: "#979797",
  grey5: "#fdfcfe",
  grey6: "#f4f4f4",
  lightGrey: "#ced2dc",
  darkGrey: "#6a6a6a",
  yellow: "#ffa23d",
  labany: "#1eaffb",
  brown: "#ed9574",
  dark: "#757575",
  none: "#ffffff00",
  opacity: "#ffffff33",
  opacity2: "#00000033",
  faceBook: "#4065ac",
  google: "#da4446",
  errorBackColor: "#f7d7da",
  errorColor: "#711d26",
};
