import React, { useState, useEffect, useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  getWriterLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem,
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";

import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import AsyncStorage from "@callstack/async-storage";
import Delete from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import WriterOrders from "../WriterOrders/View";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const AdminLedger = ({
  selectedWriter,

  loadingGetWritersStats,

  createWriterPayment,
  loadingAddWriterPayment,
  deleteWriterPayment,
  writerPayments,
  getWriterPayment,
  role,
  adminLedger,
  getWriterLedger,
  loadingSaveAdminLedger,
  createManaulLedgerItem,
  loadingAdminLedger,
  deleteAdminLedgerItem,
  adminLedgerTotal,

  orderLedgerTotal
}) => {
  const initailFormState = {
    name: null,
    email: null,
    password: null,
    confirmPassword: null,

    type: null,
    price: 0,
    date: new Date().toUTCString(),
    name: "",
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);
  var curr = new Date(); // get current date
  var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
  var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [openPament, setOpenPament] = React.useState(false);
  const [formErrorsPayment, setErrorsPayment] = useState([]);
  const [profile, setProfile] = useState(null);
  const [payments, setPayments] = useState([]);
  const [showDetails, setShowDetails] = useState(true);
  const [dateFrom, setDateFrom] = useState(firstday);
  const [dateTo, setDateTo] = useState(lastday);
  const [totalPaid, setTotalPaid] = useState(0);
  const [adminLedgerList, setAdminLedgerList] = useState([]);
  const [totalDue, setTotalDue] = useState(0.0);
  const [query, setQuery] = useState("");

  let [sliceFrom, setSliceFrom] = useState(0);
  const [sliceTo, setSliceTo] = useState(7);
  const [page, setPage] = useState(1);

  const handleClosePayment = () => {
    setOpenPament(false);
  };

  useEffect(async () => {
    const email = await AsyncStorage.getItem("email");

    getWriterLedger(query,email, dateFrom, dateTo);
  }, []);

  useEffect(async () => {
    const email = await AsyncStorage.getItem("email");

    getWriterLedger(query,email, dateFrom, dateTo);
  }, [dateFrom, dateTo,query]);

  useEffect(() => {
    if (adminLedger) {
      setAdminLedgerList(
        adminLedger
          ?.sort(
            (objA, objB) =>
              Number(new Date(objB.date)) - Number(new Date(objA.date))
          )
          ?.filter((value, index) => {
            const _value = JSON.stringify(value);
            return (
              index ===
              adminLedger?.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
              })
            );
          })
      );
      let totalPaid = 0;
      adminLedger?.map((item, index) => {
        totalPaid += parseInt(item.price);
      });
      setTotalPaid(totalPaid);
    }
  }, [adminLedger,page]);


  useEffect(() => {

    if (writerPayments && adminLedger) {
      let total = 0.0;
      adminLedger?.map((payment) => {

        if(new Date(payment.date)>new Date("2024-03-5")){
          total += parseFloat(payment?.price);
          }

       
      });

     
      setTotalDue(total);
    }

  

   
  }, [writerPayments, orderLedgerTotal,adminLedger]);

  const Item = (children, value) => {
    return (
      <Grid
        component={motion.div}
        whileHover={{
          backgroundColor: Colors.dark + "10",
          transition: { duration: 0.7 },
          scale: 1.05,
          borderColor: Colors.blue + "88",
          borderWidth: "1px",
        }}
        className={styles.container2}
        container
        direction="column"
      >
        {children}

        {loadingAdminLedger ? (
          <CircularProgress
            size="20px"
            style={{ color: Colors.green, marginTop: "20px" }}
          />
        ) : (
          <Typography className={styles.number}>{value}</Typography>
        )}
      </Grid>
    );
  };

  return showDetails ? (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid container direction="row">
        {Item(
          <Typography className={styles.numberText}>Total Due</Typography>,
          "$" +totalDue
        )}

        {/*Item(
          <Typography className={styles.numberText}>Count</Typography>,
          adminLedger?.length || 0
        )*/}
      </Grid>
      <Grid direction="row" container>
      <Grid className={styles.formInput} item>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            color="primary"
            style={{
              width: "300px",
              fontFamily: "Spartan",
              marginTop: "17px",

            }}
            onChange={(event) => {
                setQuery( event.target.value? event.target.value:"")
            }}
          />
        </Grid>
        <Grid className={styles.formInput} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="From"
              inputFormat="MM/dd/yyyy"
              onChange={(value) => {
                setDateFrom(value);
              }}
              value={dateFrom}
              renderInput={(params) => (
                <TextField
                  style={{ marginTop: "17px", width: "170px" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid className={styles.formInput} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="To"
              inputFormat="MM/dd/yyyy"
              onChange={(value) => {
                setDateTo(value);
              }}
              value={dateTo}
              renderInput={(params) => (
                <TextField
                  style={{ marginTop: "17px", width: "170px" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <TableContainer
        sx={{
          width: window.innerWidth - 300,
          marginLeft: "20px",
          marginTop: "30px",
        }}
        component={Paper}
      >
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow style={{}}>
            <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold",
                }}
              >
                Num#
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold",
                }}
              >
                Date
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold",
                }}
              >
                Name
              </TableCell>

              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  fontWeight: "bold",
                  color: Colors.white,
                }}
                align="left"
              >
                Type
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  fontWeight: "bold",
                  color: Colors.white,
                }}
                align="left"
              >
                Note
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold",
                }}
                align="right"
              >
                Gross
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {adminLedgerList?.slice(sliceFrom,sliceTo)?.map((row,index) => (
              <TableRow
              
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{backgroundColor:String(row?.name).toUpperCase().includes("PAYMENT")? Colors.green+"33":Colors.white}}
                >
                 <TableCell style={{ fontFamily: "Spartan", fontSize: "12px" }}>
                  {++sliceFrom}
                </TableCell>
                <TableCell style={{ fontFamily: "Spartan", fontSize: "12px" }}>
                  {new Date(
                    new Date(row?.date).toLocaleString("en-US", {
                      timeZone: "America/New_York",
                    })
                  )
                    .toString()
                    .substr(0, 24)}
                </TableCell>

                <TableCell
                  style={{ fontFamily: "Spartan", fontSize: "12px" }}
                  className={styles.tableCell}
                  component="th"
                  scope="row"
                >
                  {row?.name}
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {row?.type}
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                  }}
                  align="left"
                >
                  {row?.note}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  ${row?.price}
                </TableCell>
               
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack>
        <Pagination
          onChange={(event, value) => {
            setPage(value);
            setAdminLedgerList([])
            if (value==1) {
              setSliceFrom(0);
            setSliceTo( 7);
            } else {
              setSliceFrom((value - 1) * 7 );
            setSliceTo(value * 7);
            }

            
          }}
          page={page}
          style={{ alignSelf: "center", marginTop: "20px" }}
          count={Math.ceil(adminLedger?.length / 7)}
          color="primary"
        />
      </Stack>
    </div>
  ) : (
    <WriterOrders
      role={role}
      selectedWriter={selectedWriter}
      onClickBack={() => setShowDetails(true)}
    />
  );
};

const mapStateToProps = ({ writer, ledger }) => {
  return {
    adminLedger: ledger.adminLedger,
    loadingSaveAdminLedger: ledger.loadingSaveAdminLedger,
    loadingAdminLedger: ledger.loadingAdminLedger,
    adminLedgerTotal: ledger.adminLedgerTotal,

    orderLedgerTotal: ledger.orderLedgerTotal,
    writerPayments: writer.writerPayments,

  };
};

export default connect(mapStateToProps, {
  getWriterLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem,
})(AdminLedger);
