import {

    GET_PRDUCTS_ATTEMPING,
    GET_PRDUCTS_FAILED ,
    GET_PRDUCTS_SUCCESS 

} from '../actions/Types';

const INITIAL_STATE = {
    loading: false, error: null, products: null
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_PRDUCTS_ATTEMPING:
            return {  loading: true }

        case GET_PRDUCTS_SUCCESS:
            return {  loading: false, products: action.payload, }

        case GET_PRDUCTS_FAILED:
            return {  loading: false, error: action.payload, }

        default: return state;

    }

};
