import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Colors from '../../Utils/Colors'

import Section1 from './Section1';

import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Header = () => {

    const styles = useStyles()

    return (
        <div className={styles.container}>

            <Section1 learnMoreClick={() => {
               
            }} />

        </div >

    );
    ;
}

const useStyles = makeStyles({
    container: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        backgroundColor: Colors.primaryColor,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        


    },
})


export default Header 
