import React, { useState, useEffect, useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { motion } from "framer-motion";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      style={{ backgroundColor: Colors.blue }}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ color: Colors.white }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EditWriterPayment = ({
  loadingAddWriterPayment,

  signup,
  editPayment,
  handleClose,
  handleClickOpen,
  open,
  onChangeItem,
  onChangeDate,
  onChangePrice,
  formErrors,
  onChangeNote,
  selectedPayment,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [date, setDate] = useState(selectedPayment?.date);
  const [item, setItem] = useState(selectedPayment?.item);
  const [note, setNote] = useState(selectedPayment?.note);
  const [price, setPrice] = useState(selectedPayment?.price);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={styles.BootstrapDialog}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{
            fontSize: "13px",
            fontFamily: "Spartan",
            fontWeight: "bold",
            color: Colors.white,
          }}
        >
          Edit {selectedPayment?.item}
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid className={styles.formInput} item>
          <TextField
            value={item||selectedPayment?.item}
            id="outlined-basic"
            label="Item"
            variant="outlined"
            color="primary"
            style={{ marginTop: "10px", width: "500px" }}
            onChange={(event)=>{
              setItem(event.target.value)
              onChangeItem(event)
            }}          />
        </Grid>
        <Grid className={styles.formInput} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(value) => {
                setDate(value);
                onChangeDate(value);
              }}
              value={date||selectedPayment?.date}
              renderInput={(params) => (
                <TextField
                  style={{ marginTop: "10px", width: "500px" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid className={styles.formInput} item>
          <TextField
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key) && !/[.-]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={price||selectedPayment?.price}
            id="outlined-basic"
            label="Price"
            variant="outlined"
            color="primary"
            style={{
              marginTop: "10px",
              width: "500px",
              fontFamily: "Spartan",
            }}
            onChange={(event)=>{
              setPrice(event.target.value)
              onChangePrice(event)
            }}
          />
        </Grid>
        <Grid className={styles.formInput} item>
          <TextField
            value={note||selectedPayment?.note}
            multiline={true}
            id="outlined-basic"
            label="Note"
            variant="outlined"
            color="primary"
            style={{
              marginTop: "10px",
              width: "500px",
              fontFamily: "Spartan",
            }}
            onChange={(event)=>{
              setNote(event.target.value)
              onChangeNote(event)
            }}
          />
        </Grid>
        <Grid item>
          {formErrors.length > 0 && (
            <Alert
              style={{
                width: "500px",
                marginTop: "20px",
                marginLeft: "15px",
              }}
              severity="error"
            >
              {formErrors.map((error, index) => {
                return (
                  <Typography
                    style={{ fontSize: "13px", fontFamily: "Spartan" }}
                  >
                    {error}
                  </Typography>
                );
              })}
            </Alert>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.green,
            transition: { duration: 0.3 },
            color: Colors.white,
          }}
          className={styles.save}
          autoFocus
          onClick={editPayment}
        >
          {loadingAddWriterPayment ? (
            <CircularProgress style={{ color: Colors.blue }} />
          ) : (
            <Typography
              style={{
                fontSize: "15px",
                fontFamily: "Spartan",
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Save
            </Typography>
          )}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

const mapStateToProps = ({ writer }) => {
  return {};
};

export default connect(mapStateToProps, {})(EditWriterPayment);
