import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Payment from "@mui/icons-material/Payment";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import Avatar from "@mui/material/Avatar";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";

const ProductItem = ({ product, buyAction }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();

  return (
    <Grid
      component={motion.div}
      whileHover={{
        transition: { duration: 0.7 },
        scale: 1.05,
        borderColor: Colors.blue + "88",
        borderWidth: "1px",
      }}
      className={styles.container}
      container
      direction="column"
      spacing={2}
      wrap="nowrap"
    >
      <img
        variant="circular"
        className={styles.avatar}
        src={product.imageUrl}


      />

      <Typography className={styles.name}>
        {product?.defaultDisplayedPriceFormatted}
      </Typography>

      <Button
        component={motion.button}
        whileHover={{
          backgroundColor: Colors.blue,
          transition: { duration: 0.3 },
        }}
        className={styles.moreItem}
        onClick={buyAction}
      >
        <Typography className={styles.more}>Buy</Typography>
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: Colors.white,
    textTransform: "none",
    padding: "25px",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px 2px #c7c7c755",
    margin: "10px",
    width: "200px",
    height: "230px",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid #30374500",
  },
  avatar: {
    maxWidth: "200px",
    maxHeight: "100px",
    width: "auto",
    height: "auto"
   
  },

  name: {
    fontSize: "13px",
    fontFamily: "Spartan",
    color: Colors.black,
    fontWeight: "bold",
    marginTop: "15px",
  },

  email: {
    color: Colors.dark,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    width: "200px",
    textAlign: "center",
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "100px",
    height: "35px",
    background: Colors.black,
    borderRadius: "20px",
    color: Colors.white,
    textTransform: "none",

    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
}));

export default ProductItem;
