import React, { useState, useEffect, useReducer, useRef } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import { getCoupon, createOrders } from "../../../../Redux/actions";
import AsyncStorage from "@callstack/async-storage";
import ArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeft";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import firebase from "../../../../global/services/Firebase";
import CheckStudentSubscriptionStatus from "./CheckStudentSubscriptionStatus";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useFilePicker } from "use-file-picker";
import CircularProgress from "@mui/material/CircularProgress";
import Close from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import Payment from "../../Student/payment/index.js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalButton } from "react-paypal-button-v2";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const ProductForm = ({
  onClick,
  formData,
  getCoupon,
  loadingCoupon,
  errorCoupon,
  coupon,
  role,
  createOrders,
  loading,
  openOrderManagement,
  pagesLeft,
  subscriptionStatus
}) => {
  const initailFormState = {
    options: [],
    options2: [],
    dialogText: "",
    images: [],
    extraPrice: 0
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);

  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [uploadingFile, setUploadingFile] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [value, setValue] = React.useState(null);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isPaymentApproved, setIsPaymentApproved] = useState(false);

  const inputRef = useRef();
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: ""
  });

  const [openFileSelector, { filesContent, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    accept: [
      ".json",
      ".pdf",
      ".docx",
      ".doc",
      ".txt",
      ".ppt" + ".png",
      ".jpeg",
      ".jpg",
      ".xlsx",
      ".zip",
      ".rar",
      "image/*"
    ],

    multiple: false
    // limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
  });
  console.log("rolerolerole", role);
  useEffect(async () => {
    if (filesContent.length > 0) {
      const url = await uploadImageAsync(
        filesContent[0].content,
        filesContent[0].name
      );

      let result2 = [];
      let files = [];

      files.push({
        id: makeid(8),
        name: filesContent[0].name,
        size: parseInt(makeid(6)),
        url: url,
        type: "file"
      });
      setFiles(files);

      form.options2.map((option) => {
        if (
          String(option.name).includes("Upload") ||
          String(option.name).includes("upload")
        ) {
          result2.push({
            ...option,
            required: false,
            files: [
              ...(option.files || []),
              {
                id: makeid(8),
                name: filesContent[0].name,
                size: parseInt(makeid(6)),
                url: url,
                type: "file"
              }
            ]
          });
        } else result2.push(option);
      });

      updateForm({
        key: "options2",
        value: result2
      });
    }
  }, [filesContent]);

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const uploadImageAsync = async (uri, name) => {
    setUploading(true);
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    const ref = firebase
      .storage()
      .ref("dashboard/orders/" + makeid(15))
      .child(name);
    const snapshot = await ref.put(blob);
    setUploading(false);

    return await snapshot.ref.getDownloadURL();
  };

  useEffect(() => {
    updateForm({
      key: "options",
      value: formData.options
    });
    updateForm({
      key: "options2",
      value: formData.options
    });
    getCoupon(formData?.id, "dummy");
  }, []);

  useEffect(() => {
    let total = 0;
    form.options2.map((option) => {
      if (option.type == "RADIO" || option.type == "SELECT") {
        option?.selections?.map((selection) => {
          total += selection?.selectionModifier;
        });
      }
    });
    updateForm({
      key: "extraPrice",
      value: total
    });
    let error = [];
    form?.options2?.map((item, index) => {
      if (item.required == true) {
        error.push(item);
      }
      if (
        item.type == "TEXTFIELD" &&
        String(item.name).includes("Date") &&
        (item?.value == "NaN-NaN-NaN" ||
          item?.value == "undefined-NaN-undefined" ||
          String(item?.value).length < 10)
      ) {
        error.push(item);
      }
      if (
        item.type == "TEXTFIELD" &&
        !String(item.name).includes("Date") &&
        (item?.value == "")
      ) {
        error.push(item);
      }
    });
    setErrorList(error);
  }, [form.options2]);

  useEffect(() => {
    if (isPaymentApproved) {
      placeOrderAction();
    }
  }, [isPaymentApproved]);
  useEffect(() => {
    if (form?.options) {
      form?.options?.map((item, index) => {
        if (
          item.type == "TEXTAREA" &&
          String(item.name).includes("Instructions/Requirements") &&
          (!item?.value || item?.value == "")
        ) {
          let result = [];
          form.options2.map((option) => {
            if (item.name == option.name) {
              result.push({
                ...option,
                value: "-",
                valuesArray: ["-"],
                required: false
              });
            } else result.push(option);
          });

          updateForm({
            key: "options2",
            value: result
          });
        }
      });
    }
  }, [form?.options]);
  const placeOrderAction = async () => {
    let error = [];
    let pageCount = 0;
    console.log("form?.options2", form?.options2);

    form?.options2?.map((item, index) => {
      if (item.required == true) {
        error.push(item);
      }
      if (item?.name == "Page Count") {
        pageCount = item?.value;
      }
    });
    if (error.length > 0) {
      toast.error(error[0].name + " is required");
    } else if (uploadingFile) {
      toast.error("Please, wait until the file is uploaded");
    } else {
      const email = await AsyncStorage.getItem("email");
      const details = {
        options: form.options2,
        price: coupon
          ? Math.round(
              (coupon.discountType == "ABS"
                ? formData.price + form.extraPrice - parseInt(coupon.discount)
                : formData.price +
                  form.extraPrice -
                  ((formData.price + form.extraPrice) *
                    parseInt(coupon.discount)) /
                    100) * 100
            ) / 100
          : formData.price + form.extraPrice,
        quantity: 1,
        name: email,
        product: formData,
        coupon: coupon
      };

      createOrders(
        role == "student" ? email : "support@apluspaperwriting.com",
        details.product,
        details.options,
        details.price,
        details.coupon,
        (data) => {
          console.log("data", data);
          if (data) {
            setOrderId(data?.orderId);
            toast.success(`Your order with ID (${data?.orderId}) has been created 
            successfully!`);
            setIsPaymentApproved(false);
            openOrderManagement();
          }
        },
        (error) => {
          setOrderId("");
          setIsPaymentApproved(false);
          toast.error(error);
          console.log("error", error, details);
        }
      );
    }
  };

  const paypalCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: coupon
              ? Math.round(
                  (coupon.discountType == "ABS"
                    ? formData.price +
                      form.extraPrice -
                      parseInt(coupon.discount)
                    : formData.price +
                      form.extraPrice -
                      ((formData.price + form.extraPrice) *
                        parseInt(coupon.discount)) /
                        100) * 100
                ) / 100
              : parseFloat(formData.price + form.extraPrice)
            //formData.price + form.extraPrice
          }
        }
      ]
    });
  };
  console.log("form?.options2", form?.options2);

  return (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Button onClick={onClick} className={styles.backButton}>
        <ArrowLeftOutlined className={styles.more} />

        <Typography className={styles.more}>Back</Typography>
      </Button>

      <Typography className={styles.idOrder}>Create Order</Typography>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid container className={styles.leftContainer}>
          <img src={formData.imageUrl} className={styles.image} />
          <Typography className={styles.productDetails}>
            Product Details
          </Typography>
          <Typography className={styles.desc}>
            {formData.description.replace(/<\/?[^>]+(>|$)/g, "")}
          </Typography>
        </Grid>
        <Grid container className={styles.rightContainer}>
          <Typography className={styles.idOrder}>{formData.name}</Typography>

          <Grid className={styles.formInput} style={{}} item>
            <Typography className={styles.inputTitle}>Apply Coupon</Typography>
            <Grid item direction="row" style={{ height: "65px" }}>
              <TextField
                ref={inputRef}
                id="outlined-basic"
                variant="outlined"
                style={{ marginTop: "10px", width: "65%" }}
                onChange={(event) => {
                  setCouponCode(event.target.value);
                }}
              />

              <Button
                component={motion.button}
                whileHover={{
                  backgroundColor: Colors.blue,
                  transition: { duration: 0.3 },
                  color: Colors.white
                }}
                className={styles.addFiles}
                style={{
                  backgroundColor: "#000",
                  color: "white",
                  fontFamily: "Spartan",
                  textTransform: "none",
                  fontWeight: "bold",
                  marginTop: "10px",
                  height: "55px"
                }}
                onClick={() => {
                  if (!couponCode) {
                    toast.current.show("Please, enter coupon");
                  } else {
                    getCoupon(formData?.id, couponCode);
                  }
                }}
              >
                {loadingCoupon ? (
                  <CircularProgress
                    style={{
                      alignSelf: "center",
                      color: Colors.white,
                      width: "30px"
                    }}
                  />
                ) : (
                  "Apply"
                )}
              </Button>
            </Grid>
            <Typography
              style={{
                fontSize: "12px",
                color: errorCoupon
                  ? Colors.darkRed
                  : coupon
                  ? Colors.green
                  : Colors.black
              }}
              className={styles.inputTitle}
            >
              {errorCoupon ||
                (coupon &&
                  (coupon?.status
                    ? coupon?.discountType == "ABS"
                      ? "Your $" +
                        coupon?.discount +
                        " OFF code has been applied!"
                      : "Your " +
                        coupon?.discount +
                        "% OFF code has been applied!"
                    : "")) ||
                ""}
            </Typography>
          </Grid>

          {coupon && (
            <Typography
              className={styles.price}
              style={{
                textDecorationLine: "line-through",
                color: Colors.red
              }}
            >
              ${formData.price + form.extraPrice}
            </Typography>
          )}

          {coupon && (
            <Typography className={styles.price}>
              $
              {Math.round(
                (coupon.discountType == "ABS"
                  ? formData.price + form.extraPrice - parseInt(coupon.discount)
                  : formData.price +
                    form.extraPrice -
                    ((formData.price + form.extraPrice) *
                      parseInt(coupon.discount)) /
                      100) * 100
              ) / 100}
            </Typography>
          )}
          {!coupon && (
            <Typography className={styles.price}>
              ${String(formData.price + form.extraPrice).substr(0.7)}
            </Typography>
          )}

          {form.options.map((item, index) => {
            if (item.type == "SELECT" || item.name == "Page Count") {
              return (
                <Grid className={styles.formInput} item>
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <Box sx={{ width: "95%", marginTop: "10px" }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          item?.selections &&
                          item?.selections[0]?.selectionTitle
                        }
                        //label="Age"
                        // onChange={handleChange}
                      >
                        {item?.choices?.map((choice, index) => {
                          return (
                            <MenuItem
                              onClick={async () => {
                                let result = [];
                                form.options2.map((option) => {
                                  if (item.name == option.name) {
                                    result.push({
                                      ...option,
                                      value: choice.text,
                                      valuesArray: [choice.text],
                                      selections: [
                                        {
                                          selectionTitle: choice.text,
                                          selectionModifier:
                                            choice.priceModifier,
                                          selectionModifierType:
                                            choice.priceModifierType
                                        }
                                      ],
                                      required: false
                                    });
                                  } else result.push(option);
                                });

                                updateForm({
                                  key: "options2",
                                  value: result
                                });
                              }}
                              value={
                                choice.text +
                                (choice.priceModifier != 0
                                  ? " (+$" + choice.priceModifier + ")"
                                  : "")
                              }
                            >
                              {choice.text +
                                (choice.priceModifier != 0
                                  ? " (+$" + choice.priceModifier + ")"
                                  : "")}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              );
            }
            else if (item.type == "RADIO") {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <FormControl>
                    <FormLabel
                      style={{
                        marginTop: "20px",
                        color: "black",
                        fontFamily: "Spartan",
                        fontWeight: "bold",
                        fontSize: "16px"
                      }}
                      id="demo-radio-buttons-group-label"
                    >
                      {item.name}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      {item?.choices?.map((choice, index) => {
                        return (
                          <FormControlLabel
                            style={{
                              fontFamily: "Spartan"
                            }}
                            key={JSON.stringify(choice)}
                            value={
                              choice.text +
                              (choice.priceModifier != 0
                                ? " (+$" + choice.priceModifier + ")"
                                : "")
                            }
                            control={
                              <Radio
                                checked={
                                  item?.selections &&
                                  choice.text ==
                                    item?.selections[0]?.selectionTitle
                                }
                                onClick={async () => {
                                  let result = [];
                                  form.options2.map((option) => {
                                    if (item.name == option.name) {
                                      result.push({
                                        ...option,
                                        value: choice.text,
                                        valuesArray: [choice.text],
                                        selections: [
                                          {
                                            selectionTitle: choice.text,
                                            selectionModifier:
                                              choice.priceModifier,
                                            selectionModifierType:
                                              choice.priceModifierType
                                          }
                                        ],
                                        required: false
                                      });
                                    } else result.push(option);
                                  });

                                  updateForm({
                                    key: "options2",
                                    value: result
                                  });
                                }}
                              />
                            }
                            label={
                              choice.text +
                              (choice.priceModifier != 0
                                ? " (+$" + choice.priceModifier + ")"
                                : "")
                            }
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              );
            } else if (
              item.type == "TEXTFIELD" &&
              !String(item.name).includes("Date")
            ) {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <TextField
                    ref={inputRef}
                    id="outlined-basic"
                    variant="outlined"
                    style={{ marginTop: "10px", width: "95%" }}
                    value={item?.value}
                    onChange={(event) => {
                      event.preventDefault();

                      let result = [];
                      form.options2.map((option) => {
                        if (item.name == option.name) {
                          result.push({
                            ...option,
                            value: event.target.value,
                            valuesArray: [event.target.value],
                            required: false
                          });
                        } else result.push(option);
                      });

                      updateForm({
                        key: "options2",
                        value: result
                      });
                      inputRef.current.focus();
                    }}
                  />
                </Grid>
              );
            } else if (
              item.type == "TEXTFIELD" &&
              String(item.name).includes("Date")
            ) {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      value={value}
                      onChange={(value) => {
                        let dd = String(value?.getDate()).padStart(2, "0");
                        let mm = String(value?.getMonth() + 1).padStart(2, "0");
                        let yyyy = value?.getFullYear();

                        setValue(value);
                        let result = [];

                        form.options2.map((option) => {
                          if (item.name == option.name) {
                            result.push({
                              ...option,
                              value: yyyy + "-" + mm + "-" + dd,
                              valuesArray: [yyyy + "-" + mm + "-" + dd],
                              required: false
                            });
                          } else result.push(option);
                        });

                        updateForm({
                          key: "options2",
                          value: result
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ marginTop: "10px", width: "95%" }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              );
            } else if (
              item.type == "DATE" &&
              String(item.name).includes("Date")
            ) {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      value={value}
                      onChange={(value) => {
                        let dd = String(value.getDate()).padStart(2, "0");
                        let mm = String(value.getMonth() + 1).padStart(2, "0");
                        let yyyy = value.getFullYear();

                        setValue(value);
                        let result = [];

                        form.options2.map((option) => {
                          if (item.name == option.name) {
                            result.push({
                              ...option,
                              value: yyyy + "-" + mm + "-" + dd,
                              valuesArray: [yyyy + "-" + mm + "-" + dd],
                              required: false
                            });
                          } else result.push(option);
                        });

                        updateForm({
                          key: "options2",
                          value: result
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ marginTop: "10px", width: "95%" }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              );
            } else if (item.type == "TEXTAREA") {
              return (
                <Grid
                  key={JSON.stringify(item)}
                  className={styles.formInput}
                  item
                >
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>
                  <TextareaAutosize
                    maxRows={7}
                    aria-label={item.name}
                    placeholder={item.name}
                    style={{
                      marginTop: "10px",
                      width: "95%",
                      maxWidth: "95%",
                      minHeight: "130px"
                    }}
                    onChange={(event) => {
                      let result = [];
                      form.options2.map((option) => {
                        if (item.name == option.name) {
                          result.push({
                            ...option,
                            value: event.target.value,
                            valuesArray: [event.target.value],
                            required: false
                          });
                        } else result.push(option);
                      });

                      updateForm({
                        key: "options2",
                        value: result
                      });
                    }}
                  />
                </Grid>
              );
            } else if (item.type == "FILES") {
              return (
                <Grid className={styles.formInput} item>
                  <Typography className={styles.inputTitle}>
                    {item.name}
                  </Typography>
                  <Button
                    component={motion.button}
                    whileHover={{
                      backgroundColor: Colors.blue,
                      transition: { duration: 0.3 },
                      color: Colors.white
                    }}
                    className={styles.addFiles}
                    style={{
                      backgroundColor: "#000",
                      color: "white",
                      fontFamily: "Spartan",
                      textTransform: "none",
                      fontWeight: "bold",
                      marginTop: "10px"
                    }}
                    onClick={() => {
                      openFileSelector();
                    }}
                  >
                    {uploading ? (
                      <CircularProgress
                        style={{
                          alignSelf: "center",
                          color: Colors.white,
                          width: "30px"
                        }}
                      />
                    ) : (
                      "Add Files"
                    )}
                  </Button>
                  {files?.map((item, i) => {
                    return (
                      <Grid
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        key={JSON.stringify(item)}
                      >
                        <a
                          target="_blank"
                          className={styles.fileName}
                          href={item?.url}
                        >
                          {item?.name}
                        </a>
                        <Button
                          onClick={() => {
                            let result2 = [];
                            setFiles(
                              files.filter((image) => item.id !== image.id)
                            );
                            form.options2.map((option) => {
                              if (
                                String(option.name).includes("Upload") ||
                                String(option.name).includes("upload")
                              ) {
                                result2.push({
                                  ...option,
                                  required: false,
                                  files: option.files.filter(
                                    (image) => item.id !== image.id
                                  )
                                });
                              } else result2.push(option);
                            });

                            updateForm({
                              key: "options2",
                              value: result2
                            });
                          }}
                        >
                          <Close />
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            }
          })}

          {role == "admin" ? (
            <Button
              component={motion.button}
              whileHover={{
                backgroundColor: Colors.green,
                transition: { duration: 0.3 },
                color: Colors.white
              }}
              className={styles.addFiles}
              style={{
                backgroundColor: Colors.blue,
                color: "white",
                fontFamily: "Spartan",
                textTransform: "none",
                fontWeight: "bold",
                marginTop: "30px",
                width: "95%",
                height: "50px",

                marginLeft: "15px"
              }}
              onClick={() => {
                placeOrderAction();
              }}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    alignSelf: "center",
                    color: Colors.white,
                    width: "30px"
                  }}
                />
              ) : (
                "Place Order"
              )}
            </Button>
          ) : (
            <div style={{ marginTop: "30px" }}>
              {errorList?.length > 0 || uploading ? (
                <PayPalScriptProvider
                  options={{
                    clientId:
                      "AbWGT636RcnDtFc_Hj7scmzUQimPVUew0YW3EWDkvj0p-hfZmkFhDYVr-xrfyRb2SQTfFYwTZkvfDjCh",
                    intent: "capture"
                    //"AZLBKmrZMp_FA2OBRxK9igfMsuwSwS_nH-UrBj44fpRQ_Oq4m2ouaPT3VdioO1XXElXrUVIa0jJ7KIRg"
                  }}
                >
                  <PayPalButtons
                    fundingSource="paypal"
                    style={{ layout: "vertical", label: "checkout" }}
                    total={5}
                    currency="USD"
                    env="production"
                    createOrder={paypalCreateOrder}
                    onApprove={() => {
                      setIsPaymentApproved(true);
                    }}
                    disabled={errorList?.length > 0 || uploading}
                  />
                </PayPalScriptProvider>
              ) : (
                <PayPalButton
                  disabled={true}
                  amount={
                    coupon
                      ? Math.round(
                          (coupon.discountType == "ABS"
                            ? formData.price +
                              form.extraPrice -
                              parseInt(coupon.discount)
                            : formData.price +
                              form.extraPrice -
                              ((formData.price + form.extraPrice) *
                                parseInt(coupon.discount)) /
                                100) * 100
                        ) / 100
                      : parseFloat(formData.price + form.extraPrice).toFixed(2)
                  }
                  shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                  onSuccess={(details, data) => {
                    placeOrderAction();
                    // OPTIONAL: Call your server to save the transaction
                    return fetch("/paypal-transaction-complete", {
                      method: "post",
                      body: JSON.stringify({
                        orderId: data.orderID
                      })
                    });
                  }}
                  options={{
                    clientId:
                      "AbWGT636RcnDtFc_Hj7scmzUQimPVUew0YW3EWDkvj0p-hfZmkFhDYVr-xrfyRb2SQTfFYwTZkvfDjCh"
                  }}
                  currency="USD"
                />
              )}
            </div>
          )}
          <ToastContainer />
        </Grid>
      </Grid>

      <Payment
        SavePayment={() => {}}
        handleClose={() => setOpenDialog(false)}
        open={openDialog}
        openFileSelector={openFileSelector}
      />
    </div>
  );
};

const mapStateToProps = ({ orders, coupon }) => {
  return {
    errorCoupon: coupon.error,
    loadingCoupon: coupon.loading,
    coupon: coupon.coupon,
    loading: orders.loadingCreateOrder,
    error: orders.errorCreateOrder,
    created: orders.orderCreated
  };
};

export default connect(mapStateToProps, {
  getCoupon,
  createOrders
})(ProductForm);
