import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { connect } from "react-redux";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import AsyncStorage from "@callstack/async-storage";

import { getNotifications } from "../../Redux/actions";
import NotificationItem from "../../Components/NotificationItem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Notifications = ({
  handleClose,
  getNotifications,
  loading,
  selectNotification,
}) => {
  const [open, setOpen] = useState(true);
  const [notificationsList, setNotificationsList] = useState([]);

  useEffect(async () => {
    const id = await AsyncStorage.getItem("id");
    getNotifications(id, (data) => {
      setNotificationsList(data);
    });
  }, []);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Notifications
          </Typography>
          {/*<Button autoFocus color="inherit" onClick={handleClose}>
            Clear All Notifications
  </Button>*/}
        </Toolbar>
      </AppBar>

      <List>
        {notificationsList?.map((item, index) => {
          return (
            <NotificationItem
              key={index + " "}
              orderId={item?.orderId}
              title={item?.title}
              date={item?.date}
              status={item?.status}
              message={item?.message}
              file={item?.file}
              senderInfo={{
                name: item?.senderName,
                role: item?.senderRole,
                email: item?.senderEmail,
              }}
              onClick={() => selectNotification(item)}
            />
          );
        })}
      </List>
    </Dialog>
  );
};
const mapStateToProps = ({ notifications }) => {
  return {
    loading: notifications.loadingNotifications,
    notifications: notifications.notifications,
  };
};

export default connect(mapStateToProps, {
  getNotifications,
})(Notifications);
