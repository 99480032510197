import React, { useState, useEffect } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { motion, AnimatePresence } from "framer-motion";
import ListItem from "@material-ui/core/ListItem";
import { Link, Router } from "react-router-dom";

import Style from "./Style.css";

const Header = () => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      direction="column"
      container
      justify="center"
      alignItems="center"
      className={styles.container}
    >
      <Grid item>
        <Typography class={"error404"}>404</Typography>
      </Grid>
      <Grid item>
        <Typography class={matches ? "errormessage2" : "errormessage"}>
          One stepover too far?
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={styles.description}>
          Sorry, but the page you're looking for doesn't seem to exist.
        </Typography>
      </Grid>
      <Grid item>
        <ListItem component={Link} to="/">
          <Button
            component={motion.button}
            whileHover={{ scale: 1.1,backgroundColor: Colors.green, }}
            whileTap={{ scale: 0.9 }}
            variant="contained"
            className={styles.GoHome}
          >
            Home
          </Button>
        </ListItem>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: Colors.primaryColor,
    display: "flex",
    overflow: "hidden",
    position: "relative",
    width:"100vw",
    textTransform: "none",

    minHeight: "100vh",
  },
  img: {
    borderRadius: "20px",
    height: "300px",
    margin: "25px",
    [theme.breakpoints.down("md")]: {
      width: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },

  Services: {
    color: Colors.white,
    fontFamily: "Spartan",
    fontSize: "50px",
    fontWeight: "bolder",
    marginTop: "150px",
    textAlign: "center",
  },
  description: {
    color: Colors.grey2,
    fontFamily: "Spartan",
    fontSize: "15px",
    textAlign: "center",
    marginTop: "-10px",
  },

  ServicesDiv: {
    overflow: "auto",
    marginLeft: "100px",
  },
  sign: {
    color: Colors.grey4,
    fontFamily: "Spartan",
    fontSize: "100px",
    fontWeight: "bolder",
    marginTop: "30px",
    textAlign: "center",
  },
  sign2: {
    color: Colors.grey4,
    fontFamily: "Spartan",
    fontSize: "100px",
    fontWeight: "bolder",
    marginTop: "20px",
    textAlign: "center",
  },
  inspirationText: {
    color: Colors.white,
    fontFamily: "Spartan",
    fontSize: "25px",
    fontWeight: "bolder",
    textAlign: "center",
    marginLeft: "200px",
    marginRight: "200px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "50px",
      marginRight: "50px",
      fontSize: "17px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  GoHome: {
    background: Colors.blue,
    textTransform: "none",
    borderRadius: 10,
    paddingVertical: 20,
    fontFamily: "Spartan",
    height: 50,
    width: "140px",
    color: Colors.white,
    fontWeight: "bold",
    borderColor: Colors.none,
    alignSelf: "center",
    marginTop: "20px",
    textAlign: "center",
    flexDirection: "row",
  },
  learn: {
    background: Colors.secondaryColor,
    textTransform: "none",
    color: Colors.white,
    height: 60,
    width: 150,
    borderRadius: 10,
    fontFamily: "Spartan",
    fontWeight: "bold",
    marginTop: 35,
    marginBottom: 35,
    borderColor: Colors.none,
  },
}));

export default Header;
