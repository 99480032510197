import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Payment from "@mui/icons-material/Payment";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import Delete from "@mui/icons-material/DeleteOutline";
import Share from "@mui/icons-material/Share";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";
import Popover from "@mui/material/Popover";

const NoteItem = ({
  order,
  onClick,
  status,
  date,
  senderInfo,
  role,
  file,
  message,
  reference,
  description,
  note,
  periority,
  orderId,
  deleteNote,
  shareNote,
  shares,
  sharedToMe,
  userName,
  sharedNames,
  noteShare
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [sharesNames, setSharesNames] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid
      component={motion.div}
      whileHover={{
        backgroundColor: Colors.dark + "10",
        transition: { duration: 0.7 },
        scale: 1.01,
        borderColor: Colors.blue + "88",
        borderWidth: "1px",
      }}
      className={styles.container}
      container
      direction="row"
      wrap="nowrap"
    >
      {!sharedToMe &&<div
        style={{
          width: "20px",
          position: "absolute",
          right: "20px",
          top: "5px",
          fontWeight: "bold",
          marginLeft: "10px",
          marginTop: "10px",
        }}
        onClick={handleClick}
      >
        <MenuOutlined />
      </div>}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid container direction="column">
          <Grid item>
            <Button
              onClick={() => {
                handleClose();
                deleteNote();
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  fontFamily: "Spartan",
                  color: Colors.black,
                  padding: "5px",
                }}
              >
                <Delete style={{ fontSize: "15px" }} /> Delete
              </Typography>
            </Button>
          </Grid>
          {noteShare&&<Grid item>
            <Button
              onClick={() => {
                handleClose();
                shareNote();
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  fontFamily: "Spartan",
                  color: Colors.black,
                  padding: "5px",
                }}
              >
                <Share style={{ fontSize: "15px" }} /> Share
              </Typography>
            </Button>
          </Grid>}
        </Grid>
      </Popover>

      <Grid className={styles.container2}>
        {sharedToMe ? (
          <Grid item>
            <Typography
              style={{
                color: Colors.red,
                fontSize: "13px",
              }}
              className={styles.sharesNames}
            >
              {"Shared by   " + userName}
            </Typography>
          </Grid>
        ) : sharedNames ? (
          <Grid item>
            <Typography
              style={{
                color: Colors.green,
                fontSize: "13px",
              }}
              className={styles.sharesNames}
            >
              {"Shared With   " + sharedNames}
            </Typography>
          </Grid>
        ) : null}

        <Grid
          container
          style={{
            width: "100%",
          }}
          direction="row"
        >
          <Grid item>
            <Typography
              style={{
                color: Colors.grey,
                fontSize: "11px",
              }}
              className={styles.title}
            >
              Reference :
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={styles.title}>{reference}</Typography>
          </Grid>

          <Typography
            className={styles.title}
            style={{
              color: periority.backgroundColor,
              position: "absolute",
              right: "20px",
              bottom: "5px",
            }}
          >
            {periority.name}
          </Typography>
        </Grid>

        <Grid style={{width:"700px"}} container direction="row">
          <Grid item>
            <Typography
              style={{
                color: Colors.grey,
                fontSize: "11px",
              }}
              className={styles.title}
            >
              Description :{" "}
            </Typography>
          </Grid>

          <Typography className={styles.description
          }>{description}</Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item>
            <Typography
              style={{
                color: Colors.grey,
                fontSize: "11px",
              }}
              className={styles.title}
            >
              Note :{" "}
            </Typography>
          </Grid>

          <Typography className={styles.title}>{note}</Typography>
        </Grid>

        {file && (
          <a download className={styles.link} target="_blank" href={file.url}>
            {file.name}
          </a>
        )}

       {sharedToMe? <Typography className={styles.date}> {new Date(new Date(date).toLocaleString('en-US', {
            timeZone: "America/New_York",
          }) ).toString().substr(0,24)} </Typography>:null}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: Colors.white,
    position: "relative",
    textTransform: "none",
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px 2px #c7c7c755",
    margin: "10px",
    border: "1px solid #30374500",
    width: window.innerWidth - 280,
  },
  container2: {
    padding: "10px",
    marginLeft: "5px",
    width: "100%",
  },
  markAsComplete: {
    background: Colors.green,
    color: Colors.white,
    marginTop: "15px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  needsWork: {
    background: Colors.red,
    color: Colors.white,
    marginTop: "15px",
    marginRight: "5px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  inputContent: {
    width: "30%",
    height: "100%",
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "0vh",
    },
  },
  name: {
    fontSize: "11px",
    fontFamily: "Spartan",
    color: Colors.black,
    width: "220px",
    fontWeight: "bold",
  },
  value: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.black,
    marginLeft: "5px",
    width: "600px",
  },
  date: {
    fontSize: "9px",
    fontFamily: "Spartan",
    color: Colors.darkGrey,
    marginLeft: "10px",
    marginTop: "4px",
  },
  orderId: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  
  sharesNames: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "11px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  title: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "13px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  description: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "13px",
    marginLeft: "10px",
    width: "700px",
    flexWrap: 'wrap',
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  message: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "13px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  link: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginLeft: "10px",
  },
  Paypal: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  email: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "12px",
    marginTop: "5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    height: "35px",
    borderRadius: "20px",
    color: Colors.blue,
    textTransform: "none",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  price: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "20px",
    bottom: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  orderStatus: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  id: {
    color: Colors.darkGrey,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px",
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan",
  },
}));

export default NoteItem;
