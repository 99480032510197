export const development = "https://app.ecwid.com/api/v3/17283181";
export const production = "https://app.ecwid.com/api/v3/17283181";

export const hostURL = development;

const Products = `${hostURL}/products`;
const token="secret_U9S136kdDrgmHkrLxJi4qxMesAqJxr2c"

const GetWriters=`${hostURL}/storage/private?token=secret_ELaYkEkSsHVSWwNne7PJT9BTk24fGcdT`
const GetCoupon = (couponId) => {
  return `${hostURL}/discount_coupons/${couponId}`;
};

const GetOrders = (email, fulfillmentStatus,productId,keywords) => { 
  return `${hostURL}/orders?email=${email}&keywords=${keywords}&fulfillmentStatus=${fulfillmentStatus}${productId==""?"":("&productId="+productId)}`;
};

const GetOrdersCoupon = (email, fulfillmentStatus) => { 
  return `${hostURL}/orders?email=${email}&fulfillmentStatus=${fulfillmentStatus}`;
};
const Orders = () => { 
  return `${hostURL}/orders`;
};
const updateOrder = (id) => { 
  return `${hostURL}/orders/${id}`;
};
const updateSubscriptions = (id) => { 
  return `${hostURL}/subscriptions/${id}`;
};
const CreateOrder = () => {
  return `${hostURL}/orders?token=${token}`;
};
const GetCustomer= (email) => {
  return `${hostURL}/customers?email=${email}`;
};
const GetSubScription= () => {
  return `${hostURL}/subscriptions`;
};

const Customers= () => {
  return `${hostURL}/customers`;
};

const PaypalPayment = (price, item, quantity, name, description) => {
  return `https://us-central1-paper-writer-f57a6.cloudfunctions.net/app/paypal/paypal?price=${price}&item=${item}&quantity=${quantity}&name=${name}&description=${description}`;
};

const GetHomePage = `https://storefront.ecwid.com/home-page/17283181/static-code`;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "Bearer "+token,
};

export const ServiceURL = {
  headers,
  Products,
  GetHomePage,
  GetOrders,
  PaypalPayment,
  GetCustomer,
  Customers,
  CreateOrder,
  token,
  GetCoupon,
  GetWriters,
  Orders,
  GetOrdersCoupon,
  GetSubScription,
  updateOrder,
  updateSubscriptions
};
