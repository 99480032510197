import {
  GET_ORDERS_ATTEMPING,
  GET_ORDERS_FAILED,
  GET_ORDERS_SUCCESS,
  CREATE_ORDER_ATTEMPING,
  CREATE_ORDER_FAILED,
  CREATE_ORDER_SUCCESS,
  UPLOAD_FILE_ATTEMPING,
  GET_ORDERS_STATS_ATTEMPING,
  GET_ORDERS_STATS_FAILED,
  GET_ORDERS_STATS_SUCCESS,
} from "./Types";
import axios from "axios";
import { ServiceURL } from "../../global/services/config";
import AsyncStorage from "@callstack/async-storage";
import firebase from "../../global/services/Firebase";

export const updateOrder = (
  id,
  items,
  total,
  callBack,
  callBackError
) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_ORDER_ATTEMPING,
    });

    console.log("selectedOrder",items);

    try {
     

      var config = {
        method: "put",
        url: ServiceURL.updateOrder(id),
        headers: ServiceURL.headers,
        data: {
         // total: parseFloat(total),
          items: items,
        },
      };
      const { data } = await axios(config);
      callBack(data);
      dispatch({ type: CREATE_ORDER_SUCCESS, payload: true });
    } catch (error) {
      console.log("error.response.data",error.response.data,items);
      callBackError(error.response.data.errorMessage);
      dispatch({
        type: CREATE_ORDER_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const createOrders = (
  email,
  product,
  items,
  price,
  coupon,
  callBack,
  callBackError
) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_ORDER_ATTEMPING,
    });

    const email = await AsyncStorage.getItem("email");

    try {
      let orderExtraFields = [];
      items?.map((option) => {
        if (
          String(option.name).includes("Upload") ||
          String(option.name).includes("upload")
        ) {
          if (option?.files) {
            option?.files?.map((file) => {
              orderExtraFields.push({
                id: file.id + "",
                value: file.url,
                customerInputType: "TEXT",
                title: file.name,
                orderDetailsDisplaySection: "order_comments",
                orderBy: "1",
              });
            });
          }
        }
      });

      var config = {
        method: "post",
        url: ServiceURL.CreateOrder(),
        headers: ServiceURL.headers,
        data: {
          refundedAmount: 0,
          subtotal: parseFloat(price),
          subtotalWithoutTax: parseFloat(price),
          total: parseFloat(price),
          totalWithoutTax: parseFloat(price),
          giftCardRedemption: 0,
          totalBeforeGiftCardRedemption: parseFloat(price),
          email: email,
          paymentModule: "PayPalStandard",
          paymentMethod: "PayPal",
          tax: 0,
          couponDiscount: coupon?.discount || 0,
          paymentStatus: "PAID",
          fulfillmentStatus: "AWAITING_PROCESSING",
          orderComments: "DASHBOARD",
          customDiscount: [],
          customSurcharges: [],
          discount: coupon?.discount || 0,
          usdTotal: parseFloat(price),
          items: [
            {
              productId: product.id,
              categoryId: 0,
              price: parseFloat(price),
              priceWithoutTax: parseFloat(price),
              productPrice: product.price,
              sku: product.sku,
              quantity: 1,
              shortDescription: product.description,
              shortDescriptionTranslated: product.descriptionTranslated,
              tax: 0,
              shipping: 0,
              quantityInStock: 0,
              name: product.name,
              nameTranslated: product.nameTranslated,
              isShippingRequired: false,
              trackQuantity: false,
              fixedShippingRateOnly: false,
              fixedShippingRate: 0,
              digital: false,
              productAvailable: true,
              couponApplied: coupon ? true : false,
              selectedOptions: items,
              dimensions: {
                length: 0,
                width: 0,
                height: 0,
              },
              discounts: [],
              discountsAllowed: true,
              taxable: true,
              giftCard: false,
              isCustomerSetPrice: false,
            },
          ],
          orderExtraFields: orderExtraFields || [],
          refunds: [],
          predictedPackage: [],
          shipments: [],

          paymentParams: {},
          hidden: false,
          taxesOnShipping: [],
          acceptMarketing: false,
          disableAllCustomerNotifications: false,
          externalFulfillment: false,
          invoices: [],
          pricesIncludeTax: false,
        },
      };
      const { data } = await axios(config);
      callBack(data);
      dispatch({ type: CREATE_ORDER_SUCCESS, payload: true });
    } catch (error) {
      console.log("error",error);
      callBackError(error.response?.data?.errorMessage);
      dispatch({
        type: CREATE_ORDER_FAILED,
        payload: error?.response?.data?.error,
      });
    }
  };
};
export const homeStudentStats = (email, fulfillmentStatus, productId, keywords) => {
  if(email){

  
  return async (dispatch) => {
    dispatch({
      type: GET_ORDERS_STATS_ATTEMPING,
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetOrders(
          email,
          fulfillmentStatus,
          productId,
          keywords
        ),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      console.log("datadatadatadatassssss",data,config);
      let orderStats = {
        totalOrdersCount: data.total,
        totalPaid: 0.0,
        outForDeliveryOrdersCount: 0,
        deliveredOrdersCount: 0,
        readyForPickupOrdersCount: 0,
        returnedOrdersCount: 0,
        willNotDeliverOrdersCount: 0,
        awaitingProcessingOrdersCount: 0,
        processingOrdersCount: 0,
        shippedOrdersCount: 0,
      };
      data.items.filter((order) => {
        if (order.fulfillmentStatus == "AWAITING_PROCESSING") {
          orderStats.awaitingProcessingOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "PROCESSING") {
          orderStats.processingOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "SHIPPED") {
          orderStats.shippedOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "DELIVERED") {
            orderStats.deliveredOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "WILL_NOT_DELIVER") {
          orderStats.willNotDeliverOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "RETURNED") {
          orderStats.returnedOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "READY_FOR_PICKUP") {
          orderStats.readyForPickupOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "OUT_FOR_DELIVERY") {
          orderStats.outForDeliveryOrdersCount += 1;
        }

        let total = 0.0;
        order?.items?.map((item) => {
          total += parseFloat(item?.price);
          item?.selectedOptions?.map((option) => {
            if (option.type == "CHOICE") {
              option?.selections?.map((selection) => {
                total += selection?.selectionModifier;
              });
            }
          });
        });
        orderStats.totalPaid += total;
      });
      dispatch({ type: GET_ORDERS_STATS_SUCCESS, payload: orderStats });
    } catch (error) {
      dispatch({
        type: GET_ORDERS_STATS_FAILED,
        // payload: error.response.data.error,
      });
    }
  };
}
};

export const homeStats = (email, fulfillmentStatus, productId, keywords) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ORDERS_STATS_ATTEMPING,
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetOrders(
          email,
          fulfillmentStatus,
          productId,
          keywords
        ),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      console.log("datadatadatadatassssss",data,config);
      let orderStats = {
        totalOrdersCount: data.total,
        totalPaid: 0.0,
        outForDeliveryOrdersCount: 0,
        deliveredOrdersCount: 0,
        readyForPickupOrdersCount: 0,
        returnedOrdersCount: 0,
        willNotDeliverOrdersCount: 0,
        awaitingProcessingOrdersCount: 0,
        processingOrdersCount: 0,
        shippedOrdersCount: 0,
      };
      data.items.filter((order) => {
        if (order.fulfillmentStatus == "AWAITING_PROCESSING") {
          orderStats.awaitingProcessingOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "PROCESSING") {
          orderStats.processingOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "SHIPPED") {
          orderStats.shippedOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "DELIVERED") {
            orderStats.deliveredOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "WILL_NOT_DELIVER") {
          orderStats.willNotDeliverOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "RETURNED") {
          orderStats.returnedOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "READY_FOR_PICKUP") {
          orderStats.readyForPickupOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "OUT_FOR_DELIVERY") {
          orderStats.outForDeliveryOrdersCount += 1;
        }

        let total = 0.0;
        order?.items?.map((item) => {
          total += parseFloat(item?.price);
          item?.selectedOptions?.map((option) => {
            if (option.type == "CHOICE") {
              option?.selections?.map((selection) => {
                total += selection?.selectionModifier;
              });
            }
          });
        });
        orderStats.totalPaid += total;
      });
      dispatch({ type: GET_ORDERS_STATS_SUCCESS, payload: orderStats });
    } catch (error) {
      dispatch({
        type: GET_ORDERS_STATS_FAILED,
        // payload: error.response.data.error,
      });
    }
  };
};

export const getOrders = (email, fulfillmentStatus, productId, keywords) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ORDERS_ATTEMPING,
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetOrders(
          email,
          fulfillmentStatus,
          productId,
          keywords
        ),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      let orderStats = {
        totalOrdersCount: data.total,
        totalPaid: 0.0,
        outForDeliveryOrdersCount: 0,
        deliveredOrdersCount: 0,
        readyForPickupOrdersCount: 0,
        returnedOrdersCount: 0,
        willNotDeliverOrdersCount: 0,
        awaitingProcessingOrdersCount: 0,
        processingOrdersCount: 0,
        shippedOrdersCount: 0,
      };
      data.items.filter((order) => {
        let total = 0.0;
        order?.items?.map((item) => {
          total += parseFloat(item?.price);
          item?.selectedOptions?.map((option) => {
            if (option.type == "CHOICE") {
              option?.selections?.map((selection) => {
                total += selection?.selectionModifier;
              });
            }
          });
        });

        if (parseInt(order.total)==0) {
          order.total=total
        }
        

        if (order.fulfillmentStatus == "AWAITING_PROCESSING") {
          orderStats.awaitingProcessingOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "PROCESSING") {
          orderStats.processingOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "SHIPPED") {
          orderStats.shippedOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "DELIVERED") {
          if (order.paymentStatus == "PAID") {
            orderStats.deliveredOrdersCount += 1;
            orderStats.totalPaid += order.total;
          }
        }
        if (order.fulfillmentStatus == "WILL_NOT_DELIVER") {
          orderStats.willNotDeliverOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "RETURNED") {
          orderStats.returnedOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "READY_FOR_PICKUP") {
          orderStats.readyForPickupOrdersCount += 1;
        }
        if (order.fulfillmentStatus == "OUT_FOR_DELIVERY") {
          orderStats.outForDeliveryOrdersCount += 1;
        }
      });
      dispatch({
        type: GET_ORDERS_SUCCESS,
        payload: data,
        payload2: orderStats,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDERS_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};
