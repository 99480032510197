import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  getAdminLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem,
  createNote,
  getNotes,
  deleteNote,
  getAllUsers,
  shareNote,
  signup,
  updateUserStatus
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import AsyncStorage from "@callstack/async-storage";
import firebase from "../../../../global/services/Firebase";
import CreateUser from "./CreateUser";
import ShareTo from "./ShareTo";
import UserManagementItem from "../../../../Components/UserManagementItem";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const UsersManagment = ({
  role,
  loadingSaveNotes,
  getNotes,
  deleteNote,
  loadingSignup,
  loadingGetUsers,
  users,
  getAllUsers,
  shareNote,
  signup,
  updateUserStatus
}) => {
  const initailFormState = {
    name: null,
    email: null,
    password: null,
    confirmPassword: null,
    role: null,

    description: null,
    reference: 0,
    date: new Date().toUTCString(),
    note: "",
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);
  var curr = new Date(); // get current date
  var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
  var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [openPament, setOpenPament] = React.useState(false);
  const [formErrorsPayment, setErrorsPayment] = useState([]);
  const [dateFrom, setDateFrom] = useState(firstday);
  const [dateTo, setDateTo] = useState(lastday);
  const [adminLedgerList, setAdminLedgerList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [userList, setUserList] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [formErrors, setErrors] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePayment = () => {
    setOpenPament(false);
  };

  useEffect(async () => {
    const id = await AsyncStorage.getItem("id");
    const role = await AsyncStorage.getItem("role");
   // getNotes(id, dateFrom, dateTo, () => {});
    getAllUsers((users) => {
      setUserList(users);
    });
  }, []);


  const saveUser = async () => {
    let errors = [];

    if (!form.name) {
      errors.push("*name is required.");
    }
    if (!form.email) {
      errors.push("*email is required.");
    }
    if (!form.password) {
      errors.push("*password is required.");
    }
    if (form.password != form.confirmPassword) {
      errors.push("*password does not match.");
    }
    if (!form.role) {
      errors.push("*role is required.");
    }

    setErrorsPayment(errors);

    if (errors.length == 0) {
      signup(form?.name, form?.email, form?.password, form?.role, (user) => {
        handleClosePayment();
      },(error)=>{
        alert(error)
      });
    }
  };

  const shareNoteAction = () => {
    if (selectedNote) {
      userList?.map((user, index) => {
        if (user.checked == true) {
          shareNote(
            selectedNote.id,
            new Date().toUTCString(),
            user.id,
            user.name,
            user.email,
            (data) => {
              handleClose();
            }
          );
        }
      });
    }
  };
  return (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid container direction="row">
        <ShareTo
          // loadingAddWriterPayment={load}
          note={selectedNote}
          SavePayment={() => shareNoteAction()}
          handleClose={() => handleClose()}
          formErrors={formErrors}
          open={open}
          users={userList}
          getUser={(user, index) => {
            let users = userList;
            users[index] = {
              ...users[index],
              checked: !users[index].checked,
            };
            setUserList(users);

            handleClose();
            setTimeout(() => {
              setOpen(true);
            }, 1);
          }}
        />

        <CreateUser
          loadingAddWriterPayment={loadingSignup}
          saveUser={() => saveUser()}
          handleClose={() => handleClosePayment()}
          formErrors={formErrorsPayment}
          open={openPament}
          onClickClose={() => setFile(null)}
          file={file}
          onChangeDate={(value) => {
            updateForm({
              key: "date",
              value: value.toUTCString(),
            });
          }}
          onChangeName={(event) => {
            updateForm({ key: "name", value: event.target.value });
          }}
          onChangeEmail={(event) => {
            updateForm({ key: "email", value: event.target.value });
          }}
          onChangePassword={(event) => {
            updateForm({ key: "password", value: event.target.value });
          }}
          onChangeConfirmPassword={(event) => {
            updateForm({ key: "confirmPassword", value: event.target.value });
          }}
          chooseRole={(item) => {
            updateForm({ key: "role", value: item });
          }}
        />
      </Grid>
     {role=='admin'&& <Grid direction="row" container>
        <Grid item>
          <Button
            onClick={() => {
              setOpenPament(true);
            }}
            className={styles.createPaymentButton}
            style={{
              backgroundColor: Colors.blue,
              marginLeft: "20px",
              marginTop: "20px",
            }}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.green,
              transition: { duration: 0.3 },
            }}
          >
            <Typography className={styles.messageText}>Add new user</Typography>
          </Button>
        </Grid>
      </Grid>}
      <Grid container direction="column">
        {users?.map((user, index) => {
          return (
            <UserManagementItem
              key={index + ""}
              checked={user.checked}
              id={user.id}
              name={user.name}
              email={user.email}
              role={user.role}
              active={user.active}
              noteShare={user.noteShare}
              onClick={() => {}}
              loggedAt={user.loggedAt}
             handleStatusChange ={ (active,noteShare) => {
              updateUserStatus(user.id,active,noteShare,()=>{

              })
                
              }}
            />
          );
        })}
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ users, ledger, notes }) => {
  return {
    adminLedger: ledger.adminLedger,
    loadingSaveAdminLedger: ledger.loadingSaveAdminLedger,
    loadingAdminLedger: ledger.loadingAdminLedger,

    loadingGetNotes: notes.loadingGetNotes,
    notes: notes.notes,
    loadingSaveNotes: notes.loadingSaveNotes,

    loadingGetUsers: users.loadingGetAllUsers,
    users: users.allUsers,
    errorGetUsers: users.errorGetAllUsers,
    loadingSignup:users.loadingSignup
  };
};

export default connect(mapStateToProps, {
  getAdminLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem,
  createNote,
  getNotes,
  deleteNote,
  getAllUsers,
  shareNote,
  signup,
  updateUserStatus
})(UsersManagment);
