import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../Utils/Colors";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#c3c3c322",
    display: "flex",
    width: "100%",
    height: "100%",

    minHeight: "100vh",
    minWidth: window.innerWidth - 210,
    padding: "30px",
    flexDirection: "column",
  },

  container2: {
    background: Colors.white,
    textTransform: "none",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px 2px #c7c7c755",
    margin: "10px",
    width: "250px",
    height: "150px",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #30374500",
  },

  leftContainer:{
    background: "#c3c3c3200",
    minHeight: "100vh",
    width: (window.innerWidth - 300)/2,
    padding: "30px",
    flexDirection: "column",
  },


  rightContainer:{
    background: "#c3c3c3200",
    minHeight: "100vh",
    width: (window.innerWidth - 300)/2,
    padding: "30px",
    flexDirection: "column",
    marginLeft:"30px"
  },
  image:{
    height: "280px",
    width: (window.innerWidth - 330)/2,
    borderRadius:"20px"
    },

  backButton: {
    justifyContent: "flex-start",
    width: "80px",
  },
 
  formInput: {
    fontFamily: "Spartan",
    marginLeft: "15px",
    
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "10px",
    top: "10px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {},
  },
  addFiles:{
    color: Colors.white,
    fontFamily: "Spartan",
    fontSize: "13px",
    marginTop: "20px",
    fontWeight: "bold",
    width:"150px",
    height:"45px",
    borderRadius:"10px",
    backgroundColor:Colors.black,
    textTransform: "none",

  },
  fileName:{

    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "13px",
    height: "50px",
    textDecoration:'underline'
  },
  more: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  inputTitle: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "15px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {},
  },
  idOrder: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "15px",
    marginLeft: "15px",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {},
  },
  price:{
    color: Colors.green,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "24px",
    marginLeft: "15px",
  },
  productDetails: {
    fontFamily: "Spartan",
    fontSize: "16px",
    marginTop: "25px",
    fontWeight: "bold",


  },
  desc: {
    fontFamily: "Spartan",
    fontSize: "14px",
    marginTop: "10px",
    

  },
}));

export default useStyles;
