import React, { useState, useEffect, useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import WriterOrderLedger from "../WriterOrderLedger/View";
import {
  getWriterStats,
  homeStats,
  getWriterPayment,
  updatePassword,
} from "../../../../Redux/actions";
import AsyncStorage from "@callstack/async-storage";
import CircularProgress from "@mui/material/CircularProgress";

import { motion } from "framer-motion";

import CreateWriterPassword from "../WriterDetails/CreateWriterPassword";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const WriterHome = ({
  homeStats,

  loadingOrdersStats,
  errorOrdersStats,
  orderStats,
  getWriterStats,
  stats,
  errorGetWritersStats,
  loadingGetWritersStats,
  getWriterPayment,
  role,
  writerPayments,
  loadingAddWriter,
  updatePassword,
  openOrderDetails,
  openOrderDetails2,
}) => {
  const initailFormState = {
    name: null,
    email: null,
    password: null,
    confirmPassword: null,
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);

  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [formErrors, setErrors] = useState([]);
  const [id, setId] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [totalPaid, setTotalPaid] = useState(0);
  const [earnedThisMonth, setEarnedThisMonth] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    var curr = new Date(); // get current date
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

    if (writerPayments && stats) {
      let total = 0.0;
      writerPayments?.map((payment) => {
        if (String(payment.item).toUpperCase().includes("PAYMENT")) {
          total += parseFloat(payment.price)
        }
      });
      setTotalPaid(total);
    }

    if (writerPayments && stats) {
      let earnedThisMonth = parseInt(stats?.weekTotalPaid);
      writerPayments?.map((payment) => {
        if (
          new Date(new Date(payment.date).toDateString()) >=
            new Date(new Date(firstday).toDateString()) &&
          new Date(new Date(payment.date).toDateString()) <=
            new Date(new Date(lastday).toDateString())
        ) {
       
          if (!String(payment.item).toUpperCase().includes("PAYMENT")) {
            earnedThisMonth += parseFloat(payment.price)
          }
        }
      });
      setEarnedThisMonth(earnedThisMonth);
    }
  }, [writerPayments, stats]);

  useEffect(async () => {
    const email = await AsyncStorage.getItem("email");
    const id = await AsyncStorage.getItem("id");
    const password = await AsyncStorage.getItem("password");
    setId(id);
    setPassword(password);
    getWriterStats(email);
    getWriterPayment(id);
  }, []);

  const saveWriter = () => {
    let errors = [];

    if (!form.password) {
      errors.push("*Password is required.");
    }
    if (!form.confirmPassword) {
      errors.push("*Confirm Password is required.");
    }
    if (form.password != form.confirmPassword) {
      errors.push("*Password does not match.");
    }
    setErrors(errors);

    if (errors.length == 0) {
      updatePassword(id, form?.password, (profile) => {
        setPassword(form?.password);
        handleClose();
      });
    }
  };

  const Item = (children, value, backgroundColor) => {
    return (
      <Grid
        component={motion.div}
        whileHover={{
          backgroundColor: Colors.dark + "10",
          transition: { duration: 0.7 },
          scale: 1.05,
          borderColor: Colors.blue + "88",
          borderWidth: "1px",
        }}
        className={styles.container2}
        container
        direction="column"
        style={{ backgroundColor: backgroundColor }}
      >
        {children}

        {loadingGetWritersStats ? (
          <CircularProgress
            size="20px"
            style={{ color: Colors.green, marginTop: "20px" }}
          />
        ) : (
          <Typography className={styles.number}>{value}</Typography>
        )}
      </Grid>
    );
  };

  return (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid container direction="row">
        {Item(
          <Typography className={styles.numberText}>Total Orders</Typography>,
          stats?.totalOrdersCount,
          Colors.white
        )}

        {Item(
          <Typography className={styles.numberText}>
            Delivered Orders
          </Typography>,
          stats?.deliveredOrdersCount,
          Colors.white
        )}

        {Item(
          <Typography className={styles.numberText}>
            Processing Orders
          </Typography>,
          stats?.processingOrdersCount,
          Colors.white
        )}

        {Item(
          <Typography className={styles.numberText}>
            Canceled Orders
          </Typography>,
          stats?.willNotDeliverOrdersCount,
          Colors.white
        )}

        {Item(
          <Typography className={styles.numberText}>Total Paid</Typography>,
          "$" + (totalPaid.toLocaleString() || "0"),

          Colors.white
        )}

        {Item(
          <Typography className={styles.numberText}>
            Earned This Week
          </Typography>,
          "$" + (earnedThisMonth || "0"),
          Colors.green + "33"
        )}

        <Grid
          component={motion.div}
          whileHover={{
            backgroundColor: Colors.dark + "10",
            transition: { duration: 0.7 },
            scale: 1.05,
            borderColor: Colors.blue + "88",
            borderWidth: "1px",
          }}
          className={styles.container2}
          container
          direction="column"
        >
          <Typography className={styles.numberText}>Password</Typography>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={styles.password}>
              {showPassword ? password : "*****************"}
            </Typography>
            <Button onClick={() => setShowPassword(!showPassword)}>
              <Typography className={styles.showPassword}>
                {showPassword ? "Hide" : "Show"}
              </Typography>
            </Button>
          </Grid>

          <Button
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.grey,
              transition: { duration: 0.3 },
              color: "#fff",
            }}
            className={styles.createNewPasswordButton}
            onClick={() => handleClickOpen()}
          >
            <Typography className={styles.createNewPassword}>
              Create new password
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <CreateWriterPassword
        loadingAddWriter={loadingAddWriter}
        saveWriter={() => saveWriter()}
        handleClose={() => handleClose()}
        formErrors={formErrors}
        open={open}
        onChangeConfirmPassword={(event) => {
          updateForm({
            key: "confirmPassword",
            value: event.target.value,
          });
        }}
        onChangePassword={(event) => {
          updateForm({ key: "password", value: event.target.value });
        }}
      />

      <Typography className={styles.idOrder}>Orders Ledger</Typography>

      <WriterOrderLedger
        openOrderDetails={(order) => {
          openOrderDetails2(order);
        }}
        role={role}
      />
    </div>
  );
};

const mapStateToProps = ({ writer, orders }) => {
  return {
    loadingOrdersStats: orders.loadingOrdersStats,
    errorOrdersStats: orders.errorOrdersStats,
    orderStats: orders.orderStats,
    stats: writer.stats,
    errorGetWritersStats: writer.errorGetWritersStats,
    loadingGetWritersStats: writer.loadingGetWritersStats,
    loadingGetWriterPayments: writer.loadingGetWriterPayments,
    writerPayments: writer.writerPayments,

    loadingAddWriter: writer.loadingAddWriter,
    errorAddWriter: writer.errorAddWriter,
    writer: writer.writer,
  };
};

export default connect(mapStateToProps, {
  homeStats,
  getWriterStats,
  getWriterPayment,
  updatePassword,
})(WriterHome);
