import React, { useState, useEffect, useRef,useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./Style.js";
import StudentItem from "../../../../Components/StudentItem";
import { connect } from "react-redux";
import {
  createWriter,
  getWriterStats,
  getStudents
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Search from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { useFilePicker } from "use-file-picker";
import StudentDetails from "../StudentDetails/View";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const Students = ({
  createWriter,
  loadingAddWriter,
  errorAddWriter,
  orders,
  loading,
  loadingGetStudents,
  students,
  getStudents,
  openOrderDetails2,
  role,
}) => {
  const initailFormState = {
    name: null,
    email: null,
    password: null,
    confirmPassword: null,
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);

  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [showWriters, setShowWriters] = useState(true);
  const [selectedWriter, setSelectedWriter] = useState(null);
  const [files, setFiles] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const timeout = useRef(null);

  const [openFileSelector, { filesContent, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    // limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
  });

  useEffect(() => {
    if (filesContent) {
      setFiles(filesContent);
    }
  }, [filesContent]);

 

  useEffect(() => {
    getStudents();
  }, []);


  useEffect(() => {
    if (students) {
      setStudentList(students)
    }
  }, [students,searchQuery]);

  useEffect(() => {
    if(searchQuery){
      let result=
      students?.filter((item,index)=>{
        if (String(item?.email).includes(searchQuery)) {
          return item
        }
      })
      setStudentList(result)
      
    }else{
      setStudentList(students)
    }
  }, [searchQuery,students]);

  

  return showWriters ? (
    <div className={styles.container} container>
      <Grid direction="row" container>
        <Grid className={styles.search} item>
          <TextField
            id="outlined-basic"
            label="Search"
            color="primary"
            variant="outlined"
            onChange={(event) => {

              clearTimeout(timeout.current);
              timeout.current = setTimeout(() => {          
                setSearchQuery(event.target.value ? event.target.value : "");
              }, 1000);

            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <Search color={Colors.blue} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {loadingGetStudents ? (
        <Box className={styles.progress}>
          <CircularProgress
            style={{ alignSelf: "center", color: Colors.blue }}
          />
        </Box>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ marginTop: "25px" }}
        >
          {studentList?.length > 0 ? (
            studentList?.map((student, index) => {
              return (
                <StudentItem
                  key={index + ""}
                  student={student}
                  detailsAction={() => {
                    setSelectedWriter(student);
                    setShowWriters(false);
                  }}
                />
              );
            })
          ) : (
            <Box className={styles.progress}>
              <Typography style={{ alignSelf: "center", textAlign: "center" }}>
                No students Yet
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </div>
  ) : (
    <StudentDetails
      role={role}
      selectedStudent={selectedWriter}
      onClick={() => setShowWriters(true)}
      openOrderDetails={(order)=>{
        openOrderDetails2(order)
      }}
    />
  );
};

const mapStateToProps = ({ students }) => {
  return {
   
    loadingGetStudents: students.loadingGetStudents,
    students: students.students,
  };
};

export default connect(mapStateToProps, {
  createWriter,
  getStudents,
  getWriterStats,
})(Students);
