import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  getAdminLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeft";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import AsyncStorage from "@callstack/async-storage";

import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CreateWriterPayment from "./CreateWriterPayment";
import WriterOrders from "../WriterOrders/View";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const AdminLedger = ({
  selectedWriter,

  loadingGetWritersStats,

  createWriterPayment,
  loadingAddWriterPayment,
  deleteWriterPayment,
  writerPayments,
  getWriterPayment,
  role,
  adminLedger,
  getAdminLedger,
  loadingSaveAdminLedger,
  createManaulLedgerItem,
  loadingAdminLedger,
  deleteAdminLedgerItem,
  adminLedgerTotal,
  openOrderDetails2,
  adminLedgerBalance
}) => {
  const initailFormState = {
    name: null,
    email: null,
    password: null,
    confirmPassword: null,

    type: null,
    price: 0,
    date: new Date().toUTCString(),
    name: ""
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);
  var curr = new Date(); // get current date
  var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
  var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [openPament, setOpenPament] = React.useState(false);
  const [formErrorsPayment, setErrorsPayment] = useState([]);
  const [profile, setProfile] = useState(null);
  const [payments, setPayments] = useState([]);
  const [showDetails, setShowDetails] = useState(true);
  const [dateFrom, setDateFrom] = useState(firstday);
  const [dateTo, setDateTo] = useState(lastday);
  const [totalPaid, setTotalPaid] = useState(0);
  const [adminLedgerList, setAdminLedgerList] = useState([]);
  const [query, setQuery] = useState("");
  let [sliceFrom, setSliceFrom] = useState(0);
  const [sliceTo, setSliceTo] = useState(7);
  const [page, setPage] = useState(1);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    apiData = apiData?.map((item) => {
      return {
        ...item,
        date: new Date(
          new Date(item?.date).toLocaleString("en-US", {
            timeZone: "America/New_York"
          })
        )
          .toLocaleDateString()
          .substr(0, 24)
      };
    });
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleClosePayment = () => {
    setOpenPament(false);
  };

  useEffect(() => {
    getAdminLedger(query, dateFrom, dateTo);
  }, []);

  useEffect(() => {
    getAdminLedger(query, dateFrom, dateTo);
  }, [dateFrom, dateTo, query]);

  useEffect(() => {
    if (adminLedger) {
      setAdminLedgerList(
        adminLedger
          ?.sort(
            (objA, objB) =>
              Number(new Date(objB.date)) - Number(new Date(objA.date))
          )
          ?.filter((value, index) => {
            const _value = JSON.stringify(value);
            return (
              index ===
              adminLedger?.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
              })
            );
          })
      );
      let totalPaid = 0;
      adminLedger?.map((item, index) => {
        totalPaid += parseInt(item.price);
      });
      setTotalPaid(totalPaid);
    }
  }, [adminLedger, page]);

  const Item = (children, value) => {
    return (
      <Grid
        component={motion.div}
        whileHover={{
          backgroundColor: Colors.dark + "10",
          transition: { duration: 0.7 },
          scale: 1.05,
          borderColor: Colors.blue + "88",
          borderWidth: "1px"
        }}
        className={styles.container2}
        container
        direction="column"
      >
        {children}

        {loadingAdminLedger ? (
          <CircularProgress
            size="20px"
            style={{ color: Colors.green, marginTop: "20px" }}
          />
        ) : (
          <Typography className={styles.number}>{value}</Typography>
        )}
      </Grid>
    );
  };

  const savePayment = async () => {
    const id = await AsyncStorage.getItem("id");
    const role = await AsyncStorage.getItem("role");
    const name = await AsyncStorage.getItem("name");
    const email = await AsyncStorage.getItem("email");

    let errors = [];
    if (!form.date) {
      errors.push("*Date is required.");
    }
    if (!form.name) {
      errors.push("*Name is required.");
    }
    if (!form.type) {
      errors.push("*Type is required.");
    }

    setErrorsPayment(errors);

    if (errors.length == 0) {
      await createManaulLedgerItem(
        id,
        name,
        role,
        email,
        form?.date,
        form?.name,
        form?.type,
        form?.price,
        (profile) => {
          handleClosePayment();
          setTimeout(() => {
            getAdminLedger(query, dateFrom, dateTo);
          }, 2000);

          //
        }
      );
    }
  };

  let listSize = parseInt(adminLedgerList?.length);
  return showDetails ? (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid container direction="row">
        {Item(
          <Typography className={styles.numberText}>Total Due</Typography>,
          "$" + parseFloat(adminLedgerTotal).toLocaleString()
        )}

        {Item(
          <Typography className={styles.numberText}>Ledger Balance</Typography>,
          "$" + parseFloat(adminLedgerBalance).toLocaleString()
        )}

        {/*Item(
          <Typography className={styles.numberText}>Count</Typography>,
          adminLedger?.length || 0
        )*/}

        <CreateWriterPayment
          loadingAddWriterPayment={loadingSaveAdminLedger}
          SavePayment={() => savePayment()}
          handleClose={() => handleClosePayment()}
          formErrors={formErrorsPayment}
          open={openPament}
          onChangeDate={(value) => {
            updateForm({
              key: "date",
              value: value.toUTCString()
            });
          }}
          onChangeName={(event) => {
            updateForm({ key: "name", value: event.target.value });
          }}
          onChangePrice={(event) => {
            updateForm({ key: "price", value: event.target.value });
          }}
          onChangeType={(event) => {
            updateForm({ key: "type", value: event.target.value });
          }}
        />
      </Grid>
      <Grid direction="row" container>
        <Grid item>
          <Button
            onClick={() => {
              setOpenPament(true);
            }}
            className={styles.createPaymentButton}
            style={{
              backgroundColor: Colors.blue,
              marginLeft: "20px",
              marginTop: "20px"
            }}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.green,
              transition: { duration: 0.3 }
            }}
          >
            <Typography className={styles.messageText}>Add new Item</Typography>
          </Button>
        </Grid>

        <Grid className={styles.formInput} item>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            color="primary"
            style={{
              width: "300px",
              fontFamily: "Spartan",
              marginTop: "20px"
            }}
            onChange={(event) => {
              setQuery(event.target.value ? event.target.value : "");
            }}
          />
        </Grid>

        <Grid className={styles.formInput} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="From"
              inputFormat="MM/dd/yyyy"
              onChange={(value) => {
                setDateFrom(value);
              }}
              value={dateFrom}
              renderInput={(params) => (
                <TextField
                  style={{ marginTop: "17px", width: "170px" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid className={styles.formInput} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="To"
              inputFormat="MM/dd/yyyy"
              onChange={(value) => {
                setDateTo(value);
              }}
              value={dateTo}
              renderInput={(params) => (
                <TextField
                  style={{ marginTop: "17px", width: "170px" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              exportToCSV(
                adminLedgerList,
                "admin-ledger  - " + new Date().toDateString()
              );
            }}
            className={styles.createPaymentButton}
            style={{
              backgroundColor: Colors.green,
              marginLeft: "20px",
              marginTop: "20px"
            }}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.lightGreen,
              transition: { duration: 0.3 }
            }}
          >
            <Typography className={styles.messageText}>
              Export xlsx file
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        sx={{
          width: window.innerWidth - 300,
          marginLeft: "20px",
          marginTop: "30px"
        }}
        component={Paper}
      >
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow style={{}}>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Num#
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Date
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Order Number/Reference
              </TableCell>

              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  fontWeight: "bold",
                  color: Colors.white
                }}
                align="left"
              >
                Type
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
                align="right"
              >
                Gross Payment
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
                align="right"
              >
                Writer Assigned
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
                align="right"
              >
                Writer Payout
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  fontWeight: "bold",
                  color: Colors.white
                }}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminLedgerList?.slice(sliceFrom, sliceTo)?.map((row, index) => (
              <TableRow
                style={{
                  backgroundColor: String(row?.name)
                    .toUpperCase()
                    .includes("PAYMENT")
                    ? Colors.green + "33"
                    : Colors.white
                }}
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{ fontFamily: "Spartan", fontSize: "12px" }}>
                  {listSize - sliceFrom++}
                </TableCell>

                <TableCell style={{ fontFamily: "Spartan", fontSize: "12px" }}>
                  {row?.date}
                </TableCell>

                <TableCell
                  style={{ fontFamily: "Spartan", fontSize: "12px" }}
                  className={styles.tableCell}
                  component="th"
                  scope="row"
                >
                  {String(row?.type).startsWith("Order(") ? (
                    <Button
                      onClick={() => {
                        openOrderDetails2(row?.order);
                      }}
                    >
                      {row?.order_reference}
                    </Button>
                  ) : (
                    row?.order_reference
                  )}
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px"
                  }}
                  align="left"
                >
                  {row?.type}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}
                >
                  ${row?.gross_payment}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}
                >
                  {row?.writer_assigned}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}
                >
                  {row?.writer_payout ? "$" + row?.writer_payout : " --- "}
                </TableCell>
                {row?.manual == true ? (
                  <TableCell
                    style={{ fontWeight: "bold", fontFamily: "Spartan" }}
                    align="right"
                  >
                    <Button
                      onClick={() => {
                        deleteAdminLedgerItem(row?.id, (data) => {
                          if (data) {
                            getAdminLedger(query, dateFrom, dateTo);
                          }
                        });
                      }}
                    >
                      <Delete />
                    </Button>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack>
        <Pagination
          onChange={(event, value) => {
            setPage(value);
            setAdminLedgerList([]);
            if (value == 1) {
              setSliceFrom(0);
              setSliceTo(7);
            } else {
              setSliceFrom((value - 1) * 7);
              setSliceTo(value * 7);
            }
          }}
          page={page}
          style={{ alignSelf: "center", marginTop: "20px" }}
          count={Math.ceil(adminLedger?.length / 7)}
          color="primary"
        />
      </Stack>
    </div>
  ) : (
    <WriterOrders
      role={role}
      selectedWriter={selectedWriter}
      onClickBack={() => setShowDetails(true)}
    />
  );
};

const mapStateToProps = ({ writer, ledger }) => {
  return {
    adminLedger: ledger.adminLedger,
    loadingSaveAdminLedger: ledger.loadingSaveAdminLedger,
    loadingAdminLedger: ledger.loadingAdminLedger,
    adminLedgerTotal: ledger.adminLedgerTotal,
    adminLedgerBalance: ledger.adminLedgerBalance
  };
};

export default connect(mapStateToProps, {
  getAdminLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem
})(AdminLedger);
