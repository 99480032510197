import {
  GET_STUDENT_SUBSCRIPTION_ATTEMPING,
  GET_STUDENT_SUBSCRIPTION_FAILED,
  GET_STUDENT_SUBSCRIPTION_SUCCESS,

  UPDATE_SUBSCRIPTION_ATTEMPING,
UPDATE_SUBSCRIPTION_FAILED ,
UPDATE_SUBSCRIPTION_SUCCESS ,
GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_ATTEMPING ,
 GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_FAILED ,
 GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_SUCCESS 
} from "./Types";
import axios from "axios";
import { ServiceURL } from "../../global/services/config";

export const updateSubscription = (
  id,
  status,
  callBack,
  callBackError
) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIPTION_ATTEMPING,
    });

    try {
     
      var config = {
        method: "put",
        url: ServiceURL.updateSubscriptions(id),
        headers: ServiceURL.headers,
        data: {
          status: status,
        },
      };
      const { data } = await axios(config);
      callBack(data);
      dispatch({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: true });
    } catch (error) {
      callBackError(error.response.data.errorMessage);
      dispatch({
        type: UPDATE_SUBSCRIPTION_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const getSubscription = (email) => {
  return async (dispatch) => {
    dispatch({
      type: GET_STUDENT_SUBSCRIPTION_ATTEMPING
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetSubScription(),
        headers: ServiceURL.headers
      };
      const { data } = await axios(config);
      console.log("subscriptionssubscriptionsxxx",data)
      const list = data?.items?.filter(
        (subscription) => subscription?.orderTemplate?.email == email
      );
      dispatch({
        type: GET_STUDENT_SUBSCRIPTION_SUCCESS,
        payload: list
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_SUBSCRIPTION_FAILED,
        payload: error?.response?.data?.error
      });
    }
  };
};

export const getSubscriptionForAdmin = (email,callBack) => {
  return async (dispatch) => {
    dispatch({
      type: GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_ATTEMPING
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetSubScription(),
        headers: ServiceURL.headers
      };
      const { data } = await axios(config);
      console.log("subscriptionssubscriptionsxxx",data)
      const list = data?.items?.filter(
        (subscription) => subscription?.orderTemplate?.email == email
      );
      callBack(list)
      dispatch({
        type: GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_SUCCESS,
        payload: list
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_SUBSCRIPTION_FOR_ADMIN_FAILED,
        payload: error?.response?.data?.error
      });
    }
  };
};
