import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Button, Checkbox } from "@material-ui/core";
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import login_back from "../../../assets/images/auth/student.png";
import logo from "../../../assets/global/logo.png";
import useStyles from "./Style.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "../../../Components/Input";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { connect } from "react-redux";
import { studentSignup } from "../../../Redux/actions";
import AsyncStorage from "@callstack/async-storage";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const StudentSignup = ({ studentSignup, loading }) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const initailFormState = {
    email: null,
    password: null,
    isRemember: false
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);
  const [error, setError] = useState(null);
  const handleChange = () => {
    updateForm({ key: "isRemember", value: !form.isRemember });
  };

  useEffect(async () => {
    const loggedIn = await AsyncStorage.getItem("loggedIn");

    if (loggedIn == true) {
      history.push("/dashboard/home");
    }
  }, []);

  const signupAction = () => {
    setError(null);
    if (!form.name) {
      setError("please enter your name");
    } else if (!form.email) {
      setError("please enter your email address");
    } else if (!form.password) {
      setError("please enter password");
    } else {
      studentSignup(
        form.name,
        form.email,
        form.password,
        (data) => {
          if (data) {
            history.push("/dashboard/home");
          }
        },
        (error) => {
          setError(error);
        }
      );
      //
    }
  };

  const loginAction = () => {
    history.push("/student/login");
  };

  return (
    <Grid
      direction={matches ? "column" : "row"}
      container
      justify="flex-start"
      alignItems="flex-start"
      className={styles.container}
    >
      <div className={styles.imgDev}>
        <img src={login_back} className={styles.img} />
        <Grid
          direction="row"
          container
          justify="center"
          alignItems="center"
          className={styles.logoView}
        >
          <Grid item>
            <img src={logo} className={styles.logo} />
          </Grid>
          <Grid>
            <Typography className={styles.appName}>A+ Paper Writer</Typography>
          </Grid>
        </Grid>
      </div>
      <Grid
        direction="column"
        container
        justify="flex-start"
        alignItems="flex-start"
        className={styles.inputContent}
      >
        <img src={logo} className={styles.logo2} />

        <Typography className={styles.welcomeText}>
          {"Welcome to A+ Paper Writer!"}
        </Typography>
        <Typography className={styles.loginText}>
          {"Create new account"}
        </Typography>
        <Input
          className={styles.inputEmail}
          label={"Full name"}
          onChange={(event) => {
            updateForm({ key: "name", value: event.target.value });
          }}
        />

        <Input
          className={styles.inputEmail}
          label={t("Email")}
          type="email"
          onChange={(event) => {
            updateForm({ key: "email", value: event.target.value });
          }}
        />

        <Input
          color={Colors.blue}
          className={styles.inputEmail}
          label={t("Password")}
          type={"password"}
          onChange={(event) => {
            updateForm({ key: "password", value: event.target.value });
          }}
        />

        {error && (
          <Alert className={styles.alert} severity="error">
            <Typography style={{ fontSize: "13px", fontFamily: "Spartan" }}>
              {error}
            </Typography>
          </Alert>
        )}

        <Button
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.green,
            transition: { duration: 0.3 }
          }}
          className={styles.SigninButton}
          onClick={signupAction}
        >
          {loading ? (
            <CircularProgress
              style={{ alignSelf: "center", color: Colors.white }}
            />
          ) : (
            <Typography className={styles.signinText}>Sign Up</Typography>
          )}
        </Button>

        <Button
          component={motion.button}
          className={styles.SignupButton}
          onClick={loginAction}
        >
          <Typography className={styles.signupText}>
            Already have an accoun? Login
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ users }) => {
  return {
    loading: users.loadingSignup
  };
};

export default connect(mapStateToProps, { studentSignup })(StudentSignup);
