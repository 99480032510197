import {
  LOGIN_ATTEMPING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  SIGNUP_ATTEMPING,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  SEND_RESET_PASSWORD_ATTEMPING,
  SEND_RESET_PASSWORD_SUCCESS,
  SEND_RESET_PASSWORD_FAILED,
  RESET_PASSWORD_ATTEMPING,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  GET_USERS_ATTEMPING,
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
  GET_ALL_USERS_ATTEMPING,
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_SUCCESS,
  GET_STUDENT_PROFILE_BY_EMAIL_ATTEMPING,
  GET_STUDENT_PROFILE_BY_EMAIL_FAILED ,
  GET_STUDENT_PROFILE_BY_EMAIL_SUCCESS 
} from "../actions/Types";

const INITIAL_STATE = {
  loadingLogin: false,
  loadingSignup: false,
  profile: null,
  loadingReset: false,
  resetDone: false,
  errorReset: null,
  token: null,
  errorLogin: null,
  loadingEdit: false,
  saved: null,
  errorEdit: null,

  loadingResetPassword: false,
  sent: false,
  errorResetPassword: null,
  errorSignup: null,

  loadingGetUsers: false,
  users: null,
  errorGetUsers: null,

  loadingGetAllUsers: false,
  allUsers: null,
  errorGetAllUsers: null,

  loadingGetStudentProfileByEmail: false,
  studentProfileByEmail: null,
  errorGetStudentProfileByEmail: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_STUDENT_PROFILE_BY_EMAIL_ATTEMPING:
      return { ...state, loadingGetStudentProfileByEmail: true };

    case GET_STUDENT_PROFILE_BY_EMAIL_FAILED:
      return {
        ...state,
        loadingGetStudentProfileByEmail: false,
        errorGetStudentProfileByEmail: action.payload,
      };

    case GET_STUDENT_PROFILE_BY_EMAIL_SUCCESS:
      return { ...state, loadingGetStudentProfileByEmail: false, studentProfileByEmail: action.payload };


    case GET_ALL_USERS_ATTEMPING:
      return { ...state, loadingGetAllUsers: true };

    case GET_ALL_USERS_FAILED:
      return {
        ...state,
        loadingGetAllUsers: false,
        errorGetAllUsers: action.payload,
      };

    case GET_ALL_USERS_SUCCESS:
      return { ...state, loadingGetAllUsers: false, allUsers: action.payload };

    case GET_USERS_ATTEMPING:
      return { ...state, loadingGetUsers: true };

    case GET_USERS_FAILED:
      return {
        ...state,
        loadingGetUsers: false,
        errorGetUsers: action.payload,
      };

    case GET_USERS_SUCCESS:
      return { ...state, loadingGetUsers: false, users: action.payload };

    case LOGIN_ATTEMPING:
      return { loadingLogin: true };

    case LOGIN_FAILED:
      return { loadingLogin: false, errorLogin: action.payload };

    case LOGIN_SUCCESS:
      return { loadingLogin: false, profile: action.payload };

    case SIGNUP_ATTEMPING:
      return { ...state,loadingSignup: true };

    case SIGNUP_SUCCESS:
      return {...state, loadingSignup: false, profile: action.payload };

    case SIGNUP_FAILED:
      return { ...state,loadingSignup: false, errorSignup: action.payload };

    case SEND_RESET_PASSWORD_ATTEMPING:
      return { ...state,loadingResetPassword: true };

    case SEND_RESET_PASSWORD_FAILED:
      return {
        ...state, loadingResetPassword: false,
        sent: false,
        errorResetPassword: action.payload,
      };

    case SEND_RESET_PASSWORD_SUCCESS:
      return {...state, sent: true, loadingResetPassword: false };

    case RESET_PASSWORD_ATTEMPING:
      return {...state, loadingReset: true };

    case RESET_PASSWORD_FAILED:
      return { ...state,loadingReset: false, errorReset: action.payload };

    case RESET_PASSWORD_SUCCESS:
      return {...state, resetDone: action.payload, loadingReset: false };

    default:
      return state;
  }
};
