import {
    GET_NOTIFICATIONS_ATTEMPING,
    GET_NOTIFICATIONS_FAILED,
    GET_NOTIFICATIONS_SUCCESS,
} from '../actions/Types';

const INITIAL_STATE = {
    loadingNotifications: false, notifications: false, errorNotifications: null,
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

     
        case GET_NOTIFICATIONS_ATTEMPING:
            return {...state,  loadingNotifications: true };
    
        case GET_NOTIFICATIONS_FAILED:
            return {...state, loadingNotifications: false, errorNotifications: action.payload }
    
        case GET_NOTIFICATIONS_SUCCESS:
            return {...state,loadingNotifications: false , notifications: action.payload, unSeenCount:action.payload2}
    
        default: return state;

    }

};