import React, { useState, useEffect, useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  getAdminLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem,
  createNote,
  getNotes,
  deleteNote,
  getUsers,
  shareNote,
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import AsyncStorage from "@callstack/async-storage";
import firebase from "../../../../global/services/Firebase";
import CreateWriterPayment from "./CreateWriterPayment";
import ShareTo from "./ShareTo";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFilePicker } from "use-file-picker";
import NoteItem from "../../../../Components/NoteItem";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const AdminNotes = ({

 
  loadingAdminLedger,
  deleteAdminLedgerItem,
  createNote,
  loadingGetNotes,
  notes,
  loadingSaveNotes,
  getNotes,
  deleteNote,

  loadingGetUsers,
  users,
  getUsers,
  shareNote,
}) => {
  const initailFormState = {
    name: null,
    email: null,
    password: null,
    confirmPassword: null,

    description: null,
    reference: 0,
    date: new Date().toUTCString(),
    note: "",
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);

  const styles = useStyles();
  const [openPament, setOpenPament] = React.useState(false);
  const [formErrorsPayment, setErrorsPayment] = useState([]);

  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [userList, setUserList] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [formErrors, setErrors] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [noteShare, setNoteShare] = useState(null);
  const [query, setQuery] = useState("");
  
  const [openFileSelector, { filesContent, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    accept: [
      ".json",
      ".pdf",
      ".docx",
      ".doc",
      ".txt",
      ".ppt" + ".png",
      ".jpeg",
      ".jpg",
      ".xlsx",
      ".zip",
      ".rar",
      "image/*",
    ],

    multiple: false,
    // limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
  });

  useEffect(async () => {
    if (filesContent.length > 0) {
      const url = await uploadImageAsync(
        filesContent[0].content,
        filesContent[0].name
      );
      setFile({ name: filesContent[0].name, url });
    }
  }, [filesContent]);

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const uploadImageAsync = async (uri, name) => {
    setUploading(true);
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    const ref = firebase
      .storage()
      .ref("dashboard/notes/" + makeid(15))
      .child(name);
    const snapshot = await ref.put(blob);
    setUploading(false);

    return await snapshot.ref.getDownloadURL();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePayment = () => {
    setOpenPament(false);
  };

  useEffect(async () => {
    //N7IQuXEK5c893iWGKfW
    const id = await AsyncStorage.getItem("id");
    const role = await AsyncStorage.getItem("role");
    const noteShare = await AsyncStorage.getItem("noteShare");
    setNoteShare(noteShare)
    getNotes(id, query, () => {});
    getUsers(id, role, (users) => {
      setUserList(users);
    });
  }, []);

  useEffect(async () => {
    const id = await AsyncStorage.getItem("id");

    getNotes(id, query, () => {});
  }, [query]);

 

  const saveNote = async () => {
    const id = await AsyncStorage.getItem("id");
    const role = await AsyncStorage.getItem("role");
    const name = await AsyncStorage.getItem("name");
    const email = await AsyncStorage.getItem("email");

    let errors = [];
    if (!form.date) {
      errors.push("*Date is required.");
    }
    if (!form.reference) {
      errors.push("*reference is required.");
    }
    if (!form.description) {
      errors.push("*description is required.");
    }
    if (!form.periority) {
      errors.push("*periority is required.");
    }

    setErrorsPayment(errors);

    if (errors.length == 0) {
      createNote(
        id,
        name,
        role,
        email,
        form?.date,
        form?.reference,
        form?.description,
        form?.note,
        form?.periority,
        file,
        (profile) => {
          setFile(null);
          handleClosePayment();
          let users = [];
          userList.map((item, index) => {
            users.push({
              ...item,
              checked: false,
            });
          });

          setUserList(users);
        }
      );
    }
  };

  const shareNoteAction = () => {
    if (selectedNote) {
      userList?.map((user, index) => {
        if (user.checked == true) {
          shareNote(
            selectedNote.id,
            new Date().toUTCString(),
            user.id,
            user.name,
            user.email,
            (data) => {
              handleClose();
            }
          );
        }
      });
    }
  };
  return (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid container direction="row">
        <ShareTo
          // loadingAddWriterPayment={load}
          note={selectedNote}
          SavePayment={() => shareNoteAction()}
          handleClose={() => handleClose()}
          formErrors={formErrors}
          open={open}
          users={userList}
          getUser={(user, index) => {
            let users = userList;
            users[index] = {
              ...users[index],
              checked: !users[index].checked,
            };
            setUserList(users);

            handleClose();
            setTimeout(() => {
              setOpen(true);
            }, 1);
          }}
        />

        <CreateWriterPayment
          loadingAddWriterPayment={loadingSaveNotes}
          SavePayment={() => saveNote()}
          handleClose={() => handleClosePayment()}
          formErrors={formErrorsPayment}
          open={openPament}
          openFileSelector={openFileSelector}
          onClickClose={() => setFile(null)}
          file={file}
          onChangeDate={(value) => {
            updateForm({
              key: "date",
              value: value.toUTCString(),
            });
          }}
          onChangeNote={(event) => {
            updateForm({ key: "note", value: event.target.value });
          }}
          onChangeReference={(event) => {
            updateForm({ key: "reference", value: event.target.value });
          }}
          onChangeDescription={(event) => {
            updateForm({ key: "description", value: event.target.value });
          }}
          choosePeriority={(item) => {
            updateForm({ key: "periority", value: item });
          }}
        />
      </Grid>
      <Grid direction="row" container>
        <Grid item>
          <Button
            onClick={() => {
              setOpenPament(true);
            }}
            className={styles.createPaymentButton}
            style={{
              backgroundColor: Colors.blue,
              marginLeft: "20px",
            }}
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.green,
              transition: { duration: 0.3 },
            }}
          >
            <Typography className={styles.messageText}>Add new Note</Typography>
          </Button>
        </Grid>

        <Grid className={styles.formInput} item>
          <TextField
            multiline={true}
            id="outlined-basic"
            label="Search"
            variant="outlined"
            color="primary"
            style={{
              width: "300px",
              fontFamily: "Spartan",
            }}
            onChange={(event) => {
                setQuery( event.target.value? event.target.value:"")
            }}
          />
        </Grid>
        
      </Grid>
      <Grid container direction="column">
        {notes?.map((note, index) => {
          
          return (
            <NoteItem
              key={index + ""}
              note={note.note}
              periority={note.periority}
              reference={note.reference}
              description={note.description}
              date={note.date}
              file={note.file}
              shares={note.shares}
              sharedToMe={note.sharedToMe}
              userName={note.userName}
              sharedNames={note.sharedNames}
              deleteNote={() => {
                deleteNote(note.id);
              }}
              noteShare={noteShare}
              shareNote={() => {
                
                setSelectedNote(note);
                setOpen(true);
              }}
            />
          );
        })}
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ users, ledger, notes }) => {
  return {
    adminLedger: ledger.adminLedger,
    loadingSaveAdminLedger: ledger.loadingSaveAdminLedger,
    loadingAdminLedger: ledger.loadingAdminLedger,

    loadingGetNotes: notes.loadingGetNotes,
    notes: notes.notes,
    loadingSaveNotes: notes.loadingSaveNotes,

    loadingGetUsers: users.loadingGetUsers,
    users: users.users,
    errorGetUsers: users.errorGetUsers,
  };
};

export default connect(mapStateToProps, {
  getAdminLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem,
  createNote,
  getNotes,
  deleteNote,
  getUsers,
  shareNote,
})(AdminNotes);
