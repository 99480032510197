import React, { useState, useEffect, useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { motion } from "framer-motion";
import AttachFile from "@material-ui/icons/AttachFile";
import Close from "@material-ui/icons/Close";
import Popover from "@mui/material/Popover";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      style={{ backgroundColor: Colors.blue }}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon style={{ color: Colors.white }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const CreateWriterPayment = ({
  loadingAddWriterPayment,

  signup,
  SavePayment,
  handleClose,
  handleClickOpen,
  open,
  onChangeDate,
  onChangeReference,
  onChangeDescription,
  formErrors,
  onChangeNote,
  uploading,
  openFileSelector,
  onClickClose,
  choosePeriority,
  file
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(new Date());
  const [periority, setPeriority] = useState("Priority");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDrop = () => {
    setAnchorEl(null);
  };

  const openDrop = Boolean(anchorEl);
  const id = openDrop ? "simple-popover" : undefined;

  const Perioriries = [
    {
      name: "Highest",
      backgroundColor: Colors.red
    },

    {
      name: "High",
      backgroundColor: Colors.red
    },
    {
      name: "Medium",
      backgroundColor: Colors.orange
    },
    {
      name: "Low",
      backgroundColor: Colors.blue
    },
    {
      name: "Lowest",
      backgroundColor: Colors.blue
    }
  ];
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={styles.BootstrapDialog}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{
            fontSize: "13px",
            fontFamily: "Spartan",
            fontWeight: "bold",
            color: Colors.white
          }}
        >
          Create new Note
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid className={styles.formInput} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(value) => {
                setValue(value);
                onChangeDate(value);
              }}
              value={value}
              renderInput={(params) => (
                <TextField
                  style={{ marginTop: "10px", width: "500px" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid className={styles.formInput} item>
          <TextField
            id="outlined-basic"
            label="Reference"
            variant="outlined"
            color="primary"
            style={{ marginTop: "10px", width: "500px" }}
            onChange={onChangeReference}
          />
        </Grid>

        <Grid className={styles.formInput} item>
          <TextField
            multiline={true}
            id="outlined-basic"
            label="Description"
            variant="outlined"
            color="primary"
            style={{
              marginTop: "10px",
              width: "500px",
              fontFamily: "Spartan"
            }}
            onChange={onChangeDescription}
          />
        </Grid>

        <Grid className={styles.formInput} item>
          <TextField
            multiline={true}
            id="outlined-basic"
            label="Note"
            variant="outlined"
            color="primary"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              width: "500px",
              fontFamily: "Spartan"
            }}
            onChange={onChangeNote}
          />
        </Grid>
        <Grid className={styles.formInput} item>
          <Button style={{marginLeft:"-10px",marginTop:"-10px"}} onClick={handleClick}>
            <TextField
              disabled={true}
              id="outlined-basic"
              label={periority}
              variant="outlined"
              color="primary"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                width: "500px",
                fontFamily: "Spartan",
                color: periority.backgroundColor
              }}
            />
          </Button>
        </Grid>
        <Grid className={styles.formInput} item>
          <Popover
            id={id}
            open={openDrop}
            anchorEl={anchorEl}
            onClose={handleCloseDrop}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            <Grid container direction="column">
              {Perioriries.map((item, index) => {
                return (
                  <Button
                    component={motion.button}
                    whileHover={{
                      backgroundColor: Colors.grey6,
                      transition: { duration: 0.1 }
                    }}
                    key={index + " "}
                    onClick={() => {
                      setPeriority(item.name);
                      handleCloseDrop();
                      choosePeriority(item);
                    }}
                    style={{
                      width: "300px",
                      height: "50px"
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "11px",
                        fontFamily: "Spartan",
                        color: item.backgroundColor,
                        padding: "5px",
                        fontWeight: "bold"
                      }}
                      sx={{ p: 2 }}
                    >
                      {item.name}
                    </Typography>
                  </Button>
                );
              })}
            </Grid>
          </Popover>
        </Grid>

        <Grid className={styles.formInput} item>
          {uploading ? (
            <CircularProgress
              style={{ alignSelf: "center", color: Colors.black }}
            />
          ) : file?.url ? (
            <Grid justifyContent="center" alignItems="center" direction="row">
              <a className={styles.fileName}> {file?.name}</a>
              <Button onClick={onClickClose}>
                <Close />
              </Button>
            </Grid>
          ) : (
            <Button
              component={motion.button}
              whileHover={{
                backgroundColor: Colors.black,
                transition: { duration: 0.3 },
                color: Colors.white
              }}
              className={styles.AttachFile}
              onClick={() => openFileSelector()}
            >
              <AttachFile />
              <Typography>Attach File</Typography>
            </Button>
          )}
        </Grid>

        <Grid item>
          {formErrors.length > 0 && (
            <Alert
              style={{
                width: "500px",
                marginTop: "20px",
                marginLeft: "15px"
              }}
              severity="error"
            >
              {formErrors.map((error, index) => {
                return (
                  <Typography
                    style={{ fontSize: "13px", fontFamily: "Spartan" }}
                  >
                    {error}
                  </Typography>
                );
              })}
            </Alert>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.green,
            transition: { duration: 0.1 },
            color: Colors.white
          }}
          className={styles.save}
          autoFocus
          onClick={SavePayment}
        >
          {loadingAddWriterPayment ? (
            <CircularProgress style={{ color: Colors.blue }} />
          ) : (
            <Typography
              style={{
                fontSize: "15px",
                fontFamily: "Spartan",
                fontWeight: "bold",
                textTransform: "none"
              }}
            >
              Save
            </Typography>
          )}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

const mapStateToProps = ({ writer }) => {
  return {};
};

export default connect(mapStateToProps, {})(CreateWriterPayment);
