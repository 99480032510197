import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Button, Checkbox } from "@material-ui/core";
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import login_back from "../../../assets/images/auth/student.png";
import logo from "../../../assets/global/logo.png";
import useStyles from "./Style.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { sendResetPassword } from "../../../Redux/actions";
import AsyncStorage from "@callstack/async-storage";

const StudentForgotPasswordSuccess = ({
  sendResetPassword,
  resetDone,
  loading
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  useEffect(async () => {
    const loggedIn = await AsyncStorage.getItem("loggedIn");

    if (loggedIn == true) {
      history.push("/dashboard/home");
    }
  }, []);

  const loginAction = async () => {
    history.push("/student/login");
  };
  
  return (
    <Grid
      direction={matches ? "column" : "row"}
      container
      justify="flex-start"
      alignItems="flex-start"
      className={styles.container}
    >
      <div className={styles.imgDev}>
        <img src={login_back} className={styles.img} />
        <Grid
          direction="row"
          container
          justify="center"
          alignItems="center"
          className={styles.logoView}
        >
          <Grid item>
            <img src={logo} className={styles.logo} />
          </Grid>
          <Grid>
            <Typography className={styles.appName}>A+ Paper Writer</Typography>
          </Grid>
        </Grid>
      </div>
      <Grid
        direction="column"
        container
        justify="flex-start"
        alignItems="flex-start"
        className={styles.inputContent}
      >
        <img src={logo} className={styles.logo2} />

        <Typography className={styles.welcomeText}>
          Reset password link has been sent, please check your email
        </Typography>

        <Button
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.green,
            transition: { duration: 0.3 }
          }}
          className={styles.SigninButton}
          onClick={loginAction}
        >
          <Typography className={styles.signinText}>Login</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ users }) => {
  return {
    resetDone: users.sent,
    loading: users.loadingResetPassword,
    error: users.errorResetPassword
  };
};

export default connect(mapStateToProps, { sendResetPassword })(
  StudentForgotPasswordSuccess
);
