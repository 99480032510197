import React, { useState, useEffect, Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/icons/Menu';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ListItem from '@material-ui/core/ListItem';
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import { } from '@material-ui/icons'
import { theme2 } from '../Theme'
import Colors from '../../Utils/Colors'
import { Link, Router } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { motion } from "framer-motion"

const Header = () => {



    function ElevationScroll(props) {
        const { children, window } = props;
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
            target: window ? window() : undefined,
        });

        return React.cloneElement(children, {
            elevation: trigger ? 4 : 0,

        });
    }
    const styles = useStyles()
    const [tabValue, SetTabValue] = useState(0)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open });
    };

    const lists = [
        { name: "Home", to: "/" },
        { name: "How It Works", to: "/how_it_works" },
        { name: "Code & Build", to: "/codeandbuild" },
        { name: "Inspiration", to: "/inspiration" }
    ]
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    const drawer = (
        <Fragment>

            <Button href className={styles.menuButton} onClick={toggleDrawer("top", true)}><Menu className={styles.menu} /></Button>

            <SwipeableDrawer
                //className={styles.drawer}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                anchor={"top"}
                open={state["top"]}
                onOpen={toggleDrawer("top", true)}
                onClose={toggleDrawer("top", false)}>

                <List className={styles.list}>
                    <Button className={styles.menuButton} onClick={toggleDrawer("top", false)}><ArrowForward className={styles.menu} /></Button>

                    {
                        lists.map((item, index) => {
                            const style = tabValue == index ? styles.listItemSelected : styles.listItem
                            return (


                                <ListItem onClick={() => {
                                    
                                }
                                } className={styles.listItem} component={Link} to={item.to}>
                                    <motion.Button
                                        animate={tabValue == index ? {
                                            backgroundColor: Colors.lightBlack,
                                            color: Colors.white,
                                            scale: 1.1,

                                        } : {}
                                        }
                                        whileHover={{
                                            backgroundColor: Colors.lightBlack,
                                            color: Colors.white,
                                            scale: 1.1,
                                            transition: { duration: .7 },

                                        }}
                                        variant="text" className={styles.tab}
                                        onClick={()=>{
                                            setState({ ...state, ["top"]: false });
                                            SetTabValue(index)

                                        }}
                                    >
                                        {item.name}

                                    </motion.Button>
                                </ListItem>
                            )
                        })
                    }
                    <motion.Button
                        whileHover={{
                            backgroundColor: Colors.secondaryColor,
                            color: Colors.white,

                        }}
                        download
                        href="../../../assets/files/Mohamed Adel_resume.pdf"

                        variant="contained" className={styles.Resume2}>
                        <a href="https://www.cakeresume.com/pdf/s--qf-kQn4_zlAl1BaYEV3ejA--/adr7L.pdf" className={styles.ResumeA} download> @Resume</a>
                    </motion.Button>
                </List>
            </SwipeableDrawer>
        </Fragment>
    )
    useEffect(() => {
        if (window.location.pathname === "/" && tabValue != 0) {
            SetTabValue(0)
        }
        else if (window.location.pathname === "/how_it_works" && tabValue != 1) {
            SetTabValue(1)
        }
        else if (window.location.pathname === "/codeandbuild" && tabValue != 2) {
            SetTabValue(2)
        }
        else if (window.location.pathname === "/inspiration" && tabValue != 3) {
            SetTabValue(3)
        }
       
    }, [tabValue])

    const tabs = (
        <Fragment>
            <Tabs
                onChange={(e, value) => {
                    SetTabValue(value)
                }}
                indicatorColor='common'
                textColor="secondary"

                value={tabValue}
                className={styles.tabs}>

                <Tab selected="common" component={Link} to="/" className={styles.tab}
                    label={
                        <motion.Button
                            animate={tabValue == 0 ? {
                                backgroundColor: Colors.lightBlack,
                                color: Colors.white,
                                scale: 1.1,

                            } : {}
                            }
                            whileHover={{
                                backgroundColor: Colors.lightBlack,
                                color: Colors.white,
                                scale: 1.1,
                                transition: { duration: .7 },

                            }}
                            variant="text" className={styles.tab}>
                            Home

                </motion.Button>} />
                <Tab selected="common" component={Link} to="/how_it_works" className={styles.tab}
                    label={
                        <motion.Button
                            animate={tabValue == 1 ? {
                                backgroundColor: Colors.lightBlack,
                                color: Colors.white,
                                scale: 1.1,

                            } : {}
                            }
                            whileHover={{
                                backgroundColor: Colors.lightBlack,
                                color: Colors.white,
                                scale: 1.1,
                                transition: { duration: .7 },

                            }}
                            variant="text" className={styles.tab}>
                            How It Works

                </motion.Button>} />
                <Tab selected="common" component={Link} to="/codeandbuild" className={styles.tab}
                    label={
                        <motion.Button
                            animate={tabValue == 2 ? {
                                backgroundColor: Colors.lightBlack,
                                color: Colors.white,
                                scale: 1.1,

                            } : {}
                            }
                            whileHover={{
                                backgroundColor: Colors.lightBlack,
                                color: Colors.white,
                                scale: 1.1,
                                transition: { duration: .7 },

                            }}
                            variant="text" className={styles.tab}>
                            Code & Build

                </motion.Button>} />
                <Tab selected="common" component={Link} to="/inspiration" className={styles.tab} label={
                    <motion.Button
                        animate={tabValue == 3 ? {
                            backgroundColor: Colors.lightBlack,
                            color: Colors.white,
                            scale: 1.1,

                        } : {}
                        }
                        whileHover={{
                            backgroundColor: Colors.lightBlack,
                            color: Colors.white,
                            scale: 1.1,
                            transition: { duration: .7 },

                        }}
                        variant="text" className={styles.tab}>
                        Inspiration

                </motion.Button>} />

            </Tabs>

            <motion.Button

                whileHover={{
                    backgroundColor: Colors.secondaryColor,
                    color: Colors.white,
                }}

                variant="contained" className={styles.Resume}>
                <a href="https://www.cakeresume.com/pdf/s--qf-kQn4_zlAl1BaYEV3ejA--/adr7L.pdf" className={styles.ResumeA} download> @Resume</a>

            </motion.Button>
        </Fragment>
    )
    return (
        <Fragment>

            <ThemeProvider theme={theme2}>
                <ElevationScroll>

                    <AppBar position='fixed' color='primary'>
                        <Toolbar className={styles.Toolbar}>
                            <Button to='/' component={Link} variant='text' className={styles.name}>
                                Ⲙohαᴍêd Adel
                        </Button>

                            {matches ? drawer : tabs}

                        </Toolbar>

                    </AppBar>

                </ElevationScroll>
            </ThemeProvider>
        </Fragment>


    );
    ;
}

const useStyles = makeStyles(theme => ({
    list: {

        background: Colors.primaryColor,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 20,
        height: window.innerHeight,


    },
    listItem: {
        color: Colors.white,
        fontSize: '16px',
        fontFamily: "Spartan",
        fontWeight: 'bold',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: 10,
        width: "200px",

    },
    listItemSelected: {
        color: Colors.lightBlue,
        fontSize: '16px',
        fontFamily: "Spartan",
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 10,
        width: "200px",

        '&:hover': {
            border: '1px solid white',
            borderRadius: 20

        }
    },
    drawer: {
        marginTop: "80px"
    },
    name: {
        color: Colors.white,
        fontSize: 16,
        fontFamily: "Spartan",
        fontWeight: 'bold'
    },
    image: {
        width: 100,
        height: 50,
        borderRadius: 10,
        marginLeft: 20
    },
    menu: {
        color: Colors.white,
        fontSize: 30,
    },
    menuButton: {
        marginLeft: 'auto'

    },

    Toolbar: {
        height: 80,
    },
    tabs: {
        marginLeft: 'auto',
        marginRight: 50
    },
    tab: {
        textTransform: 'none',
        fontFamily: 'Spartan',
        minWidth: 15,
        marginLeft: 10,
        color: Colors.grey2,
        fontWeight: 'bold',
        height: 40,
        backgroundColor: Colors.primaryColor,
        borderRadius: "10px",
        borderColor: Colors.none,



    },
   
    Resume: {
        background: Colors.white,
        textTransform: 'none',
        color: Colors.secondaryColor,
        height: 50,
        borderRadius: 10,
        paddingVertical: 20,
        fontFamily: 'Spartan',
        fontWeight: 'bold',
        borderColor: Colors.none,

    },
    ResumeA: {
        background: Colors.none,
        textTransform: 'none',
        borderRadius: 10,
        paddingVertical: 20,
        fontFamily: 'Spartan',
        paddingRight: 10,
        height: 50,

        fontWeight: 'bold',
        borderColor: Colors.none,
        '&:hover': {
            color: Colors.white,


        }

    }
    ,

    Resume2: {
        background: Colors.white,
        textTransform: 'none',
        color: Colors.secondaryColor,
        height: 50,
        borderRadius: 10,
        paddingVertical: 20,
        fontFamily: 'Spartan',
        paddingRight: 10,
        fontWeight: 'bold',
        marginTop: 20,
        borderColor: Colors.none



    },



}))


export default Header 
