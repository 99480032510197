import React, { useState, useEffect, useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  getWriters,
  createWriter,
  getWriterStats,
  getWriter,
  updatePassword,
  signup,
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeft";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      style={{ backgroundColor: Colors.blue }}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{ color: Colors.white }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CreateWriterPassword = ({
  loadingAddWriter,
  errorAddWriter,
  getWriter,
  updatePassword,
  signup,
  saveWriter,
  handleClose,
  handleClickOpen,
  open,
  onChangePassword,
  onChangeConfirmPassword,
  formErrors,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={styles.BootstrapDialog}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{
            fontSize: "13px",
            fontFamily: "Spartan",
            fontWeight: "bold",
            color: Colors.white,
          }}
        >
          Create Writer Password
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid className={styles.formInput} item>
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            color="primary"
            type={"password"}
            style={{ marginTop: "10px", width: "300px" }}
            onChange={onChangePassword}
          />
        </Grid>
        <Grid className={styles.formInput} item>
          <TextField
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
            type={"password"}
            color="primary"
            style={{
              marginTop: "10px",
              width: "300px",
              fontFamily: "Spartan",
            }}
            onChange={onChangeConfirmPassword}
          />
        </Grid>
        <Grid item>
          {formErrors.length > 0 && (
            <Alert
              style={{
                width: "300px",
                marginTop: "20px",
                marginLeft: "15px",
              }}
              severity="error"
            >
              {formErrors.map((error, index) => {
                return (
                  <Typography
                    style={{ fontSize: "13px", fontFamily: "Spartan" }}
                  >
                    {error}
                  </Typography>
                );
              })}
            </Alert>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.green,
            transition: { duration: 0.3 },
            color:Colors.white

          }}
          className={styles.save}
          autoFocus
          onClick={saveWriter}
        >
          {loadingAddWriter ? (
            <CircularProgress style={{ color: Colors.blue }} />
          ) : (
            <Typography
              style={{
                fontSize: "15px",
                fontFamily: "Spartan",
                color: Colors.blue,
                textTransform: "none",
              }}
            >
              Save
            </Typography>
          )}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

const mapStateToProps = ({ writer }) => {
  return {
    loadingGetWriters: writer.loadingGetWriters,
    errorGetWriters: writer.errorGetWriters,
    writers: writer.writers,
    stats: writer.stats,
    errorGetWritersStats: writer.errorGetWritersStats,
    loadingGetWritersStats: writer.loadingGetWritersStats,
  };
};

export default connect(mapStateToProps, {
  createWriter,
  getWriters,
  getWriterStats,
  getWriter,
  updatePassword,
  signup,
})(CreateWriterPassword);
