import {
    CREATE_CHAT_MESSAGE_ATTEMPING,
    CREATE_CHAT_MESSAGE_FAILED,
    CREATE_CHAT_MESSAGE_SUCCESS,
    GET_CHAT_MESSAGES_ATTEMPING,
    GET_CHAT_MESSAGES_FAILED,
    GET_CHAT_MESSAGES_SUCCESS,

    GET_CHAT_MESSAGES_STUDENT_ATTEMPING,
    GET_CHAT_MESSAGES_STUDENT_FAILED ,
    GET_CHAT_MESSAGES_STUDENT_SUCCESS 

} from '../actions/Types';

const INITIAL_STATE = {
    loadingChatMessage: false, chatMessagSaved: false, errorChatMessage: null,
    loadingGetchatMessages: false, chatMessages: null, errorGetChatMessages: null,unSeenCount:null,
    loadingGetchatMessagesStudent: false, chatMessagesStudent: null, errorGetChatMessagesStudent: null,unSeenCountStudent:null,
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {


        case GET_CHAT_MESSAGES_STUDENT_ATTEMPING:
            return {...state,  loadingGetchatMessagesStudent: true,chatMessages:[] ,unSeenCount:null};
    
        case GET_CHAT_MESSAGES_STUDENT_FAILED:
            return {...state, loadingGetchatMessagesStudent: false, errorGetChatMessagesStudent: action.payload }
    
        case GET_CHAT_MESSAGES_STUDENT_SUCCESS:
            return {...state,loadingGetchatMessagesStudent: false , chatMessagesStudent: action.payload, unSeenCountStudent:action.payload2}
    

    
        case GET_CHAT_MESSAGES_ATTEMPING:
            return {...state,  loadingGetchatMessages: true,chatMessages:[] ,unSeenCount:null};
    
        case GET_CHAT_MESSAGES_FAILED:
            return {...state, loadingGetchatMessages: false, errorGetChatMessages: action.payload }
    
        case GET_CHAT_MESSAGES_SUCCESS:
            return {...state,loadingGetchatMessages: false , chatMessages: action.payload, unSeenCount:action.payload2}
    
        default: return state;

    }

};