import {
  CREATE_WRITER_ATTEMPING,
  CREATE_WRITER_FAILED,
  CREATE_WRITER_SUCCESS,
  GET_WRITERS_ATTEMPING,
  GET_WRITERS_FAILED,
  GET_WRITERS_SUCCESS,

  GET_WRITER_STATS_ATTEMPING,
  GET_WRITER_STATS_FAILED ,
  GET_WRITER_STATS_SUCCESS ,

  GET_WRITER_ORDERS_ATTEMPING,
  GET_WRITER_ORDERS_FAILED ,
  GET_WRITER_ORDERS_SUCCESS ,


  SAVE_WRITER_PAYMENT_ATTEMPING,
  SAVE_WRITER_PAYMENT_FAILED,
  SAVE_WRITER_PAYMENT_SUCCESS,


  GET_WRITER_PAYMENTS_ATTEMPING,
GET_WRITER_PAYMENTS_FAILED ,
GET_WRITER_PAYMENTS_SUCCESS 

} from "../actions/Types";

const INITIAL_STATE = {
  loadingAddWriter: false,
  errorAddWriter: false,
  writer: null,

  loadingGetWriters: false,
  errorGetWriters: false,
  writers: null,

  loadingGetWritersStats: false,
  errorGetWritersStats: false,
  stats: null,
  writerOrders: null,
  loadingGetWritersOrders: false,
  errorGetWritersOrders: false,

  loadingAddWriterPayment: false,
  errorAddWriterPayment: false,


  loadingGetWriterPayments: false,
  errorGetWriterPayments: false,
  writerPayments: null,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_WRITER_PAYMENTS_ATTEMPING:
      return {...state, loadingGetWriterPayments: true, errorGetWriterPayments: null };

    case GET_WRITER_PAYMENTS_FAILED:
      return {...state, loadingGetWriterPayments: false, errorGetWriterPayments: action.payload };

    case GET_WRITER_PAYMENTS_SUCCESS:
      return { ...state, loadingGetWriterPayments: false,writerPayments:action.payload  };




    case SAVE_WRITER_PAYMENT_ATTEMPING:
      return {...state, loadingAddWriterPayment: true, errorGetWritersOrders: null };

    case SAVE_WRITER_PAYMENT_FAILED:
      return {...state, loadingAddWriterPayment: false, errorAddWriterPayment: action.payload };

    case SAVE_WRITER_PAYMENT_SUCCESS:
      return { ...state, loadingAddWriterPayment: false,  };


    case GET_WRITER_ORDERS_ATTEMPING:
      return {...state, loadingGetWritersOrders: true, errorGetWritersOrders: null };

    case GET_WRITER_ORDERS_FAILED:
      return {...state, loadingGetWritersOrders: false, errorGetWritersOrders: action.payload };

    case GET_WRITER_ORDERS_SUCCESS:
      return { ...state, loadingGetWritersOrders: false, writerOrders: action.payload };


    case GET_WRITER_STATS_ATTEMPING:
      return {...state, loadingGetWritersStats: true, errorGetWritersStats: null };

    case GET_WRITER_STATS_FAILED:
      return {...state, loadingGetWritersStats: false, errorGetWritersStats: action.payload };

    case GET_WRITER_STATS_SUCCESS:
      return { ...state, loadingGetWritersStats: false,stats: action.payload2 };


    case GET_WRITERS_ATTEMPING:
      return { loadingGetWriters: true, errorAddWriter: null };

    case GET_WRITERS_FAILED:
      return { loadingGetWriters: false, errorGetWriters: action.payload };

    case GET_WRITERS_SUCCESS:
      return {  loadingGetWriters: false, writers: action.payload };

    case CREATE_WRITER_ATTEMPING:
      return { ...state, loadingAddWriter: true, errorAddWriter: null };

    case CREATE_WRITER_FAILED:
      return { ...state, loadingAddWriter: false, errorAddWriter: action.payload };

    case CREATE_WRITER_SUCCESS:
      return { ...state, loadingAddWriter: false, writer: action.payload };

    default:
      return state;
  }
};
