import firebase from "../../global/services/Firebase";
import AsyncStorage from "@callstack/async-storage";
import axios from "axios";
import { ServiceURL } from "../../global/services/config";

import {
  CREATE_WRITER_ATTEMPING,
  CREATE_WRITER_FAILED,
  CREATE_WRITER_SUCCESS,
  GET_WRITERS_ATTEMPING,
  GET_WRITERS_FAILED,
  GET_WRITERS_SUCCESS,
  GET_WRITER_ATTEMPING,
  GET_WRITER_FAILED,
  GET_WRITER_SUCCESS,
  GET_WRITER_STATS_ATTEMPING,
  GET_WRITER_STATS_FAILED,
  GET_WRITER_STATS_SUCCESS,
  GET_WRITER_ORDERS_ATTEMPING,
  GET_WRITER_ORDERS_FAILED,
  GET_WRITER_ORDERS_SUCCESS,
  SAVE_WRITER_PAYMENT_ATTEMPING,
  SAVE_WRITER_PAYMENT_FAILED,
  SAVE_WRITER_PAYMENT_SUCCESS,
  DELETE_WRITER_PAYMENT_ATTEMPING,
  DELETE_WRITER_PAYMENT_FAILED,
  DELETE_WRITER_PAYMENT_SUCCESS,
  GET_WRITER_PAYMENTS_ATTEMPING,
  GET_WRITER_PAYMENTS_FAILED,
  GET_WRITER_PAYMENTS_SUCCESS,
  EDIT_WRITER_PAYMENT_ATTEMPING,
  EDIT_WRITER_PAYMENT_FAILED,
  EDIT_WRITER_PAYMENT_SUCCESS,
} from "./Types";

export const getWriterPayment = (writerId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_WRITER_PAYMENTS_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/users/" + writerId + "/payments")
        .on("value", (snapshot) => {
          const payments = [];

          snapshot.forEach((value) => {
            let payment = value.val();
            payment.createDate = payment.date;
            payments.push(payment);

            if (payments) {
              dispatch({
                type: GET_WRITER_PAYMENTS_SUCCESS,
                payload: payments.reverse(),
              });
            }
          });
        });
    } catch (error) {
      dispatch({ type: GET_WRITER_PAYMENTS_FAILED });
    }
  };
};

export const deleteWriterPayment = (writerId, paymentId, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_WRITER_PAYMENT_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/users/" + writerId + "/payments/" + paymentId)
        .remove()
        .then((response) => {
          callBack(true);
          dispatch({
            type: DELETE_WRITER_PAYMENT_SUCCESS,
          });
        });
    } catch (error) {
      dispatch({ type: DELETE_WRITER_PAYMENT_FAILED });
    }
  };
};

export const editWriterPayment = (
  writerId,
  paymentId,
  item,
  date,
  price,
  note,
  callBack
) => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_WRITER_PAYMENT_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/users/" + writerId + "/payments/" + paymentId)
        .update({ item, date, price, note })
        .then((response) => {
          callBack(true);
          dispatch({
            type: EDIT_WRITER_PAYMENT_SUCCESS,
          });
        });
    } catch (error) {
      dispatch({ type: EDIT_WRITER_PAYMENT_FAILED });
    }
  };
};

export const createWriterPayment = (
  writerId,
  writerName,
  writerEmail,
  item,
  date,
  price,
  note,
  callBack
) => {
  return async (dispatch) => {
    dispatch({
      type: SAVE_WRITER_PAYMENT_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/users/" + writerId + "/payments")
        .push({ writerId, writerName, writerEmail, item, date, price, note })
        .then((response) => {
          firebase
            .database()
            .ref("dashboard/users/" + writerId + "/payments/" + response.key)
            .update({
              id: response.key,
            });
          callBack(true);
          dispatch({
            type: SAVE_WRITER_PAYMENT_SUCCESS,
          });
        });
    } catch (error) {
      dispatch({ type: SAVE_WRITER_PAYMENT_FAILED });
    }
  };
};

export const getStudentsOrders = (
  email,
  fulfillmentStatus,
  productId,
  keywords
) => {
  return async (dispatch) => {
    dispatch({
      type: GET_WRITER_ORDERS_ATTEMPING,
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetOrders(email, fulfillmentStatus, productId, keywords),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      let orders = [];
      data?.items?.filter((order) => {
      
          let total = 0.0;

          order?.items?.map((item) => {

            item?.selectedOptions?.filter((option) => {
              if (option.type == "CHOICE") {
                option?.selections?.map((selection) => {
                  total += selection?.selectionModifier;
                });
              }
            });

            if (parseInt(order.total)==0) {
              order.total=total
            }
            
          });


          orders.push(order);
        
      });

      dispatch({
        type: GET_WRITER_ORDERS_SUCCESS,
        payload: orders,
      });
    } catch (error) {
      dispatch({
        type: GET_WRITER_ORDERS_FAILED,
      });
    }
  };
};

export const getWriterOrders = (
  email,
  fulfillmentStatus,
  productId,
  keywords
) => {
  return async (dispatch) => {
    dispatch({
      type: GET_WRITER_ORDERS_ATTEMPING,
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetOrders("", fulfillmentStatus, productId, keywords),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      let orders = [];
      data?.items?.filter((order) => {
        var text = order?.privateAdminNotes;
        var orderEmail = String(text).match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        );

        if (orderEmail && orderEmail[orderEmail.length - 1] == email) {
          let total = 0.0;

          order?.items?.map((item) => {
            let pageCount = 0;
            let isInRush = false;

            item?.selectedOptions?.filter((option) => {
              if (option.name == "Page Count" ) {
                pageCount = parseInt(option.value);
              }
              if (option.name =="Slide Count") {
                pageCount = parseInt(option.value);
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("1 day")
              ) {
                isInRush = true;
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("Same day")
              ) {
                isInRush = true;
              }
            });
            if (isInRush) {
              total += 8 * pageCount;
            } else {
              total += 6 * pageCount;
            }
          });

          order.total = total;

          orders.push(order);
        }
      });

      dispatch({
        type: GET_WRITER_ORDERS_SUCCESS,
        payload: orders,
      });
    } catch (error) {
      dispatch({
        type: GET_WRITER_ORDERS_FAILED,
      });
    }
  };
};

export const getWriterStats = (email) => {
  return async (dispatch) => {
    dispatch({
      type: GET_WRITER_STATS_ATTEMPING,
    });

    try {
      var curr = new Date(); // get current date
      var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
      var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

      var config = {
        method: "get",
        url: ServiceURL.Orders(),
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      let orderStats = {
        totalOrdersCount: 0,
        totalPaid: 0.0,
        totalPaid2: 0.0,
        weekTotalPaid: 0.0,
        deliveredOrdersCount: 0,
        willNotDeliverOrdersCount: 0,
        processingOrdersCount: 0,
      };
      let orders = [];
      data?.items?.filter((order) => {
        var text = order?.privateAdminNotes;
        var orderEmail = String(text).match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        );

        if (orderEmail && orderEmail[0] == email) {
          orders.push(order);

          orderStats.totalOrdersCount += 1;
          if (order.fulfillmentStatus == "PROCESSING") {
            orderStats.processingOrdersCount += 1;
          }

          if (order.fulfillmentStatus == "WILL_NOT_DELIVER") {
            orderStats.willNotDeliverOrdersCount += 1;
          }
          if (order.fulfillmentStatus == "DELIVERED") {
              orderStats.deliveredOrdersCount += 1;
            
          }
          order?.items?.map((item) => {
            let pageCount = 0;
            let isInRush = false;

            item?.selectedOptions?.filter((option) => {
              if (option.name == "Page Count") {
                pageCount = parseInt(option.value);
              }
              if (option.name =="Slide Count") {
                pageCount = parseInt(option.value);
              }

              if (
                option.name == "Rush Service" &&
                option.value.includes("1 day")
              ) {
                isInRush = true;
              }
              if (
                option.name == "Rush Service" &&
                option.value.includes("Same day")
              ) {
                isInRush = true;
              }
            });
            if (isInRush) {
              orderStats.totalPaid += 8 * pageCount;
              if(new Date(order.createDate)>new Date("2023-09-1T15:42:35.862Z")){
                orderStats.totalPaid2 += 8 * pageCount;

              }
              if (
                new Date(new Date(order.createDate).toDateString()) >=
                  new Date(new Date(firstday).toDateString()) &&
                new Date(new Date(order.createDate).toDateString()) <=
                  new Date(new Date(lastday).toDateString())
              ) {
                orderStats.weekTotalPaid += 8 * pageCount;
              }
            } else {
              orderStats.totalPaid += 6 * pageCount;
              if(new Date(order.createDate)>new Date("2023-09-1T15:42:35.862Z")){
                orderStats.totalPaid2 += 6 * pageCount;
              }
              if (
                new Date(new Date(order.createDate).toDateString()) >=
                  new Date(new Date(firstday).toDateString()) &&
                new Date(new Date(order.createDate).toDateString()) <=
                  new Date(new Date(lastday).toDateString())
              ) {
                orderStats.weekTotalPaid += 6 * pageCount;
              }
            }
          });
        }
      });
      console.log("orderStats.totalPaid",orderStats.totalPaid);

      dispatch({
        type: GET_WRITER_STATS_SUCCESS,
        payload: orders,
        payload2: orderStats,
      });
    } catch (error) {
      dispatch({
        type: GET_WRITER_STATS_FAILED,
        //  payload: error.response.data.error,
      });
    }
  };
};

export const getWriter = (email, callBack, callBackError) => {
  return async (dispatch) => {
    dispatch({
      type: GET_WRITER_ATTEMPING,
    });

    firebase
      .database()
      .ref("dashboard/users")
      .on("value", async (snapshot) => {
        let profile = null;

        snapshot.forEach((value) => {
          let user = value.val();

          if (user.email == email) {
            profile = user;
          }
        });
        if (profile) {
          callBack(profile);
          dispatch({
            type: GET_WRITER_SUCCESS,
            payload: profile,
          });
        } else {
          dispatch({ type: GET_WRITER_FAILED });
        }
      });
  };
};

export const getWriters = (query) => {
  return async (dispatch) => {
    dispatch({
      type: GET_WRITERS_ATTEMPING,
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.GetWriters,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const { data } = await axios(config);
      let writers = [];
      if (query) {
        JSON.parse(String(data.value))?.writersList.filter((item) => {
          if (String(item.name).trim().toLocaleUpperCase().includes(String(query).trim().toLocaleUpperCase())||
          String(item.email).trim().toLocaleUpperCase().includes(String(query).trim().toLocaleUpperCase())
          ) {
            writers.push(item)
          }
        });
      } else {
        writers = JSON.parse(String(data.value))?.writersList;
      }

      dispatch({ type: GET_WRITERS_SUCCESS, payload: writers });
    } catch (error) {
      dispatch({
        type: GET_WRITERS_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const createWriter = (name, email, password) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_WRITER_ATTEMPING,
    });

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((resp) => {
        firebase
          .database()
          .ref("dashboard/writers/" + resp.user.uid)
          .set({
            id: resp.user.uid,
            name,
            password,
            email,
            role: "writer",
            createdAt: new Date().toISOString(),
          })
          .then(async (reponse) => {
            dispatch({
              type: CREATE_WRITER_SUCCESS,
              payload: {
                id: resp.user.uid,
                name,
                email,
                password,
                active: true,
                createdAt: new Date().toISOString(),
              },
            });
          })
          .catch((err) => {
            // dispatch({ type: SIGNUP_FAILED, payload: err.message });
          });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_WRITER_FAILED,
          payload: String(err.message).replace("Firebase:", ""),
        });
      });
  };
};
