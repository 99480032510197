import {

    GET_ADMIN_LEDGER_LIST_ATTEMPING,
    GET_ADMIN_LEDGER_LIST_FAILED,
    GET_ADMIN_LEDGER_LIST_SUCCESS,
    

    SAVE_ADMIN_LEDGER_ITEM_ATTEMPING,
    SAVE_ADMIN_LEDGER_ITEM_FAILED,
    SAVE_ADMIN_LEDGER_ITEM_SUCCESS,

    GET_ORDER_LEDGER_ATTEMPING,
GET_ORDER_LEDGER_FAILED ,
GET_ORDER_LEDGER_SUCCESS 
} from '../actions/Types';

const INITIAL_STATE = {
    loadingAdminLedger: false, errorAdminLedger: null, adminLedger: null,adminLedgerTotal: null,
    loadingSaveAdminLedger: false, errorSaveAdminLedger: null, adminLedgerSaved: null,
    loadingGetOrderLedger: false, errorGetOrderLedger: null, orderLedger: null,orderLedgerTotal: null,adminLedgerBalance:null
   
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        
        case GET_ORDER_LEDGER_ATTEMPING:
            return {...state,  loadingGetOrderLedger: true }

        case GET_ORDER_LEDGER_SUCCESS:
            return { ...state, loadingGetOrderLedger: false, orderLedger: action.payload,orderLedgerTotal: action.payload2 }

        case GET_ORDER_LEDGER_FAILED:
            return { ...state, loadingGetOrderLedger: false, errorGetOrderLedger: action.payload, }


        case GET_ADMIN_LEDGER_LIST_ATTEMPING:
            return {...state,  loadingAdminLedger: true }

        case GET_ADMIN_LEDGER_LIST_SUCCESS:
            return { ...state, loadingAdminLedger: false, adminLedger: action.payload ,adminLedgerTotal:action.payload2,adminLedgerBalance:action.payload3}

        case GET_ADMIN_LEDGER_LIST_FAILED:
            return { ...state, loadingAdminLedger: false, errorAdminLedger: action.payload, }

            case SAVE_ADMIN_LEDGER_ITEM_ATTEMPING:
                return {...state,  loadingSaveAdminLedger: true }
    
            case SAVE_ADMIN_LEDGER_ITEM_SUCCESS:
                return { ...state, loadingSaveAdminLedger: false, adminLedgerSaved: action.payload }
    
            case SAVE_ADMIN_LEDGER_ITEM_FAILED:
                return { ...state, loadingSaveAdminLedger: false, errorSaveAdminLedger: action.payload, }
    
        default: return state;

    }

};
