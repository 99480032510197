import React, { useState, useEffect, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  getAdminLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem
} from "../../../../Redux/actions";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { getOrders } from "../../../../Redux/actions";

const AdminSnapshot = ({
  getOrders,
  orders,
  openOrderDetails2
}) => {
  const styles = useStyles();

  const [orderList, setOrderList] = useState([]);
  let [sliceFrom, setSliceFrom] = useState(0);
  const [sliceTo, setSliceTo] = useState(5);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getOrders("", "", "", "");
  }, []);

   const debounce = (func, wait) => {
    let timeout;
  
    return function executedFunction() {
      const context = this;
      const args = arguments;
  
      const later = function () {
        timeout = null;
        func.apply(context, args);
      };
  
      clearTimeout(timeout);
  
      timeout = setTimeout(later, wait);
    };
  };
  const onChangeText = useCallback(debounce((value) => {
    getOrders("", "", "", value.target.value);

  }, 300), [setOrderList]);


  useEffect(() => {
    if (orders?.items?.length>0) {
      setOrderList(
        orders?.items
          ?.sort(
            (objA, objB) =>
              Number(new Date(objA?.items[objA?.items?.length-1]?.selectedOptions?.find((item, index) => String(item.name).includes("Due Date") )?.value)) - Number(new Date(objB?.items[objB?.items?.length-1]?.selectedOptions?.find((item, index) => String(item.name).includes("Due Date") )?.value))
          )
          ?.filter((order, index) => 
          order?.fulfillmentStatus=="PROCESSING" 
          ||order.fulfillmentStatus == "AWAITING_PROCESSING"
          ||order.fulfillmentStatus == "AWAITING_PAYMENT"
          ||order.fulfillmentStatus == "CUSTOM_FULFILLMENT_STATUS_1"
          ||order.fulfillmentStatus == "CUSTOM_FULFILLMENT_STATUS_2"
          ||order.fulfillmentStatus == "CUSTOM_FULFILLMENT_STATUS_3"
        )
      );
    }
  }, [orders?.items, page]);

  let listSize = parseInt(orderList?.length);
  console.log('orderList',orderList);
  
  return (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
       <Grid className={styles.formInput} item>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            color="primary"
            style={{
              width: "300px",
              fontFamily: "Spartan",
              marginTop: "20px"
            }}
            onChange={onChangeText}
          />
        </Grid>
      <TableContainer
        sx={{
          width: window.innerWidth - 300,
          marginLeft: "20px",
          marginTop: "30px"
        }}
        component={Paper}
      >
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow style={{}}>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Num#
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Order Number/Reference
              </TableCell>

              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  fontWeight: "bold",
                  color: Colors.white
                }}
                align="left"
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
                align="right"
              >
                Assignment Title
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
                align="right"
              >
                Due Date
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
                align="right"
              >
                Writer Assigned
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  fontWeight: "bold",
                  color: Colors.white
                }}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList?.slice(sliceFrom, sliceTo)?.map((row) => {
             var text = row?.privateAdminNotes;
             var name = String(row?.privateAdminNotes).split('. (');

             var orderEmail = String(text).match(
               /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
             );
                 return(
              <TableRow
                style={{
                  backgroundColor: String(row?.name)
                    .toUpperCase()
                    .includes("PAYMENT")
                    ? Colors.green + "33"
                    : Colors.white,
                }}
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{ fontFamily: "Spartan", fontSize: "12px" }}>
                  {listSize - sliceFrom++}
                </TableCell>

                <TableCell style={{ fontFamily: "Spartan", fontSize: "12px" }}>
                  {new Date(
                    new Date(row?.createDate).toLocaleString("en-US", {
                      timeZone: "America/New_York"
                    })
                  )
                    .toString()
                    .substr(0, 24)}
                </TableCell>

                <TableCell
                  style={{ fontFamily: "Spartan", fontSize: "12px" }}
                  className={styles.tableCell}
                  component="th"
                  scope="row"
                >
                  
                    <Button
                      onClick={() => {
                        openOrderDetails2(row);
                      }}
                    >
                      #{row?.id}
                    </Button>
                  
                </TableCell>

                <TableCell
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "13px",
                    color:Colors.orange,
                    fontWeight: "bold"
                  }}
                  align="left"
                >
                  {row?.fulfillmentStatus}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}
                >
                  {row?.items?.length>0&& row?.items[row?.items?.length-1]?.name}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}
                >
                  {row?.items?.length>0&&
                row?.items[row?.items?.length-1]?.selectedOptions?.find((item, index) => String(item.name).includes("Due Date") )?.value
                }
                  </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "12px",
                    fontWeight: "bold"
                  }}
                >
                {`${String(name[0])?.split(':')[1]}`} ({orderEmail[orderEmail?.length - 1]})
                </TableCell>
              </TableRow>
            )})}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack>
        <Pagination
          onChange={(event, value) => {
            setPage(value);
            setOrderList([]);
            if (value == 1) {
              setSliceFrom(0);
              setSliceTo(5);
            } else {
              setSliceFrom((value - 1) * 5);
              setSliceTo(value * 5);
            }
          }}
          page={page}
          style={{ alignSelf: "center", marginTop: "20px" }}
          count={Math.ceil(orderList?.length / 5)}
          color="primary"
        />
      </Stack>
    </div>
  );
};

const mapStateToProps = ({ ledger, orders }) => {
  return {
    loadingSaveAdminLedger: ledger.loadingSaveAdminLedger,
    loadingAdminLedger: ledger.loadingAdminLedger,
    adminLedgerTotal: ledger.adminLedgerTotal,
    adminLedgerBalance: ledger.adminLedgerBalance,
    loading: orders.loading,
    error: orders.error,
    orders: orders.orders
  };
};

export default connect(mapStateToProps, {
  getAdminLedger,
  createManaulLedgerItem,
  deleteAdminLedgerItem,
  getOrders
})(AdminSnapshot);
