import firebase from 'firebase/compat/app';
// Optionally import the services that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
//import {...} from "firebase/firestore";
//import {...} from "firebase/functions";
//import {...} from "firebase/storage";

const app = firebase.initializeApp({
  
    apiKey: "AIzaSyBFcvxXHh0LfKKmHDxooLqJ7dMgwYLNDM0",
  authDomain: "paper-writer-f57a6.firebaseapp.com",
  databaseURL: "https://paper-writer-f57a6-default-rtdb.firebaseio.com",
  projectId: "paper-writer-f57a6",
  storageBucket: "paper-writer-f57a6.appspot.com",
  messagingSenderId: "405864155902",
  appId: "1:405864155902:web:cf23c535e23db2a816b3af",
  measurementId: "G-FD6ZLT1YZG"
})

export default app


