import firebase from "../../global/services/Firebase";
import AsyncStorage from "@callstack/async-storage";
import {
  CREATE_CHAT_MESSAGE_ATTEMPING,
  CREATE_CHAT_MESSAGE_FAILED,
  CREATE_CHAT_MESSAGE_SUCCESS,
  GET_CHAT_MESSAGES_ATTEMPING,
  GET_CHAT_MESSAGES_FAILED,
  GET_CHAT_MESSAGES_SUCCESS,
  CHANGE_CHAT_MESSAGE_STATUS_ATTEMPING,
  CHANGE_CHAT_MESSAGE_STATUS_FAILED,
  CHANGE_CHAT_MESSAGE_STATUS_SUCCESS,
  CHANGE_CHAT_MESSAGE_SEEN_ATTEMPING,
  CHANGE_CHAT_MESSAGE_SEEN_FAILED,
  CHANGE_CHAT_MESSAGE_SEEN_SUCCESS,
  DELETE_CHAT_MESSAGE_ATTEMPING,
  DELETE_CHAT_MESSAGE_FAILED,
  DELETE_CHAT_MESSAGE_SUCCESS,
  SEND_SMS_ATTEMPING,
  SEND_SMS_FAILED,
  SEND_SMS_SUCCESS,
  GET_CHAT_MESSAGES_STUDENT_ATTEMPING,
  GET_CHAT_MESSAGES_STUDENT_FAILED,
  GET_CHAT_MESSAGES_STUDENT_SUCCESS,
  SEND_EMAIL_ATTEMPING,
  SEND_EMAIL_FAILED,
  SEND_EMAIL_SUCCESS
} from "./Types";
import axios from "axios";

export const sendEmail = (body) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_EMAIL_ATTEMPING
    });
    var data = JSON.stringify({
      subject: body.subject,
      to: body.to,
      order: body.order,
      title: body.title,
      description: body.description,
      fileUrl: body.fileUrl,
      fileName: body.fileName,
    });

    var config = {
      method: "post",
      url: `https://us-central1-paper-writer-f57a6.cloudfunctions.net/app/sendEmail`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log('response',response);
        
        if (response.data) {
          dispatch({
            type: SEND_EMAIL_SUCCESS
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: SEND_EMAIL_FAILED
        });
      });
  };
};

export const sendPushNotification = (title, body, expoPushToken, details) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_SMS_ATTEMPING
    });

    const data = JSON.stringify({
      to: expoPushToken,
      sound: "default",
      title: title,
      body: body,
      data: { orderId: details?.id },
    });

    var config = {
      url: "https://us-central1-paper-writer-f57a6.cloudfunctions.net/app/pushNotification",
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    console.log("config", config);
    axios(config)
      .then(function (response) {
        console.log("response", response);

        dispatch({
          type: SEND_SMS_SUCCESS
        });
      })
      .catch(function (error) {
        console.log("response", error);
        dispatch({
          type: SEND_SMS_FAILED
        });
      });
  };
};

export const sendSms = (body) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_SMS_ATTEMPING
    });
    var data = JSON.stringify({
      body: body,
      phone: "+17708105633"
    });

    var config = {
      method: "post",
      url: `https://us-central1-paper-writer-f57a6.cloudfunctions.net/app/sms`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: SEND_SMS_SUCCESS
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: SEND_SMS_FAILED
        });
      });
  };
};

export const deleteChatMessage = (ref, orderId, messageId) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CHAT_MESSAGE_ATTEMPING
    });
    try {
      firebase
        .database()
        .ref("dashboard/orders/" + orderId + ref + messageId)
        .remove()
        .then((response) => {
          dispatch({
            type: DELETE_CHAT_MESSAGE_SUCCESS
          });
        });
    } catch (error) {
      dispatch({ type: DELETE_CHAT_MESSAGE_FAILED });
    }
  };
};

export const setChatMessagesSeen = (ref, role, messages) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_CHAT_MESSAGE_SEEN_ATTEMPING
    });
    try {
      messages?.map((item, index) => {
        if (item.orderId) {
          if (role == "writer") {
            firebase
              .database()
              .ref("dashboard/orders/" + item.orderId + ref + item.id)
              .update({
                seenWriter: true
              });
          } else if (role == "admin") {
            firebase
              .database()
              .ref("dashboard/orders/" + item.orderId + ref + item.id)
              .update({
                seenAdmin: true
              });
          } else if (role == "manager") {
            firebase
              .database()
              .ref("dashboard/orders/" + item.orderId + ref + item.id)
              .update({
                seenManager: true
              });
          } else if (role == "student") {
            firebase
              .database()
              .ref("dashboard/orders/" + item.orderId + ref + item.id)
              .update({
                seenStudent: true
              });
          }
        }
      });
    } catch (error) {
      dispatch({ type: CHANGE_CHAT_MESSAGE_SEEN_FAILED });
    }
  };
};

export const getChatMessagesStudent = (ref, orderId, role, callBack) => {
  console.log("messagesmessagesmessagesmessages", ref, orderId, role);
  return async (dispatch) => {
    dispatch({
      type: GET_CHAT_MESSAGES_STUDENT_ATTEMPING
    });
    let unSeenCount = 0;
    try {
      firebase
        .database()
        .ref("dashboard/orders/" + orderId + ref)
        .on("value", (snapshot) => {
          const messages = [];

          snapshot.forEach((value) => {
            let message = value.val();
            message.id = value.key;
            if (message.orderId) {
              messages.push(message);

              if (role == "admin" && message.seenAdmin == false) {
                unSeenCount += 1;
              }
              if (role == "manager" && message.seenManager == false) {
                unSeenCount += 1;
              }
              if (role == "writer" && message.seenWriter == false) {
                unSeenCount += 1;
              }
              if (role == "student" && message.seenStudent == false) {
                unSeenCount += 1;
              }
            }
            
          });

          if (messages) {
            callBack(messages);
            dispatch({
              type: GET_CHAT_MESSAGES_STUDENT_SUCCESS,
              payload: messages,
              payload2: unSeenCount
            });
          }
        });
    } catch (error) {
      dispatch({ type: GET_CHAT_MESSAGES_STUDENT_FAILED });
    }
  };
};

export const getChatMessages = (ref, orderId, role, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CHAT_MESSAGES_ATTEMPING
    });
    let unSeenCount = 0;
    try {
      firebase
        .database()
        .ref("dashboard/orders/" + orderId + ref)
        .on("value", (snapshot) => {
          const messages = [];

          snapshot.forEach((value) => {
            let message = value.val();
            message.id = value.key;
            if (message.orderId) {
              messages.push(message);

              if (role == "admin" && message.seenAdmin == false) {
                unSeenCount += 1;
              }
              if (role == "manager" && message.seenManager == false) {
                unSeenCount += 1;
              }
              if (role == "writer" && message.seenWriter == false) {
                unSeenCount += 1;
              }

              if (role == "student" && message.seenStudent == false) {
                unSeenCount += 1;
              }
            }
            if (messages) {
              callBack(unSeenCount);
              dispatch({
                type: GET_CHAT_MESSAGES_SUCCESS,
                payload: messages,
                payload2: unSeenCount
              });
            }
          });
        });
    } catch (error) {
      dispatch({ type: GET_CHAT_MESSAGES_FAILED });
    }
  };
};

export const changeChatMessageStatus = (
  ref,
  orderId,
  messageId,
  title,
  status,
  callBack
) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_CHAT_MESSAGE_STATUS_ATTEMPING
    });

    firebase
      .database()
      .ref("dashboard/orders/" + orderId + ref + messageId)
      .update({
        status,
        title
      })
      .then(async (reponse) => {
        callBack(true);

        dispatch({
          type: CHANGE_CHAT_MESSAGE_STATUS_SUCCESS,
          payload: true
        });
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_CHAT_MESSAGE_STATUS_FAILED,
          payload: err.message
        });
      });
  };
};

export const createChatMessage = (
  ref,
  orderId,
  senderName,
  senderEmail,
  senderRole,
  title,
  status,
  message,
  file,
  date,
  callBack
) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_CHAT_MESSAGE_ATTEMPING
    });

    firebase
      .database()
      .ref("dashboard/orders/" + orderId + ref)
      .push({
        orderId,
        senderName,
        senderEmail,
        senderRole,
        title,
        status,
        message,
        file,
        seenAdmin: senderRole == "admin" ? true : false,
        seenManager: senderRole == "manager" ? true : false,
        seenWriter: senderRole == "writer" ? true : false,
        seenStudent: senderRole == "student" ? true : false,
        date
      })
      .then(async (reponse) => {
        firebase
          .database()
          .ref("dashboard/orders/" + orderId + "/chat/" + reponse.key)
          .update({
            id: reponse.key
          });
        callBack(true);

        dispatch({
          type: CREATE_CHAT_MESSAGE_SUCCESS,
          payload: true
        });
      })
      .catch((err) => {
        dispatch({ type: CREATE_CHAT_MESSAGE_FAILED, payload: err.message });
      });
  };
};

const makeid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
