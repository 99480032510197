import {

    GET__ORDER_COUPON_ATTEMPING,
    GET__ORDER_COUPON_FAILED,
    GET__ORDER_COUPON_SUCCESS,

} from '../actions/Types';

const INITIAL_STATE = {
    loading: false, error: null, coupon: null
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET__ORDER_COUPON_ATTEMPING:
            return {  loading: true }

        case GET__ORDER_COUPON_SUCCESS:
            return {  loading: false, coupon: action.payload,error:null, }

        case GET__ORDER_COUPON_FAILED:
            return {  loading: false, error: action.payload, }

        default: return state;

    }

};
