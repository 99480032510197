import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../../Utils/Colors";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#c3c3c322",
    display: "flex",
    width: "100%",
    height: "100%",
   
    minHeight: "100vh",
    minWidth:window.innerWidth-210,
    padding:"30px",
    flexDirection:'column'
  },
  container2: {
    background: Colors.white,
    overflow: "hidden",
    position: "relative",
    textTransform: "none",
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px 2px #c7c7c755",
    margin: "10px",
    flexDirection:'row'
  },
  backButton:{
    justifyContent:"flex-start",
    width:"80px"

  },
  noOrders: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    marginLeft: "5px",
    textAlign:'center',
    alignSelf:'center',
    width:"100%"
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  id:{
    color: Colors.darkGrey,
    fontFamily: "Spartan",
    fontSize: "14px",
    marginTop: "10px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  search: {
    fontFamily: "Spartan",
    marginLeft: "15px",

  },
  progress:{
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  name: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.dark,
  },
  value: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.black,
    marginLeft: "5px",
    width: "600px",
  },

  paymentStatus: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
   
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  Paypal: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
  },
  email: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "14px",
    marginTop: "5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {},
  },
  more: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginTop: "5px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {},
  },
  price: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  orderStatus: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  date: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  idOrder: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "25px",
    marginLeft:"15px",
    marginTop:"15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px",
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan",
  },
}));

export default useStyles;
