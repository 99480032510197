import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Button, Checkbox } from "@material-ui/core";
import {
  Switch,
  BrowserRouter,
  Route,
  useParams,
  useHistory,
} from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import useStyles from "./Style.js";
import OrderItem from "../../../../Components/OrderItem";
import { connect } from "react-redux";
import { getOrders, getProducts, getWriters,getChatMessages ,getWriterOrders} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Search from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import AsyncStorage from "@callstack/async-storage";

import { motion } from "framer-motion";
import OrderDetails from "../OrderDetails/View";
import OrderChat from "../OrderChat/View";
import ArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeft";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const WriterOrders = ({
  getOrders,
  getProducts,
  orders,
  products,
  loading,
  getWriters,
  writers,
  role,
  getChatMessages,
  getWriterOrders,
  selectedNotification,
  selectedWriter,
  onClickBack
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showOrders, setShowOrders] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [fulfillmentStatus, setSelectedfulfillmentStatus] = useState("");
  const [orderList, setOrderList] = useState();
 
  let [sliceFrom, setSliceFrom] = useState(0);
  const [sliceTo, setSliceTo] = useState(5);
  const [page, setPage] = useState(1);


  const FulfillmentStatusList = [
    "DELIVERED",
    "PROCESSING",
    "AWAITING_PROCESSING",
    "SHIPPED",
    "OUT_FOR_DELIVERY",
    "READY_FOR_PICKUP",
    "WILL_NOT_DELIVER",
    "RETURNED",
  ];

  useEffect(() => {
    setOrderList(orders)
  }, [orders]);

  useEffect(async() => {
    let email = null;

    if (role=='writer') {
       email = await AsyncStorage.getItem("email");

    }else {
      email=selectedWriter.email
    }

    getWriterOrders(email, "", "", "");
    getProducts();

    if (global.selectedNotification) {
      setSelectedOrder({ id: global.selectedNotification.orderId });
      setShowOrders(2);
      global.selectedNotification=null
    }

    if (global.order) {
      setSelectedOrder(global.order);
      setShowOrders(1);
      global.order=null
    }


  }, []);
  
  useEffect(() => {
    if (selectedNotification) {
      setSelectedOrder({ id: selectedNotification.orderId });
      setShowOrders(2);
      global.selectedNotification=null
    }
  }, [selectedNotification]);


  useEffect(async() => {
    let email = null;

    if (role=='writer') {
       email = await AsyncStorage.getItem("email");

    }else {
      email=selectedWriter.email
    }
    getWriterOrders(email, fulfillmentStatus, selectedProduct, searchQuery);
  }, [selectedProduct, searchQuery,fulfillmentStatus]);

  return showOrders==0 ? (
    <div className={styles.container} container>
        {role!='writer'&& <Button onClick={onClickBack} className={styles.backButton}>
        <ArrowLeftOutlined className={styles.more} />

        <Typography className={styles.more}>Back</Typography>
      </Button>}

      {role!='writer'&&<Typography className={styles.idOrder}>
      {selectedWriter?.name}  Orders
      </Typography>}

      <Grid direction="row" container>
        <Grid item>
          <Autocomplete
            style={{ height: "45px" }}
            id="country-select-demo"
            sx={{ width: 250, height: 45 }}
            options={products}
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSelectedProduct(newValue?.id ? newValue?.id : "");
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={option.imageUrl}
                  srcSet={option.imageUrl}
                  alt=""
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Orders By Product"
                color="primary"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>

        <Grid item>
          <Autocomplete
            style={{ height: "45px", marginLeft: "15px" }}
            id="country-select-demo"
            sx={{ width: 250, height: 45 }}
            options={FulfillmentStatusList}
            autoHighlight
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSelectedfulfillmentStatus(newValue ? newValue : "");
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Fulfillment Status"
                color="primary"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>
        <Grid className={styles.search} item>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            color="primary"
            onChange={(event) => {
              setSearchQuery(event.target.value ? event.target.value : "");
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <Search color={Colors.blue} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {loading ? (
        <Box className={styles.progress}>
          <CircularProgress
            style={{ alignSelf: "center", color: Colors.blue }}
          />
        </Box>
      ) : orderList?.length > 0 ? (
        <div>
       { orderList?.slice(sliceFrom, sliceTo)?.map((order, index) => {
          let unSeenCount=0
         
          return (
            <OrderItem
              key={index + ""}
              order={order}
              role={role}
              unSeenCount={unSeenCount}
              openChat={()=>{
                setSelectedOrder(order);
                setShowOrders(2);
              }}
              onClick={() => {
                setSelectedOrder(order);
                setShowOrders(1);
              }}
            />
          );
        })}
        <Stack>
        <Pagination
          onChange={(event, value) => {
            setPage(value);
            if (value==1) {
              setSliceFrom(0);
            setSliceTo( 5);
            } else {
              setSliceFrom((value - 1) * 5 );
            setSliceTo(value * 5);
            }
          }}
          page={page}
          style={{ alignSelf: "center", marginTop: "20px" }}
          count={Math.ceil(orderList?.length / 5)}
          color="primary"
        />
      </Stack>
        </div>
      ) : (
        <Box className={styles.progress}>
          <Typography style={{ alignSelf: "center", textAlign: "center" }}>
            No Orders Yet
          </Typography>
        </Box>
      )}
    </div>
  ) : (showOrders==1?
    <OrderDetails
      selectedOrder={selectedOrder}
      role={role}
      onClick={() => setShowOrders(0)}
      openChat={() => setShowOrders(2)}

    />:
    <OrderChat
      selectedOrder={selectedOrder}
      role={role}
      onClick={() => setShowOrders(0)}
    />
  );
};

const mapStateToProps = ({ orders, products, writer,chat }) => {
  return {
    loading: writer.loadingGetWritersOrders,
    error: orders.error,
    orders: writer.writerOrders,
    orderStats: orders.orderStats,
    products: products.products,
    writers: writer.writers,
    unSeenCount: chat.unSeenCount,

  };
};

export default connect(mapStateToProps, { getOrders, getChatMessages,getProducts, getWriters,getWriterOrders })(
  WriterOrders
);
