import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors.js";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {} from "../../../../Redux/actions/index.js";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      style={{ backgroundColor: Colors.blue }}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon style={{ color: Colors.white }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const CreateWriterPayment = ({
  loadingAddWriterPayment,

  SavePayment,
  handleClose,
  open
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={styles.BootstrapDialog}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{
            fontSize: "13px",
            fontFamily: "Spartan",
            fontWeight: "bold",
            color: Colors.white
          }}
        >
          Payment
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <iframe
          style={{ width: "550px" }}
          onChange={(e) => {
            console.log("eeeeeeeeeeee", e);
          }}
          className={styles.iframe}
          src="https://us-central1-paper-writer-f57a6.cloudfunctions.net/app/paypal/paypal?price=0.01&item=order&quantity=12&name=name&description=10"
          title="Payment"
        ></iframe>
      </DialogContent>
    </BootstrapDialog>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(CreateWriterPayment);
