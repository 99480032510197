import {
 GET_STUDENTS_FAILED,
 GET_STUDENTS_ATTEMPING,
 GET_STUDENTS_SUCCESS
} from "../actions/Types";

const INITIAL_STATE = {
  loadingGetStudents: false,
  errorGetStudents: false,
  students: null,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_STUDENTS_ATTEMPING:
      return {...state, loadingGetStudents: true, errorGetStudents: null };

    case GET_STUDENTS_FAILED:
      return {...state, loadingGetStudents: false, errorGetStudents: action.payload };

    case GET_STUDENTS_SUCCESS:
      return { ...state, loadingGetStudents: false,students:action.payload  };


    default:
      return state;
  }
};
