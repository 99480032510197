import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Copy from "@mui/icons-material/ContentCopy";
import Watch from "@mui/icons-material/TimerRounded";
import Chat from "@mui/icons-material/Chat";
import Avatar from "@mui/material/Avatar";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";

const SunscriptionItem = ({
  subscription,
  onClick,
  role,
  openChat,
  cancelButtonAction
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();
  const [pagesLeft, setPagesLeft] = useState(0);

  useEffect(() => {
    let result = 10;
    subscription?.orderTemplate?.items?.map((Item) => {
      Item.selectedOptions?.map((option) => {
        if (option?.name == "Page Count") {
          result = result - parseInt(option.value);
        }
      });
    });
    setPagesLeft(result);
  }, []);
  return (
    <Grid
      component={motion.div}
      whileHover={{
        backgroundColor: Colors.dark + "10",
        transition: { duration: 0.7 },
        scale: 1.01,
        borderColor: Colors.blue + "88",
        borderWidth: "1px"
      }}
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Grid container direction="row" style={{ marginTop: "7px" }}>
        <Typography
          className={styles.id}
          style={{
            color: subscription?.status == "ACTIVE" ? Colors.green : Colors.red
          }}
        >
          {subscription?.status}
        </Typography>
        <Watch style={{ color: Colors.blue }} />
        <Typography className={styles.date}>
          Next charge on{" "}
          {new Date(
            new Date(subscription?.nextCharge).toLocaleString("en-US", {
              timeZone: "America/New_York"
            })
          )
            .toString()
            .substr(0, 15)}
        </Typography>
      </Grid>

      <Grid container direction="row" style={{ marginTop: "7px" }}>
        <Copy style={{ color: Colors.blue, fontSize: "16px" }} />
        <Typography className={styles.id} style={{ color: Colors.blue }}>
          {subscription?.orderTemplate?.items?.length > 0
            ? subscription?.orderTemplate?.items?.length
            : 0}{" "}
          {" Order Created"}
        </Typography>
        <Typography className={styles.date}>
          Started{" "}
          {new Date(
            new Date(subscription?.created).toLocaleString("en-US", {
              timeZone: "America/New_York"
            })
          )
            .toString()
            .substr(0, 15)}
        </Typography>
      </Grid>

      {subscription?.status == "ACTIVE" ? (
        <Button 
        component={motion.button}
        whileHover={{
          backgroundColor: Colors.blue,
          transition: { duration: 0.3 }
        }}
        onClick={cancelButtonAction} className={styles.cancelButton}>
          <Typography>Cancel Subscription</Typography>
        </Button>
      ) : null}

      <Typography className={styles.price}>
        ${subscription?.orderTemplate.total} per month
      </Typography>
      {subscription?.orderTemplate?.items?.map((Item) => {
        return (
          <Grid style={{ marginTop: "10px" }} container direction="row">
            <Avatar variant="rounded" src={Item.imageUrl} />
            <Grid item style={{ marginLeft: "10px" }}>
              <Grid container direction="row">
                <Typography className={styles.orderStatus}>
                  {Item?.name}
                </Typography>

                <Typography
                  className={styles.orderStatus}
                  style={{
                    marginLeft: "20px",
                    fontSize: "11px",
                    color: Colors.grey
                  }}
                >
                  {Item?.sku}
                </Typography>
              </Grid>

              {Item.selectedOptions?.map((option) => {
                if (!String(option.name).includes("Upload")) {
                  return (
                    <Grid
                      container
                      direction="row"
                      key={JSON.stringify(option)}
                      style={{ marginTop: "4px" }}
                    >
                      <Typography className={styles.name}>
                        {option.name} :{" "}
                      </Typography>
                      <Typography className={styles.value}>
                        {String(option.value).length < 50
                          ? option.value
                          : String(option.value).substr(0, 200) + "..."}
                      </Typography>
                    </Grid>
                  );
                }
              })}
              <Grid container direction="row" style={{ marginTop: "4px" }}>
                <Typography className={styles.name}>
                  {"x " + Item.quantity}
                </Typography>
                <Typography
                  className={styles.value}
                  style={{ fontWeight: "bold" }}
                >
                  {"$" + Item.price}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      {subscription?.selectedOptions?.orderComments && (
        <Grid style={{ marginTop: "15px" }} container direction="row">
          <Typography className={styles.name}>Order Comment: </Typography>
          <Typography className={styles.value}>
            {subscription?.orderComments}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    background: Colors.white,
    position: "relative",
    textTransform: "none",
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "2px 2px 2px 2px #c7c7c755",
    margin: "10px",
    border: "1px solid #30374500",
    width: window.innerWidth - 280
  },

  inputContent: {
    width: "30%",
    height: "100%",
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "0vh"
    }
  },
  name: {
    fontSize: "11px",
    fontFamily: "Spartan",
    color: Colors.black,
    width: "220px",
    fontWeight: "bold"
  },
  value: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.black,
    marginLeft: "5px",
    width: "600px"
  },

  paymentStatus: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  Paypal: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px"
  },
  email: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "12px",
    marginTop: "5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  chat: {
    border: "1px solid " + Colors.blue,
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    height: "35px",
    color: Colors.blue,

    borderRadius: "20px",
    textTransform: "none",
    position: "absolute",
    right: "110px",
    top: "10px"
  },
  ChatText: {
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold"
  },
  ChatHead: {
    padding: "2px",
    borderRadius: "7px",
    backgroundColor: Colors.red,
    color: Colors.white,
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold",
    marginLeft: "3px",
    textAlign: "center",
    justifyContent: "center",
    minWidth: "15px"
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    height: "35px",
    borderRadius: "20px",
    color: Colors.blue,
    textTransform: "none",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {}
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {}
  },
  cancelButton: {
    width: "210px",
    height: "40px",
    backgroundColor: Colors.black,
    fontFamily: "Spartan",
    fontSize: "20px",
    color: Colors.white,
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    borderRadius: "20px",
    right: "20px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {}
  },
  price: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "20px",
    bottom: "10px",
    [theme.breakpoints.down("sm")]: {}
  },
  orderStatus: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },

  date: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "center",
    marginTop: "2px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  id: {
    color: Colors.darkGrey,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px"
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan"
  }
}));

const mapStateToProps = ({ chat }) => {
  return {
    loadingGetchatMessages: chat.loadingGetchatMessages
    //unSeenCount: chat.unSeenCount,
  };
};

export default connect(mapStateToProps, {})(SunscriptionItem);
