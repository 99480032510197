import {
  GET_PRDUCTS_ATTEMPING,
  GET_PRDUCTS_FAILED,
  GET_PRDUCTS_SUCCESS,
} from "./Types";
import axios from "axios";
import { ServiceURL } from "../../global/services/config";

export const getProducts = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_PRDUCTS_ATTEMPING,
    });

    try {
      var config = {
        method: "get",
        url: ServiceURL.Products,
        headers: ServiceURL.headers,
      };
      const { data } = await axios(config);
      let items= data.items.filter((item)=>{return item.enabled==true&& item})
      dispatch({ type: GET_PRDUCTS_SUCCESS, payload: items });
    } catch (error) {
      dispatch({
        type: GET_PRDUCTS_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};
