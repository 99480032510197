import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Button, Checkbox } from "@material-ui/core";
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import login_back from "../../../assets/images/auth/student.png";
import logo from "../../../assets/global/logo.png";
import useStyles from "./Style.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "../../../Components/Input";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { connect } from "react-redux";
import { sendResetPassword } from "../../../Redux/actions";
import AsyncStorage from "@callstack/async-storage";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const StudentForgotPassword = ({ sendResetPassword, resetDone,
  loading, }) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const initailFormState = {
    email: null,
    password: null,
    isRemember: false
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);
  const [error, setError] = useState(null);


  useEffect(async () => {
    const loggedIn = await AsyncStorage.getItem("loggedIn");

    if (loggedIn == true) {
      history.push("/dashboard/home");
    }
  }, []);

  

  const  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const resetAction = async () => {
    if (!form.email) {
      setError("Please enter email");
    } else if (!validateEmail(String(form.email).trim())) {
      setError("Please enter valid email");
    } else {
      sendResetPassword(String(form.email).trim(),()=>{
        history.push("/student/forgot_password_success");

      });
    }
  };
  return (
    <Grid
      direction={matches ? "column" : "row"}
      container
      justify="flex-start"
      alignItems="flex-start"
      className={styles.container}
    >
      <div className={styles.imgDev}>
        <img src={login_back} className={styles.img} />
        <Grid
          direction="row"
          container
          justify="center"
          alignItems="center"
          className={styles.logoView}
        >
          <Grid item>
            <img src={logo} className={styles.logo} />
          </Grid>
          <Grid>
            <Typography className={styles.appName}>A+ Paper Writer</Typography>
          </Grid>
        </Grid>
      </div>
      <Grid
        direction="column"
        container
        justify="flex-start"
        alignItems="flex-start"
        className={styles.inputContent}
      >
        <img src={logo} className={styles.logo2} />

        <Typography className={styles.welcomeText}>
        Did you forget your password ?
        </Typography>
        <Typography className={styles.loginText}>
        Enter your email address you`re using for your account below and we
          will send you a password reset link.{" "}
        </Typography>
        <Input
          className={styles.inputEmail}
          label={t("Email")}
          type="email"
          onChange={(event) => {
            updateForm({ key: "email", value: event.target.value });
          }}
        />

        {error && (
          <Alert className={styles.alert} severity="error">
            <Typography style={{ fontSize: "13px", fontFamily: "Spartan" }}>
              {error}
            </Typography>
          </Alert>
        )}

        <Button
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.green,
            transition: { duration: 0.3 }
          }}
          className={styles.SigninButton}
          onClick={resetAction}
        >
          {loading ? (
            <CircularProgress
              style={{ alignSelf: "center", color: Colors.white }}
            />
          ) : (
            <Typography className={styles.signinText}>Send</Typography>
          )}
        </Button>

      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ users }) => {
  return {
    resetDone: users.sent,
    loading: users.loadingResetPassword,
    error: users.errorResetPassword,  };
};

export default connect(mapStateToProps, { sendResetPassword })(StudentForgotPassword);
