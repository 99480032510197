import { combineReducers } from 'redux';
import Products from './Products'
import UsersReducer from './UsersReducer'
import Home from './Home'
import Orders from './Orders'
import Profile from './Profile'
import Coupon from './Coupons'
import Writer from './Writer'
import Chat from './Chat'
import Notifications from './Notifications'
import Ledger from './Ledger'
import Notes from './Notes'
import Subscriptions from './Subscriptions'
import ChatStudents from './ChatStudents'
import Students from './Students'


export default combineReducers({
    products: Products,
    users:UsersReducer,
    home:Home,
    orders:Orders,
    profile:Profile,
    coupon:Coupon,
    writer:Writer,
    chat:Chat,
    notifications:Notifications,
    ledger:Ledger,
    notes:Notes,
    Subscriptions:Subscriptions,
    ChatStudents:ChatStudents,
    students:Students
   
})