import firebase from "../../global/services/Firebase";
import AsyncStorage from "@callstack/async-storage";
import {
  CREATE_NOTIFICATION_ATTEMPING,
  CREATE_NOTIFICATION_FAILED,
  CREATE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATIONS_ATTEMPING,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_SUCCESS,
  CHANGE_NOTIFICATIONS_STATUS_ATTEMPING,
  CHANGE_NOTIFICATIONS_STATUS_FAILED,
  CHANGE_NOTIFICATIONS_STATUS_SUCCESS,

  DELETE_NOTIFICATION_ATTEMPING ,
  DELETE_NOTIFICATION_FAILED,
  DELETE_NOTIFICATION_SUCCESS

} from "./Types";


export const deleteNotification = () => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_NOTIFICATION_ATTEMPING,
    });

    firebase
      .database()
      .ref("dashboard/notifications/" )
      .remove()
      .then(async (reponse) => {
        dispatch({
          type: DELETE_NOTIFICATION_SUCCESS,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_NOTIFICATION_FAILED,
          payload: err.message,
        });
      });
  };
};

export const changeNotificationStatus = (userId, notificationId, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_NOTIFICATIONS_STATUS_ATTEMPING,
    });

    firebase
      .database()
      .ref("dashboard/notifications/" + userId + "/" + notificationId)
      .update({
        status: "read",
      })
      .then(async (reponse) => {
        callBack(true);
        dispatch({
          type: CHANGE_NOTIFICATIONS_STATUS_SUCCESS,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_NOTIFICATIONS_STATUS_FAILED,
          payload: err.message,
        });
      });
  };
};

export const getNotifications = (id, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: GET_NOTIFICATIONS_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/notifications/" + id)
        .on("value", (snapshot) => {
          const notifications = [];

          snapshot.forEach((value) => {
            let notification = value.val();
            notification.id = value.key;
            notifications.push(notification);
          });
          if (notifications) {
            callBack(notifications.reverse());
            dispatch({
              type: GET_NOTIFICATIONS_SUCCESS,
              payload: notifications,
            });
          }
        });
    } catch (error) {
      dispatch({ type: GET_NOTIFICATIONS_FAILED });
    }
  };
};

export const createNotificationFromWriter = (
  orderId,
  senderName,
  senderEmail,
  senderRole,
  privateAdminNotes,
  title,
  message,
  file,
  date
) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_NOTIFICATION_ATTEMPING,
    });

    firebase
      .database()
      .ref("dashboard/users")
      .once("value", (snapshot) => {
        snapshot.forEach((value) => {
          let user = value.val();
          if (user.role == "admin" || user.role == "manager") {
            firebase
              .database()
              .ref("dashboard/notifications/" + user.id)
              .push({
                orderId,
                senderName,
                senderEmail,
                senderRole,
                title,
                status: "unread",
                message,
                file,
                date,
                privateAdminNotes,
              })

              .catch((err) => {
                dispatch({
                  type: CREATE_NOTIFICATION_FAILED,
                  payload: err.message,
                });
              });
          }
        });
      });
  };
};

export const createNotificationToWriter = (
  orderId,
  senderName,
  senderEmail,
  senderRole,
  privateAdminNotes,
  writerEmail,
  title,
  message,
  file,
  date
) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_NOTIFICATION_ATTEMPING,
    });

    firebase
      .database()
      .ref("dashboard/users")
      .once("value", (snapshot) => {
        let x=0
        snapshot.forEach((value) => {
          let user = value.val();

          if (user.email == writerEmail&&x==0) {
            firebase
              .database()
              .ref("dashboard/notifications/" + user.id)
              .push({
                orderId,
                senderName,
                senderEmail,
                senderRole,
                title,
                status: "unread",
                message,
                file,
                date,
                privateAdminNotes,
              })
              .catch((err) => {
                dispatch({
                  type: CREATE_NOTIFICATION_FAILED,
                  payload: err.message,
                });
              });
              x=1
          }
        });
      });
  };
};
