import {
    GET_HOME_PAGE_ATTEMPING,
    GET_HOME_PAGE_FAILED ,
    GET_HOME_PAGE_SUCCESS 
} from '../actions/Types';

const INITIAL_STATE = {
    loading: false, error: null, homePage: null
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_HOME_PAGE_ATTEMPING:
            return {  loading: true }

        case GET_HOME_PAGE_SUCCESS:
            return {  loading: false, homePage: action.payload, }

        case GET_HOME_PAGE_FAILED:
            return {  loading: false, error: action.payload, }

        default: return state;

    }

};
