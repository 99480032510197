import React, { Component } from 'react';
import { Button, TextField, Container, Grid, } from '@material-ui/core';
import { Height,  } from '@material-ui/icons';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import Header from '../src/Screens/Header/View'
import { theme} from '../src/Screens/Theme'
import { BrowserRouter, Route ,Switch} from 'react-router-dom';
import Colors from './Utils/Colors';
import Error404 from './Screens/404Error/View';
import Login from './Screens/Auth/Login/View';
import StudentLogin from './Screens/Auth/StudentLogin/View';
import StudentSignup from './Screens/Auth/StudentSignup/View';
import StudentForgotPassword from './Screens/Auth/StudentForgotPassword/View';
import StudentForgotPasswordSuccess from './Screens/Auth/StudentForgotPasswordSuccess/View';
import Admin from './Screens/Dashboard/Admin/View';
import {Provider} from 'react-redux'
import {store} from '../src/Redux/store';

const App = () => {

  const styles = useStyles()
  return (
    <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>

     { /*<Header />*/}
      <Switch>
      <Route exact path="/" component={()=><Login/>}/>
      <Route exact path="/login" component={()=><Login/>}/>
      <Route exact path="/student" component={()=><StudentLogin/>}/>
      <Route exact path="/student/login" component={()=><StudentLogin/>}/>
      <Route exact path="/student/forgot_password" component={()=><StudentForgotPassword/>}/>
      <Route exact path="/student/forgot_password_success" component={()=><StudentForgotPasswordSuccess/>}/>
      <Route exact path="/student/signup" component={()=><StudentSignup/>}/>
      <Route exact path="/dashboard" component={()=><Admin/>}/>
      <Route exact path="/dashboard/order-management" component={()=><Admin/>}/>
      <Route exact path="/dashboard/writers-list" component={()=><Admin/>}/>
      <Route exact path="/dashboard/orders" component={()=><Admin/>}/>
      <Route exact path="/dashboard/students" component={()=><Admin/>}/>
      <Route exact path="/dashboard/ledger" component={()=><Admin/>}/>
      <Route exact path="/dashboard/writers" component={()=><Admin/>}/>
      <Route exact path="/dashboard/home" component={()=><Admin/>}/>
      <Route exact path="/dashboard/Notes" component={()=><Admin/>}/>
      <Route exact path="/dashboard/Courses" component={()=><Admin/>}/>
      <Route exact path="/dashboard/users-management" component={()=><Admin/>}/>

      
      <Route exact path="/notFound" component={()=><Error404/>}/>

      
      </Switch>
      </BrowserRouter>
    
    </ThemeProvider>
      </Provider>
  )

}

const useStyles = makeStyles({
  text: {
    color: Colors.white,
    fontFamily:"Lato",
    background:Colors.primaryColor,
    width:"100%"
  }
})


export default App;
