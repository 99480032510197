import React, {  } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {} from "../../../../Redux/actions";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      style={{ backgroundColor: Colors.blue }}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon style={{ color: Colors.white }} />
          {children}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const OrderMorePages = ({
  loadingAddWriterPayment,
  subscriptionStatus,
  pagesLeft,
  signup,
  handleClose,
  subscribeAction,
  open,
  children,
  isPaymentFree,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={styles.BootstrapDialog}
    >
      <BootstrapDialogTitle
        style={{ backgroundColor: Colors.blue }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        <Typography
          style={{
            fontSize: "13px",
            fontFamily: "Spartan",
            fontWeight: "bold",
            color: Colors.white
          }}
        >
          Order more pages
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {isPaymentFree && (
        <DialogActions>
          <Button
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.lightGrey,
              transition: { duration: 0.1 },
              color: Colors.white
            }}
            className={styles.save}
            autoFocus
            onClick={handleClose}
          >
            <Typography
              style={{
                fontSize: "15px",
                fontFamily: "Spartan",
                fontWeight: "bold",
                textTransform: "none",
                color: Colors.black
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.lightGrey,
              transition: { duration: 0.1 },
              color: Colors.white
            }}
            className={styles.save}
            autoFocus
            onClick={subscribeAction}
          >
            <Typography
              style={{
                fontSize: "15px",
                fontFamily: "Spartan",
                fontWeight: "bold",
                textTransform: "none",
                color: Colors.blue
              }}
            >
              Confirm
            </Typography>
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

const mapStateToProps = ({ writer }) => {
  return {};
};

export default connect(mapStateToProps, {})(OrderMorePages);
