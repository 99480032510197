import {
  CREATE_NOTE_ATTEMPING,
  CREATE_NOTE_FAILED,
  CREATE_NOTE_SUCCESS,
  GET_NOTES_ATTEMPING,
  GET_NOTES_FAILED,
  GET_NOTES_SUCCESS,
  DELETE_NOTE_ATTEMPING,
  DELETE_NOTE_FAILED,
  DELETE_NOTE_SUCCESS,
  SHARE_NOTE_ATTEMPING,
  SHARE_NOTE_FAILED,
  SHARE_NOTE_SUCCESS,
} from "./Types";
import AsyncStorage from "@callstack/async-storage";
import firebase from "../../global/services/Firebase";

export const shareNote = (
  id,
  date,
  shareToId,
  shareToName,
  shareToEmail,
  callBack
) => {
  return async (dispatch) => {
    dispatch({
      type: SHARE_NOTE_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/notes/" + id + "/shares/" + shareToId)
        .set({
          date,
          shareToId,
          shareToName,
          shareToEmail,
        })
        .then((response) => {
          callBack(true);
          dispatch({
            type: SHARE_NOTE_SUCCESS,
          });
        });
    } catch (error) {
      dispatch({ type: SHARE_NOTE_FAILED });
    }
  };
};

export const deleteNote = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_NOTE_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/notes/" + id)
        .remove()
        .then((response) => {
          dispatch({
            type: DELETE_NOTE_SUCCESS,
          });
        });
    } catch (error) {
      dispatch({ type: DELETE_NOTE_FAILED });
    }
  };
};

export const getNotes = (id, query, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: GET_NOTES_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/notes/")
        .on("value", (snapshot) => {
          const notes = [];
          snapshot.forEach((value) => {
            let note = value.val();

            if (note.userId == id) {
              note.sharedToMe = false;

              if (note?.shares) {
                Object.values(note?.shares).forEach(function (value) {
                  note.sharedNames += value?.shareToName + " / ";
                });
              }
              note.sharedNames = String(note.sharedNames).replace(
                "undefined",
                ""
              );

              if (
                String(note.reference).replace(" ","")
                  .toUpperCase()
                  .includes(String(query).toUpperCase().replace(" ","")) ||
                String(note.description).replace(" ","")
                  .toUpperCase()
                  .includes(String(query).toUpperCase().replace(" ","")) ||
                String(note.note).replace(" ","")
                  .toUpperCase()
                  .includes(String(query).toUpperCase().replace(" ","")) ||
                String(note.periority.name).replace(" ","")
                  .toUpperCase()
                  .includes(String(query).toUpperCase().replace(" ",""))
              ) {
                notes.push(note);
              }
            } else {
              if (note?.shares) {
                Object.values(note?.shares).forEach(function (value) {
                  note.sharedNames += value?.shareToName + " / ";

                  if (value?.shareToId == id) {
                    note.sharedToMe = true;
                  }
                });
              }
              note.sharedNames = String(note.sharedNames).replace(
                "undefined",
                ""
              );
              if (note.sharedToMe == true) {
                if (
                  String(note.reference).replace(" ","")
                    .toUpperCase()
                    .includes(String(query).toUpperCase().replace(" ","")) ||
                  String(note.description).replace(" ","")
                    .toUpperCase()
                    .includes(String(query).toUpperCase().replace(" ","")) ||
                  String(note.note).replace(" ","")
                    .toUpperCase()
                    .includes(String(query).toUpperCase().replace(" ","")) ||
                  String(note.periority.name).replace(" ","")
                    .toUpperCase()
                    .includes(String(query).toUpperCase().replace(" ",""))
                ) {
                  notes.push(note);
                }
              }
            }
          });
          if (notes) {
            callBack(notes.reverse());
            dispatch({
              type: GET_NOTES_SUCCESS,
              payload: notes,
            });
          }
        });
    } catch (error) {
      dispatch({ type: GET_NOTES_FAILED });
    }
  };
};

export const createNote = (
  userId,
  userName,
  userRole,
  userEmail,
  date,
  reference,
  description,
  note,
  periority,
  file,
  callBack
) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_NOTE_ATTEMPING,
    });
    try {
      firebase
        .database()
        .ref("dashboard/notes/")
        .push({
          userId,
          userName,
          userRole,
          userEmail,
          date,
          reference,
          description,
          note,
          periority,
          file,
        })
        .then((response) => {
          firebase
            .database()
            .ref("dashboard/notes/" + response.key)
            .update({
              id: response.key,
            });
          callBack(true);
          dispatch({
            type: CREATE_NOTE_SUCCESS,
          });
        });
    } catch (error) {
      dispatch({ type: CREATE_NOTE_FAILED });
    }
  };
};
