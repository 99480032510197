import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Payment from "@mui/icons-material/Payment";
import Delete from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";
import { Button } from "@material-ui/core";
import Popover from "@mui/material/Popover";

const OrderItem = ({
  order,
  onClick,
  status,
  date,
  senderInfo,
  role,
  file,
  message,
  title,
  needsWorkAction,
  markAsCompleteAction,
  deleteMessage,
  userInfo
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isMe = senderInfo?.role == userInfo?.role;
  return (
    <Grid
      className={styles.container}
      container
      direction="row"
      wrap="nowrap"
      alignItems="flex-end"
    >
      <Tooltip title={senderInfo?.name + " (" + senderInfo?.role + ")"}>
        <Avatar
          style={
            isMe
              ? { backgroundColor: Colors.blue }
              : { backgroundColor: Colors.grey }
          }
        >
          {String(senderInfo?.name || senderInfo?.senderEmail)
            .substr(0, 2)
            .toLocaleUpperCase()}
        </Avatar>
      </Tooltip>

      <Grid
        className={styles.container2}
        style={
          String(message).includes(
            "Hello, Additional pages have been requested for order #"
          )
            ? { background: Colors.green + "33" }
            : { background: Colors.white }
        }
      >
        {title && (
          <Typography
            className={styles.title}
            style={{
              color:
                status == "Needs Work" || status == "Canceled"
                  ? Colors.red
                  : Colors.green
            }}
          >
            {title}{" "}
          </Typography>
        )}
        {message && (
          <Typography className={styles.message}>{message} </Typography>
        )}

        {file && (
          <a download className={styles.link} target="_blank" href={file.url}>
            {file.name}
          </a>
        )}

        {/*status == "Delivered" &&userInfo?.role!='writer'&& (
          <Grid
            container
            direction="row"
            wrap="nowrap"
            style={{
              width: window.innerWidth - 280,
            }}
          >
            <Button
              component={motion.button}
              whileHover={{
                backgroundColor: Colors.black,
                transition: { duration: 0.3 },
                color: Colors.white,
              }}
              className={styles.needsWork}
              onClick={needsWorkAction}
            >
              Needs Work
            </Button>
            <Button
              component={motion.button}
              whileHover={{
                backgroundColor: Colors.black,
                transition: { duration: 0.3 },
                color: Colors.white,
              }}
              className={styles.markAsComplete}
              onClick={markAsCompleteAction}

            >
              Mark As Complete
            </Button>
          </Grid>
            )*/}

        <Typography className={styles.date}>{date}</Typography>
      </Grid>
      {senderInfo?.email == userInfo?.email && (
        <div
          style={{ width: "20px", fontWeight: "bold", marginLeft: "10px" }}
          onClick={handleClick}
        >
          <Typography
            style={{
              fontSize: "9px",
              color: Colors.dark,
              fontFamily: "Spartan"
            }}
          >
            Remove
          </Typography>
        </div>
      )}
      {senderInfo?.email == userInfo?.email && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <Button
            onClick={() => {
              handleClose();
              deleteMessage();
            }}
          >
            <Typography
              style={{
                fontSize: "11px",
                fontFamily: "Spartan",
                color: Colors.black,
                padding: "5px"
              }}
              sx={{ p: 2 }}
            >
              Confirm
            </Typography>
          </Button>
        </Popover>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    textTransform: "none",

    margin: "10px",
    border: "1px solid #30374500",
    width: window.innerWidth - 280
  },
  container2: {
    padding: "10px",
    background: Colors.white,
    borderTopLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    border: "1px solid #30374500",
    maxWidth: window.innerWidth - 350,
    marginLeft: "5px"
  },
  markAsComplete: {
    background: Colors.green,
    color: Colors.white,
    marginTop: "15px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px"
  },
  needsWork: {
    background: Colors.red,
    color: Colors.white,
    marginTop: "15px",
    marginRight: "5px",
    textTransform: "none",
    fontFamily: "Spartan",
    fontSize: "11px"
  },
  inputContent: {
    width: "30%",
    height: "100%",
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "0vh"
    }
  },
  name: {
    fontSize: "11px",
    fontFamily: "Spartan",
    color: Colors.black,
    width: "220px",
    fontWeight: "bold"
  },
  value: {
    fontSize: "12px",
    fontFamily: "Spartan",
    color: Colors.black,
    marginLeft: "5px",
    width: "600px"
  },
  date: {
    fontSize: "9px",
    fontFamily: "Spartan",
    color: Colors.grey,
    marginLeft: "10px",
    marginTop: "4px"
  },
  title: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    }
  },
  message: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontSize: "13px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    }
  },
  link: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px",
    marginLeft: "10px"
  },
  Paypal: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "11px"
  },
  email: {
    color: Colors.blue,
    fontFamily: "Spartan",
    fontSize: "12px",
    marginTop: "5px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  moreItem: {
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "15px",
    fontWeight: "bold",
    width: "90px",
    height: "35px",
    borderRadius: "20px",
    color: Colors.blue,
    textTransform: "none",
    position: "absolute",
    right: "10px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {}
  },
  more: {
    fontFamily: "Spartan",
    fontSize: "11px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {}
  },
  price: {
    color: Colors.green,
    fontFamily: "Spartan",
    fontSize: "20px",
    marginTop: "5px",
    fontWeight: "bold",
    position: "absolute",
    right: "20px",
    bottom: "10px",
    [theme.breakpoints.down("sm")]: {}
  },
  orderStatus: {
    color: Colors.black,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },

  id: {
    color: Colors.darkGrey,
    fontFamily: "Spartan",
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  loginText: {
    color: Colors.black,
    fontFamily: "Spartan",
    textAlign: "center",
    fontSize: "12px",
    marginTop: "10px",
    marginLeft: "50px",
    marginRight: "50px"
  },
  inputEmail: {
    width: "80%",
    height: "50px",
    marginTop: "20px",
    fontFamily: "Spartan"
  }
}));

export default OrderItem;
