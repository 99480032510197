import React, { useState, useEffect, useReducer } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  getWriters,
  createWriter,
  getWriterStats,
  getWriter,
  updatePassword,
  signup,
  createWriterPayment,
  deleteWriterPayment,
  getWriterPayment,
  editWriterPayment,
  getWriterOrders
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeft";
import { motion } from "framer-motion";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CreateWriterPassword from "./CreateWriterPassword";
import CreateWriterPayment from "./CreateWriterPayment";
import EditWriterPayment from "./EditWriterPayment";
import WriterOrders from "../WriterOrders/View";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const WriterDetails = ({
  createWriter,
  getWriters,
  selectedWriter,
  onClick,
  getWriterStats,
  stats,
  errorGetWritersStats,
  loadingGetWritersStats,
  loadingAddWriter,
  errorAddWriter,
  getWriter,
  updatePassword,
  signup,
  createWriterPayment,
  loadingAddWriterPayment,
  deleteWriterPayment,
  loadingGetWriterPayments,
  writerPayments,
  getWriterPayment,
  editWriterPayment,
  role,
  getWriterOrders,
  orders,
  openOrderDetails
}) => {
  const initailFormState = {
    name: null,
    email: null,
    password: null,
    confirmPassword: null,

    item: null,
    price: 0,
    date: new Date().toUTCString(),
    note: ""
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);

  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openPamentEdit, setOpenPamentEdit] = React.useState(false);
  const [openPament, setOpenPament] = React.useState(false);
  const [formErrors, setErrors] = useState([]);
  const [formErrorsPaymentEdit, setErrorsPaymentEdit] = useState([]);
  const [formErrorsPayment, setErrorsPayment] = useState([]);
  const [profile, setProfile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [payments, setPayments] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [earnedThisMonth, setEarnedThisMonth] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [totalDue, setTotalDue] = useState(0);
  const [showDetails, setShowDetails] = useState(true);
  let [sliceFrom, setSliceFrom] = useState(0);
  const [sliceTo, setSliceTo] = useState(7);
  const [page, setPage] = useState(1);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    apiData=apiData?.map(item=> {
      return {...item,date:new Date(
        new Date(item?.date).toLocaleString("en-US", {
          timeZone: "America/New_York"
        })
      )
        .toLocaleDateString()
        .substr(0, 24),}
    })
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePayment = () => {
    setOpenPament(false);
  };
  const handleClosePaymentEdit = () => {
    setOpenPamentEdit(false);
  };
  useEffect(() => {
    getWriterStats(selectedWriter.email);
    getWriterOrders(selectedWriter.email, "", "", "");
    getWriterProfile();
  }, []);

  useEffect(() => {
    if (profile) {
      getWriterPayment(profile?.id);
    }
  }, [profile]);

  useEffect(() => {
    var curr = new Date(); // get current date
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

    if(writerPayments){
      setPayments(writerPayments);
    }
    if (writerPayments && stats) {
      let total = 0.0;
      writerPayments?.map((payment) => {
        if (String(payment.item).toUpperCase().includes("PAYMENT")) {

          total += parseFloat(payment.price);
        }
      });
      setTotalPaid(total);
    }

    if (writerPayments && orders) {
      let total = 0.0;
      orders?.map((payment) => {
        if(new Date(payment.createDate)>=new Date("2024-11-5")){
        total += parseFloat(payment.total);
        }
      });

      writerPayments
      ?.map((payment) => {
        if(new Date(payment.createDate)>=new Date("2024-11-5")){
        total += parseFloat(payment.price);
        }
      });
      setTotalDue(total);
    }

    if (writerPayments && stats) {
      let earnedThisMonth = parseInt(stats?.weekTotalPaid);
      writerPayments?.map((payment) => {
        if (
          new Date(new Date(payment.date).toDateString()) >=
            new Date(new Date(firstday).toDateString()) &&
          new Date(new Date(payment.date).toDateString()) <=
            new Date(new Date(lastday).toDateString())
        ) {
          if (!String(payment.item).toUpperCase().includes("PAYMENT")) {
            earnedThisMonth += parseFloat(payment.price);
          }
        }
      });
      setEarnedThisMonth(earnedThisMonth);
    }
  }, [writerPayments, stats,orders]);

  const getWriterProfile = () => {
    getWriter(selectedWriter.email, (profile) => {
      setProfile(profile);
    });
  };

  const Item = (children, value, backgroundColor) => {
    return (
      <Grid
        component={motion.div}
        whileHover={{
          backgroundColor: Colors.dark + "10",
          transition: { duration: 0.7 },
          scale: 1.05,
          borderColor: Colors.blue + "88",
          borderWidth: "1px"
        }}
        className={styles.container2}
        container
        direction="column"
        style={{ backgroundColor: backgroundColor }}
      >
        {children}

        {loadingGetWritersStats ? (
          <CircularProgress
            size="20px"
            style={{ color: Colors.green, marginTop: "20px" }}
          />
        ) : (
          <Typography className={styles.number}>{value}</Typography>
        )}
      </Grid>
    );
  };
  const saveWriter = () => {
    let errors = [];

    if (!form.password) {
      errors.push("*Password is required.");
    }
    if (!form.confirmPassword) {
      errors.push("*Confirm Password is required.");
    }
    if (form.password != form.confirmPassword) {
      errors.push("*Password does not match.");
    }
    setErrors(errors);

    if (errors.length == 0) {
      if (profile?.password) {
        updatePassword(profile?.id, form?.password, (profile) => {
          handleClose();
        });
      } else {
        signup(
          selectedWriter.name,
          selectedWriter.email,
          form?.password,
          "writer",
          (profile) => {
           // handleClose();
          }
        );
      }
    }
  };

  const editPayment = () => {
    let errors = [];

    if (!form.itemEdit) {
      errors.push("*Item is required.");
    }
    if (!form.dateEdit) {
      errors.push("*Date is required.");
    }
    if (!form.priceEdit) {
      errors.push("*Price is required.");
    }

    setErrorsPaymentEdit(errors);

    if (errors.length == 0) {
      editWriterPayment(
        profile?.id,
        selectedPayment?.id,
        form?.itemEdit,
        form?.dateEdit,
        form?.priceEdit,
        form?.noteEdit,
        (profile) => {
          handleClosePaymentEdit();
          // setTimeout(() => {
          // getWriterProfile();
          //}, 2000);
          // alert("item is updated successfully!");
        }
      );
    }
  };

  const savePayment = () => {
    let errors = [];

    if (!form.item) {
      errors.push("*Item is required.");
    }
    if (!form.date) {
      errors.push("*Date is required.");
    }
    if (!form.price) {
      errors.push("*Price is required.");
    }

    setErrorsPayment(errors);

    if (errors.length == 0) {
      createWriterPayment(
        profile?.id,
        profile?.name,
        profile?.email,
        form?.item,
        form?.date,
        form?.price,
        form?.note,
        (profile) => {
          //getWriterProfile();
           //handleClosePayment();
          //  alert("item is added successfully!");
        }
      );
    }
  };

  return showDetails ? (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Button onClick={onClick} className={styles.backButton}>
        <ArrowLeftOutlined className={styles.more} />

        <Typography className={styles.more}>Back</Typography>
      </Button>

      <Typography className={styles.idOrder}>Profile</Typography>
      <Grid container direction="row">
        <Grid
          component={motion.div}
          whileHover={{
            backgroundColor: Colors.dark + "10",
            transition: { duration: 0.7 },
            scale: 1.05,
            borderColor: Colors.blue + "88",
            borderWidth: "1px"
          }}
          className={styles.container2}
          container
          direction="column"
        >
          <img
            variant="circular"
            className={styles.avatar}
            src={
              "https://firebasestorage.googleapis.com/v0/b/paper-writer-f57a6.appspot.com/o/Screen%20Shot%202022-06-05%20at%206.21.52%20PM.png?alt=media&token=8b8baa02-e770-449d-911c-6d2b3fd62e2a"
            }
          />

          <Typography className={styles.orderStatus}>
            {selectedWriter.name}
          </Typography>
        </Grid>
        <Grid
          component={motion.div}
          whileHover={{
            backgroundColor: Colors.dark + "10",
            transition: { duration: 0.7 },
            scale: 1.05,
            borderColor: Colors.blue + "88",
            borderWidth: "1px"
          }}
          className={styles.container2}
          container
          direction="column"
        >
          <img
            variant="circular"
            className={styles.avatar}
            src={
              "https://firebasestorage.googleapis.com/v0/b/paper-writer-f57a6.appspot.com/o/44-448460_email-clipart-grey-transparent-background-email-icons-png.png?alt=media&token=261506e5-de0e-4427-8dca-13ef28030c06"
            }
          />

          <Typography className={styles.orderStatus}>
            {selectedWriter.email.startsWith("add.task")
              ? String(selectedWriter.email).substr(0, 30) + ".."
              : selectedWriter.email}
          </Typography>
        </Grid>

        <Grid
          component={motion.div}
          whileHover={{
            backgroundColor: Colors.dark + "10",
            transition: { duration: 0.7 },
            scale: 1.05,
            borderColor: Colors.blue + "88",
            borderWidth: "1px"
          }}
          className={styles.container2}
          container
          direction="column"
        >
          <Typography className={styles.numberText}>Total Orders</Typography>

          {loadingGetWritersStats ? (
            <CircularProgress
              size="20px"
              style={{ color: Colors.green, marginTop: "20px" }}
            />
          ) : (
            <Typography className={styles.number}>
              {stats?.totalOrdersCount}
            </Typography>
          )}

          {loadingGetWritersStats ? null : (
            <Button
              component={motion.button}
              whileHover={{
                backgroundColor: Colors.grey,
                transition: { duration: 0.3 },
                color: "#fff"
              }}
              className={styles.createNewPasswordButton}
              onClick={() => setShowDetails(false)}
            >
              <Typography className={styles.createNewPassword}>
                Show Writer Orders
              </Typography>
            </Button>
          )}
        </Grid>

        {Item(
          <Typography className={styles.numberText}>
            Delivered Orders
          </Typography>,
          stats?.deliveredOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Canceled Orders
          </Typography>,
          stats?.willNotDeliverOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>Total Paid</Typography>,
          "$" + (totalPaid.toLocaleString() || 0),
          Colors.green + "22"
        )}
        {Item(
          <Typography className={styles.numberText}>Total Due</Typography>,
          "$" + (totalDue.toLocaleString() || 0),
          Colors.green + "22"
        )}

        {Item(
          <Typography className={styles.numberText}>
            Earned This Week
          </Typography>,
          "$" + (earnedThisMonth || 0),
          Colors.green + "22"
        )}

        <Grid
          component={motion.div}
          whileHover={{
            backgroundColor: Colors.dark + "10",
            transition: { duration: 0.7 },
            scale: 1.05,
            borderColor: Colors.blue + "88",
            borderWidth: "1px"
          }}
          className={styles.container2}
          container
          direction="column"
        >
          <Typography className={styles.numberText}>Password</Typography>

          {profile?.password ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Typography className={styles.password}>
                {showPassword ? profile?.password : "*****************"}
              </Typography>
              <Button onClick={() => setShowPassword(!showPassword)}>
                <Typography className={styles.showPassword}>
                  {showPassword ? "Hide" : "Show"}
                </Typography>
              </Button>
            </Grid>
          ) : null}

          <Button
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.grey,
              transition: { duration: 0.3 },
              color: "#fff"
            }}
            className={styles.createNewPasswordButton}
            onClick={() => handleClickOpen()}
          >
            <Typography className={styles.createNewPassword}>
              Create new password
            </Typography>
          </Button>
        </Grid>

        <CreateWriterPassword
          loadingAddWriter={loadingAddWriter}
          saveWriter={() => saveWriter()}
          handleClose={() => handleClose()}
          formErrors={formErrors}
          open={open}
          onChangeConfirmPassword={(event) => {
            updateForm({
              key: "confirmPassword",
              value: event.target.value
            });
          }}
          onChangePassword={(event) => {
            updateForm({ key: "password", value: event.target.value });
          }}
        />
        <CreateWriterPayment
          loadingAddWriterPayment={loadingAddWriterPayment}
          SavePayment={() => savePayment()}
          handleClose={() => handleClosePayment()}
          formErrors={formErrorsPayment}
          open={openPament}
          onChangeDate={(value) => {
            updateForm({
              key: "date",
              value: value.toUTCString()
            });
          }}
          onChangeItem={(event) => {
            updateForm({ key: "item", value: event.target.value });
          }}
          onChangePrice={(event) => {
            updateForm({ key: "price", value: event.target.value });
          }}
          onChangeNote={(event) => {
            updateForm({ key: "note", value: event.target.value });
          }}
        />

        <EditWriterPayment
          selectedPayment={selectedPayment}
          loadingAddWriterPayment={loadingAddWriterPayment}
          editPayment={() => editPayment()}
          handleClose={() => handleClosePaymentEdit()}
          formErrors={formErrorsPaymentEdit}
          open={openPamentEdit}
          onChangeDate={(value) => {
            updateForm({
              key: "dateEdit",
              value: value.toUTCString()
            });
          }}
          onChangeItem={(event) => {
            updateForm({ key: "itemEdit", value: event.target.value });
          }}
          onChangePrice={(event) => {
            updateForm({ key: "priceEdit", value: event.target.value });
          }}
          onChangeNote={(event) => {
            updateForm({ key: "noteEdit", value: event.target.value });
          }}
        />
      </Grid>
      <Typography className={styles.idOrder}>Payments</Typography>
      <Grid container direction="row">
        <Button
          disabled={profile?.id ? false : true}
          onClick={() => {
            setOpenPament(true);
          }}
          className={styles.createPaymentButton}
          style={{
            backgroundColor: profile?.id ? Colors.blue : Colors.grey,
            marginLeft: "20px",
            marginTop: "20px"
          }}
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.green,
            transition: { duration: 0.3 }
          }}
        >
          <Typography className={styles.messageText}>
            Add new payment
          </Typography>
        </Button>
        <Button
          disabled={profile?.id ? false : true}
          onClick={() => {
            exportToCSV(
              payments?.concat(orders.map(item=>{
                return({
                  item:item?.id,
                  price:item?.total,
                  note:item?.fulfillmentStatus,
                  date:item?.createDate
                })
              }).sort(
                (objA, objB) =>
                  Number(new Date(objB.date)) -
                  Number(new Date(objA.date))
              )),
              "writer-payment  - " + (selectedWriter.email.startsWith("add.task")
              ? String(selectedWriter.email).substr(0, 30) + ".."
              : selectedWriter.email)+" - "+new Date().toDateString()
            );
          }}
          className={styles.createPaymentButton}
          style={{
            backgroundColor: Colors.green,
            marginLeft: "20px",
            marginTop: "20px"
          }}
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.lightGreen,
            transition: { duration: 0.3 }
          }}
        >
          <Typography className={styles.messageText}>
            Export xlsx file
          </Typography>
        </Button>
      </Grid>
      <TableContainer
        sx={{
          width: window.innerWidth - 300,
          marginLeft: "20px",
          marginTop: "30px"
        }}
        component={Paper}
      >
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow style={{}}>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Num#
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Date
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
              >
                Item
              </TableCell>

              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  fontWeight: "bold",
                  color: Colors.white
                }}
                align="right"
              >
                Price
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  color: Colors.white,
                  fontWeight: "bold"
                }}
                align="right"
              >
                Note
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: Colors.blue,
                  fontFamily: "Spartan",
                  fontWeight: "bold",
                  color: Colors.white
                }}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments
              ?.concat(orders)
              .sort(
                (objA, objB) =>
                  Number(new Date(objB.createDate)) -
                  Number(new Date(objA.createDate))
              )
              ?.slice(sliceFrom, sliceTo)
              ?.map((row, index) => (
                <TableRow
                  key={JSON.stringify(row)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    backgroundColor: String(row?.item)
                      .toUpperCase()
                      .includes("PAYMENT")
                      ? Colors.green + "33"
                      : Colors.white
                  }}
                >
                  <TableCell
                    style={{ fontFamily: "Spartan", fontSize: "13px" }}
                  >
                    {++sliceFrom}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Spartan", fontSize: "13px" }}
                  >
                    {row?.fulfillmentStatus
                      ? new Date(
                          new Date(row?.createDate).toLocaleString("en-US", {
                            timeZone: "America/New_York"
                          })
                        )
                          .toString()
                          .substr(0, 24)
                      : new Date(
                          new Date(row?.date).toLocaleString("en-US", {
                            timeZone: "America/New_York"
                          })
                        )
                          .toLocaleString()
                          .substr(0, 24)}
                  </TableCell>

                  <TableCell
                    style={{ fontFamily: "Spartan" }}
                    className={styles.tableCell}
                    component="th"
                    scope="row"
                  >
                    {row?.fulfillmentStatus ? (
                      <Button
                        onClick={() => {
                          openOrderDetails(row);
                        }}
                      >
                        {"Order (" + row?.id + ")"}
                      </Button>
                    ) : (
                      row?.item
                    )}
                  </TableCell>

                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Spartan",
                      fontSize: "13px"
                    }}
                    align="right"
                  >
                    ${row?.fulfillmentStatus ? row?.total : row?.price}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      fontFamily: "Spartan",
                      fontSize: "13px",
                      color:
                        (row?.fulfillmentStatus == "PROCESSING" &&
                          Colors.orange) ||
                        (row?.fulfillmentStatus == "DELIVERED" &&
                          Colors.green) ||
                        (row?.fulfillmentStatus == "AWAITING_PROCESSING" &&
                          Colors.red)
                    }}
                  >
                    {row?.fulfillmentStatus
                      ? row?.fulfillmentStatus
                      : row?.note}
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", fontFamily: "Spartan" }}
                    align="right"
                  >
                    {!row?.fulfillmentStatus ? (
                      <Grid container direction="row">
                        <Grid
                          item
                          onClick={() => {
                            updateForm({ key: "itemEdit", value: row?.item });
                            updateForm({ key: "dateEdit", value: row?.date });
                            updateForm({ key: "priceEdit", value: row?.price });
                            updateForm({ key: "noteEdit", value: row?.note });

                            setSelectedPayment(row);
                            setOpenPamentEdit(true);
                          }}
                        >
                          <Edit style={{ width: "20px", color: Colors.blue }} />
                        </Grid>
                        <Grid
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            deleteWriterPayment(
                              profile?.id,
                              row?.id,
                              (data) => {
                                if (data) {
                                  setPayments(
                                    payments?.filter(
                                      (item, index) => item.id != row.id
                                    )
                                  );
                                  // getWriterPayment(profile?.id);
                                }
                              }
                            );
                          }}
                          item
                        >
                          <Delete
                            style={{ width: "20px", color: Colors.blue }}
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack>
        <Pagination
          onChange={(event, value) => {
            setPage(value);
            if (value == 1) {
              setSliceFrom(0);
              setSliceTo(7);
            } else {
              setSliceFrom((value - 1) * 7);
              setSliceTo(value * 7);
            }
          }}
          page={page}
          style={{ alignSelf: "center", marginTop: "20px" }}
          count={Math.ceil(payments?.concat(orders)?.length / 7)}
          color="primary"
        />
      </Stack>
    </div>
  ) : (
    <WriterOrders
      role={role}
      selectedWriter={selectedWriter}
      onClickBack={() => setShowDetails(true)}
    />
  );
};

const mapStateToProps = ({ writer }) => {
  return {
    loadingAddWriter: writer.loadingAddWriter,
    errorAddWriter: writer.errorAddWriter,
    writer: writer.writer,

    loadingGetWriters: writer.loadingGetWriters,
    errorGetWriters: writer.errorGetWriters,
    writers: writer.writers,
    stats: writer.stats,
    errorGetWritersStats: writer.errorGetWritersStats,
    loadingGetWritersStats: writer.loadingGetWritersStats,
    loadingAddWriterPayment: writer.loadingAddWriterPayment,
    loadingGetWriterPayments: writer.loadingGetWriterPayments,
    writerPayments: writer.writerPayments,
    orders: writer.writerOrders
  };
};

export default connect(mapStateToProps, {
  createWriter,
  getWriters,
  getWriterStats,
  getWriter,
  updatePassword,
  signup,
  createWriterPayment,
  deleteWriterPayment,
  getWriterPayment,
  editWriterPayment,
  getWriterOrders
})(WriterDetails);
