import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Button, Checkbox } from "@material-ui/core";
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
import {} from "@material-ui/icons";
import Colors from "../../../Utils/Colors";
import { ArrowForward } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, Router } from "react-router-dom";
import login_back from "../../../assets/images/auth/login_back.jpg";
import logo from "../../../assets/global/logo.png";
import useStyles from "./Style.js";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "../../../Components/Input";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { connect } from "react-redux";
import { login } from "../../../Redux/actions";
import AsyncStorage from "@callstack/async-storage";


function reducer(state, { key, value }) {
  return { ...state, [key]: value };
}

const Login = ({ login, loading }) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const initailFormState = {
    email: null,
    password: null,
    isRemember: false,
  };
  const [form, updateForm] = useReducer(reducer, initailFormState);
  const [error, setError] = useState(null);
  const handleChange = () => {
    updateForm({ key: "isRemember", value: !form.isRemember });
  };

  useEffect(async()=>{
    const loggedIn = await AsyncStorage.getItem("loggedIn");

    if (loggedIn==true) {
      history.push("/dashboard/home");
    }
  },[])

  const loginAction = () => {
    setError(null);
    if (!form.email) {
      setError("please enter email");
    } else if (!form.password) {
      setError("please enter password");
    } else {
      login(
        form.email,
        form.password,
        (data) => {
          if (data) {
            history.push("/dashboard/home");
          }
        },
        (error) => {
          setError(error);
        }
      );
      //
    }
  };

  return (
    <Grid
      direction={matches ? "column" : "row"}
      container
      justify="flex-start"
      alignItems="flex-start"
      className={styles.container}
    >
      <div className={styles.imgDev}>
        <img src={login_back} className={styles.img} />
        <Grid
          direction="row"
          container
          justify="center"
          alignItems="center"
          className={styles.logoView}
        >
          <Grid item>
            <img src={logo} className={styles.logo} />
          </Grid>
          <Grid>
            <Typography className={styles.appName}>A+ Paper Writer</Typography>
          </Grid>
        </Grid>
      </div>
      <Grid
        direction="column"
        container
        justify="flex-start"
        alignItems="flex-start"
        className={styles.inputContent}
      >
        <Typography className={styles.welcomeText}>
          {"Welcome to Paper Writer!"}
        </Typography>
        <Typography className={styles.loginText}>
          {"Please sign-in to your account and start the adventure"}
        </Typography>
        <Input
          className={styles.inputEmail}
          label={t("Email")}
          type="email"
          onChange={(event) => {
            updateForm({ key: "email", value: event.target.value });
          }}
        />

        <Input
          color={Colors.blue}
          className={styles.inputEmail}
          label={t("Password")}
          type={"password"}
          onChange={(event) => {
            updateForm({ key: "password", value: event.target.value });
          }}
        />
        {/*<FormControlLabel
          className={styles.rememberMeContentView}
          control={
            <Checkbox
              checked={form.isRemember}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label={t("Remember Me")}
        ></FormControlLabel>*/}
        {error && (
          <Alert className={styles.alert} severity="error">
            <Typography style={{ fontSize: "13px", fontFamily: "Spartan" }}>
              {error}
            </Typography>
          </Alert>
        )}

        <Button
          component={motion.button}
          whileHover={{
            backgroundColor: Colors.green,
            transition: { duration: 0.3 },
          }}
          className={styles.SigninButton}
          onClick={loginAction}
        >
          {loading ? (
            <CircularProgress
              style={{ alignSelf: "center", color: Colors.white }}
            />
          ) : (
            <Typography className={styles.signinText}>Sign In</Typography>
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ users }) => {
  return {
    loading: users.loadingLogin,
  };
};

export default connect(mapStateToProps, { login })(Login);
