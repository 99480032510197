import {

   GET_ORDERS_SUCCESS,
   GET_ORDERS_ATTEMPING,
   GET_ORDERS_FAILED,

   CREATE_ORDER_ATTEMPING,
   CREATE_ORDER_FAILED,
   CREATE_ORDER_SUCCESS,

   GET_ORDERS_STATS_ATTEMPING,
   GET_ORDERS_STATS_FAILED,
   GET_ORDERS_STATS_SUCCESS,

} from '../actions/Types';

const INITIAL_STATE = {
    loading: false, error: null, orders: null,
    loadingCreateOrder: false, errorCreateOrder: null, orderCreated: false,
    loadingOrdersStats: false, errorOrdersStats: null, orderStats: false
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_ORDERS_ATTEMPING:
            return {...state,  loading: true }

        case GET_ORDERS_SUCCESS:
            return { ...state, loading: false, orders: action.payload }

        case GET_ORDERS_FAILED:
            return { ...state, loading: false, error: action.payload, }

        case GET_ORDERS_STATS_ATTEMPING:
            return {...state,  loadingOrdersStats: true }
    
        case GET_ORDERS_STATS_SUCCESS:
            return { ...state, loadingOrdersStats: false, orderStats: action.payload }
    
        case GET_ORDERS_STATS_FAILED:
            return { ...state, loadingOrdersStats: false, errorOrdersStats: action.payload, }

                

        case CREATE_ORDER_ATTEMPING:
            return {...state,  loadingCreateOrder: true }
    
        case CREATE_ORDER_SUCCESS:
            return { ...state, loadingCreateOrder: false, orderCreated: action.payload }
    
        case CREATE_ORDER_FAILED:
            return { ...state, loadingCreateOrder: false, errorCreateOrder: action.payload, }
    
        default: return state;

    }

};
