import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  homeStudentStats,
  getProducts,
  deleteNotification,
  getSubscription,
  updateSubscription
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import ProductItem from "../../../../Components/ProductItem";
import SunscriptionItem from "../../../../Components/SunscriptionItem";
import ProductForm from "../../Admin/ProductForm/View";
import AsyncStorage from "@callstack/async-storage";
import CancelStudentSubscription from "./CancelStudentSubscription";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

const HomeStudent = ({
  homeStudentStats,
  loadingOrdersStats,
  orderStats,
  getProducts,
  products,
  loading,
  role,
  deleteNotification,
  openOrderManagement,
  loadingSubscription,
  subscriptions,
  getSubscription,
  updateSubscription
}) => {
  const styles = useStyles();
  const [showStats, setShowStats] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [pagesLeft, setPagesLeft] = useState(0);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSubsciption, setSelectedSubsciption] = useState(null);

  useEffect(() => {
    if (subscriptions?.length > 0) {
      let result = 10;
      subscriptions[0]?.orderTemplate?.items?.map((Item) => {
        Item.selectedOptions?.map((option) => {
          if (option?.name == "Page Count") {
            result = result - parseInt(option.value);
          }
        });
      });
      setPagesLeft(result);
      setSubscriptionStatus(subscriptions[0]?.status);
    }
  }, [subscriptions]);

  useEffect(() => {
    //deleteNotification()
    init();
  }, []);

  const init = async () => {
    const email = await AsyncStorage.getItem("email");

    homeStudentStats(email, "", "", "");
    getSubscription(email);
    getProducts();
  };
  console.log("subscriptionssubscriptions", subscriptions);
  const Item = (children, value) => {
    return (
      <Grid
        component={motion.div}
        whileHover={{
          backgroundColor: Colors.dark + "10",
          transition: { duration: 0.7 },
          scale: 1.05,
          borderColor: Colors.blue + "88",
          borderWidth: "1px"
        }}
        className={styles.container2}
        container
        direction="column"
      >
        {children}

        {loadingOrdersStats ? (
          <CircularProgress
            size="20px"
            style={{ color: Colors.green, marginTop: "20px" }}
          />
        ) : (
          <Typography className={styles.number}>{value}</Typography>
        )}
      </Grid>
    );
  };

  return showStats ? (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <ToastContainer />

      <Grid container direction="row">
        {Item(
          <Typography className={styles.numberText}>Total Orders</Typography>,
          orderStats?.totalOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Delivered Orders
          </Typography>,
          orderStats?.deliveredOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Processing Orders
          </Typography>,
          orderStats?.processingOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Awaiting Processing Orders
          </Typography>,
          orderStats?.awaitingProcessingOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>Canceled</Typography>,
          orderStats?.willNotDeliverOrdersCount
        )}

        {role == "admin"
          ? Item(
              <Typography className={styles.numberText}>Total Paid</Typography>,
              "$" + Math.round(orderStats?.totalPaid)
            )
          : null}
      </Grid>

      {loading ? (
        <Grid container>
          <Typography className={styles.idOrder}>Products</Typography>
          <Box className={styles.progress}>
            <CircularProgress
              style={{ alignSelf: "center", color: Colors.blue }}
            />
          </Box>
        </Grid>
      ) : products?.length > 0 ? (
        <Grid container>
          <Typography className={styles.idOrder}>Products</Typography>
          <Grid container direction="row">
            {products?.sort((a,b) => a.name - b.name)?.map((item, index) => {
              if (
                item?.id == 694325716 ||
                item?.id == 392168910 ||
                item?.id == 139667022 ||
                item?.id == 409159815 ||
                item?.id == 694315665
              ) {
                return (
                  <Grid item>
                    <ProductItem
                      key={index.toString()}
                      product={item}
                      buyAction={() => {
                        setSelectedProduct(item);
                        setShowStats(false);
                      }}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </Grid>
      ) : null}

      {loadingSubscription ? (
        <Grid container>
          <Typography className={styles.idOrder}>My Subscriptions</Typography>

          <Box className={styles.progress}>
            <CircularProgress
              style={{ alignSelf: "center", color: Colors.blue }}
            />
          </Box>
        </Grid>
      ) : subscriptions?.length > 0 ? (
        <Grid container direction="row">
          <Typography className={styles.idOrder}>My Subscriptions</Typography>
          {subscriptions?.map((item, index) => {
            return (
              <Grid item>
                <SunscriptionItem
                  key={index.toString()}
                  subscription={item}
                  cancelButtonAction={() => {
                    setSelectedSubsciption(item.subscriptionId);
                    setOpenDialog(true);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      <CancelStudentSubscription
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        subscribeAction={() => {
          updateSubscription(
            selectedSubsciption,
            "CANCELLED",
            async (data) => {
              toast.success("The subscription is cancelled successfully!");
              const email = await AsyncStorage.getItem("email");
              getSubscription(email);
            },
            (error) => {
              toast.error(error);
            }
          );
          setOpenDialog(false);
        }}
      />
    </div>
  ) : (
    <ProductForm
      pagesLeft={pagesLeft}
      subscriptionStatus={subscriptionStatus}
      openOrderManagement={openOrderManagement}
      onClick={() => setShowStats(true)}
      formData={selectedProduct}
      role={role}
    />
  );
};

const mapStateToProps = ({ products, orders, Subscriptions }) => {
  return {
    loadingOrdersStats: orders.loadingOrdersStats,
    errorOrdersStats: orders.errorOrdersStats,
    orderStats: orders.orderStats,
    products: products.products,
    loading: products.loading,
    loadingSubscription: Subscriptions.loadingSubscription,
    subscriptions: Subscriptions.subscriptions
  };
};

export default connect(mapStateToProps, {
  homeStudentStats,
  getProducts,
  deleteNotification,
  getSubscription,
  updateSubscription
})(HomeStudent);
