import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {} from "@material-ui/icons";
import Colors from "../../../../Utils/Colors";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Style.js";
import { connect } from "react-redux";
import {
  homeStudentStats,
  getProducts,
  deleteNotification,
  getSubscription
} from "../../../../Redux/actions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import SunscriptionItem from "../../../../Components/SunscriptionItem";
import StudentOrders from "../../Student/Orders/View";
import ArrowLeftOutlined from "@mui/icons-material/KeyboardArrowLeft";

const StudentDetails = ({
  homeStudentStats,
  loadingOrdersStats,
  orderStats,
  getProducts,
  products,
  loading,
  role,
  onClick,
  openOrderManagement,
  loadingSubscription,
  subscriptions,
  getSubscription,
  selectedStudent
}) => {
  const styles = useStyles();
  const [showStats, setShowStats] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [pagesLeft, setPagesLeft] = useState(0);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    if (subscriptions?.length > 0) {
      let result = 10;
      subscriptions[0]?.orderTemplate?.items?.map((Item) => {
        Item.selectedOptions?.map((option) => {
          if (option?.name == "Page Count") {
            result = result - parseInt(option.value);
          }
        });
      });
      setPagesLeft(result);
      setSubscriptionStatus(subscriptions[0]?.status);
    }
  }, [subscriptions]);

  useEffect(() => {
    //deleteNotification()
    init();
  }, []);

  const init = async () => {
    homeStudentStats(selectedStudent?.email, "", "", "");
    getSubscription(selectedStudent?.email);
    getProducts();
  };
  console.log("subscriptionssubscriptions", subscriptions);
  const Item = (children, value) => {
    return (
      <Grid
        component={motion.div}
        whileHover={{
          backgroundColor: Colors.dark + "10",
          transition: { duration: 0.7 },
          scale: 1.05,
          borderColor: Colors.blue + "88",
          borderWidth: "1px"
        }}
        className={styles.container2}
        container
        direction="column"
      >
        {children}

        {loadingOrdersStats ? (
          <CircularProgress
            size="20px"
            style={{ color: Colors.green, marginTop: "20px" }}
          />
        ) : (
          <Typography className={styles.number}>{value}</Typography>
        )}
      </Grid>
    );
  };

  return showStats ? (
    <div
      className={styles.container}
      container
      direction="column"
      wrap="nowrap"
      spacing={2}
    >
      <Button onClick={onClick} className={styles.backButton}>
        <ArrowLeftOutlined className={styles.more} />

        <Typography className={styles.more}>Back</Typography>
      </Button>
     

   

      <Grid container direction="row">

      <Grid
          component={motion.div}
          whileHover={{
            backgroundColor: Colors.dark + "10",
            transition: { duration: 0.7 },
            scale: 1.05,
            borderColor: Colors.blue + "88",
            borderWidth: "1px"
          }}
          className={styles.container2}
          container
          direction="column"
        >
          <img
            variant="circular"
            className={styles.avatar}
            src={
              "https://firebasestorage.googleapis.com/v0/b/paper-writer-f57a6.appspot.com/o/44-448460_email-clipart-grey-transparent-background-email-icons-png.png?alt=media&token=261506e5-de0e-4427-8dca-13ef28030c06"
            }
          />

          <Typography className={styles.orderStatus}>
            { selectedStudent.email}
          </Typography>
        </Grid>
        <Grid
        component={motion.div}
        whileHover={{
          backgroundColor: Colors.dark + "10",
          transition: { duration: 0.7 },
          scale: 1.05,
          borderColor: Colors.blue + "88",
          borderWidth: "1px"
        }}
        className={styles.container2}
        container
        direction="column"
      >
        <Typography className={styles.numberText}>Total Orders</Typography>
        {loadingOrdersStats ? (
          <CircularProgress
            size="20px"
            style={{ color: Colors.green, marginTop: "20px" }}
          />
        ) : (
          <Typography className={styles.number}>
            {orderStats?.totalOrdersCount}
          </Typography>
        )}

        {loadingOrdersStats ? null : (
          <Button
            component={motion.button}
            whileHover={{
              backgroundColor: Colors.grey,
              transition: { duration: 0.3 },
              color: "#fff"
            }}
            className={styles.createNewPasswordButton}
            onClick={() => setShowStats(false)}
          >
            <Typography className={styles.createNewPassword}>
              Show Student Orders
            </Typography>
          </Button>
        )}
      </Grid>
        {Item(
          <Typography className={styles.numberText}>
            Delivered Orders
          </Typography>,
          orderStats?.deliveredOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Processing Orders
          </Typography>,
          orderStats?.processingOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>
            Awaiting Processing Orders
          </Typography>,
          orderStats?.awaitingProcessingOrdersCount
        )}

        {Item(
          <Typography className={styles.numberText}>Canceled</Typography>,
          orderStats?.willNotDeliverOrdersCount
        )}

        {role == "admin"
          ? Item(
              <Typography className={styles.numberText}>Total Paid</Typography>,
              "$" + Math.round(orderStats?.totalPaid)
            )
          : null}
      </Grid>
      <Typography className={styles.idOrder}>My Subscriptions</Typography>
      {loadingSubscription ? (
        <Box className={styles.progress}>
          <CircularProgress
            style={{ alignSelf: "center", color: Colors.blue }}
          />
        </Box>
      ) : subscriptions?.length > 0 ? (
        <Grid container direction="row">
          {subscriptions?.map((item, index) => {
            return (
              <Grid item>
                <SunscriptionItem key={index.toString()} subscription={item} />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
    </div>
  ) : (
    <StudentOrders
      role={role}
      selectedStudent={selectedStudent}
      onClickBack={() => setShowStats(true)}
    />
  );
};

const mapStateToProps = ({ products, orders, Subscriptions }) => {
  return {
    loadingOrdersStats: orders.loadingOrdersStats,
    errorOrdersStats: orders.errorOrdersStats,
    orderStats: orders.orderStats,
    products: products.products,
    loading: products.loading,
    loadingSubscription: Subscriptions.loadingSubscription,
    subscriptions: Subscriptions.subscriptions
  };
};

export default connect(mapStateToProps, {
  homeStudentStats,
  getProducts,
  deleteNotification,
  getSubscription
})(StudentDetails);
