import React, { useState, useEffect, useReducer } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Colors from "../Utils/Colors";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

const Input = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [showPassword, SetShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    SetShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      className={props.className}
      id="outlined-basic"
      label={props.label}
      variant="outlined"
      size='medium'
      type={showPassword && props.type == "password" ? "text" : props.type}
      InputLabelProps={{
        style: {
          fontFamily: "Spartan",
        },
      }}
      SelectProps={{
        style: {
          fontFamily: "Spartan",
        },
      }}
      InputProps={
        props.type == "password" && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }
      }
      onChange={props.onChange}
    />
  );
};

export default Input;
