import {
  GET_STUDENT_SUBSCRIPTION_ATTEMPING,
  GET_STUDENT_SUBSCRIPTION_FAILED,
  GET_STUDENT_SUBSCRIPTION_SUCCESS
} from "../actions/Types";

const INITIAL_STATE = {
  loadingSubscription: false,
  error: null,
  subscriptions: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STUDENT_SUBSCRIPTION_ATTEMPING:
      return { ...state, loadingSubscription: true };

    case GET_STUDENT_SUBSCRIPTION_SUCCESS:
      return { ...state, loadingSubscription: false, subscriptions: action.payload };

    case GET_STUDENT_SUBSCRIPTION_FAILED:
      return { ...state, loadingSubscription: false, error: action.payload };

    default:
      return state;
  }
};
